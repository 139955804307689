let fr = {
    translations: {
        "component": "Composant",
        "add": "Ajouter",
        "document_type": "Type de document",
        "select_theme_color": "Sélectionnez la couleur du thème",
        "company_logo": "Logo d'entreprise",
        "choose_file": "Choisir le fichier",
        "your_company_name": "Le nom de votre société",
        "company_address": "Adresse de la société",
        "currency": "La monnaie",
        "no": "Non",
        "terms_&_condition": "Termes et conditions",
        "notes": "Remarques",

        "date_format": "Format de date",
        "line_items": "Éléments de campagne",
        "item_name": "Nom de l'article",
        "bill_to": "Facturé à",
        "ship_to": "Envoyez à",
        "valid_till": "Valable jusqu'au",
        "due_date": "Date d'échéance",
        "date": " le",
        "Facture le":"Facturé le",
        "item_description": "Description de l'article",
        "discount": "Remise",
        "tax": "Taxes",
        "delete": "Effacer",
        "add_new_item": "Ajouter un nouvel élément",
        "signature": "Signature",
        "show_discount": "Afficher la remise",
        "show_tax": "Afficher la taxe",
        "company_name": "Nom de la compagnie",
        "company_billing_address": "Adresse de facturation de l'entreprise",
        "billing_address_placeholder_text_value": "Nom du client\nAdresse de facturation de votre client",
        "shipping_address_placeholder_text_value": "Nom du client\nAdresse de livraison de votre client",
        "terms_and_condition_placeholder_text": "Le paiement est dû dans les 30 jours",
        "notes_placeholder_text": "Veuillez payer le solde dû dans les délais",
        "hide": "Cacher",
        "show": "Spectacle",
        "estimate": "Estimation",
        "quotation": "Devis",
        "invoice": "Facture",
        "purchase_order": "Bon de commande",
        "bills": "Factures",
        "total": "Total",
        "number": "nombre",

        "edit": "Éditer",
        "products": "Article",
        "produits": "Article",
        "description": "Description",
        "qty": "Qté",
        "unit_price": "Prix unitaire",
        "unit price": "Prix unitaire",
        "price": "Prix",
        "amount": "Montant",
        "subtotal": "Sous-total",

        "choose_a_template": "Choisissez un modèle",
        "go_to_deskera": "Aller à Deskera",
        "print_this_document": "Imprimer ce document",
        "send_email_to_clients": "Envoyer un e-mail aux clients",

        "document_generator": "Générateur de documents",
        "please_wait": "S'il vous plaît, attendez...",
        "download_as_pdf": "Télécharger en (.pdf)",
        "download": "Télécharger",
        "print": "Impression",
        "share": "Partager",
        "try_deskera": "Essayez Deskera",
        "copy_link": "Copier le lien",
        "share_on_the_web": "Partager sur le Web",
        "anyone_with_the_below_link_can_view": "Toute personne disposant du lien ci-dessous peut consulter",

        "send_your": "Envoyez votre",
        "quickly": "Rapidement",
        "please_sign_up_with_deskera_to_send_your": "Veuillez vous inscrire auprès de Deskera pour envoyer votre",
        "dont_worry_your": "Ne t'inquiète pas, ton",
        "will_still_be_here": "sera toujours là.",
        "sign_up_for_free": "Inscription gratuite",
        "already_a_user_Sign_in": "Déjà un utilisateur? Se connecter",

        "share_on_mail": "Partager par e-mail",
        "to": "À",
        "subject": "matière",
        "enter_recipient_email_address": "Saisissez l'adresse e-mail du destinataire",
        "enter_subject_here": "Entrez le sujet ici",
        "send":'Envoyer',
        "cancel": "Annuler",
        "edit_table": "Modifier le tableau",
        "save": "Sauvegarder",
        "buyer": "Acheteur",
        "seller": "vendeur",
        "powered_by_deskera": "Propulsé par Deskera",
        "details": "Détails",
        "other_comments": "Autre commentaires",
        "bill": "Facture",
        "order": "Ordre",

        "email": "Email",

        "items": "Code",
        "services": "Services",
        "hours": "Heures",
        "rate": "Taux",
        "other": "Autre",
        "sales_tax": "Taxe de vente",
        "tax_rate": "Taux d'imposition",
        "sales tax": "Taxe de vente",
        "tax rate": "Taux d'imposition",

        "num": " No",
        "template_update_failure_message": "Impossible de mettre à jour les paramètres. Veuillez réessayer!",
        "template_save_failure_message": "Impossible d'enregistrer les paramètres. Veuillez réessayer!",
        "template_update_success_message": "Modèle enregistré avec succès",
        "template_save_success_message": "Modèle enregistré avec succès",
        "additional_information": "Information additionnelle",
        "other_options": "Autres options",

        "image": "Image",
        "text": "Texte",
        "watermark": "Filigrane",
        "banner": "Bannière",
        "custom_field": "Champs personnalisé",
        "component_list": "Liste des composants",

        "email_address": "Adresse e-mail",
        "email_address_1": "Adresse e-mail",
        "is_not_valid": "n'est pas valide.",

        "deskera_suites": "Suites Deskera",
        "deskera_books": "Livres Deskera",
        "deskera_people": "Les gens de Deskera",
        "deskera_sales": "Ventes Deskera",
        "try_now": "Essayez maintenant",


        "text_color": "Couleur du texte",
        "image_ratio": "Rapport d'image (%)",
        "text_size": "Taille du texte",
        "opacity": "Opacité",
        "font_style": "Le style de police",
        "font_weight": "Poids de la police",
        "text_alignment": "Alignement du texte",
        "image_alignment": "Alignement d'image",
        "image_preview": "Aperçu de l'image",
        "preview_banner": "Aperçu de la bannière",
        "banner_selection": "Sélection de bannière",
        "duplicate": "Dupliquer",
        "settings": "Réglages",
        "expiry": "Expiration",
        "batch_no": "Lot No.",

        "saving": "Économie...",
        "save_template": "Enregistrer le modèle",

        "name": "Nom",

        "stock_items": "Articles en stock",

        "sending": "Envoi en cours...",
        "set_as_default": "Définir par defaut",

        "no_email_subject": "Aucun sujet personnalisé trouvé !! Veuillez saisir votre sujet personnalisé et appuyez sur Enregistrer le modèle",

        "expense": "dépense",
        "deposit": "Caution",
        "credit_note": "Note de crédit",
        "debit_note": "Note de débit",
        "accounts": "Comptes",
        "customer_address_placeholder_text_value": "Nom du client\nAdresse de votre client",
        "account_name_placeholder_text_value": "Code de compte\nNom de compte",
        "payment_to": "Paiement à",
        "payment_from": "Paiement de",
        "from_account": "Du compte",
        "to_account": "Vers le compte",
        "payment_date": "Date de paiement",
        "document_date": "Date du document",
        "account_name": "Account name",
        "account_description": "Description du compte",
        "tax_amount": "Montant de la taxe",
        "payment_amount": "Montant du paiement",
        "line_amount": "Montant de la ligne",
        "total_tax_amount": "Montant total de la taxe",
        "contact": "Contact",
        "driver_name": "Nom du Conducteur",
        "transporter": "Transporteur",
        "vehicle_no": "Numéro du Véhicule",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "Nom du contact\nAdresse de votre contact",

        "printing": "Impression..",
        "downloading": "Téléchargement..",

        "incorrect_format": "Format incorrect!",
        "few_formats_allowed": "Seuls les fichiers jpg, jpeg et png sont autorisés",
        "image_missing": "Image manquante!",
        "data_missing": "Données manquantes!",
        "select_image_to_proceed": "Veuillez sélectionner l'image pour continuer",
        "no_data_to_export": "Aucune donnée à exporter",

        "session_expired_title": "La session a expiré!",
        "session_expired_message": "Votre session a expiré. Veuillez vous reconnecter.",
        "login": "Connexion",
        "generate_link_to_share": "Obtenir le lien",
        "burn_after_read": "Graver après lecture",
        "generate_link": "produire",
        "no_never": "Jamais",
        "1_min": "1 minute",
        "1_hr": "1 heure",
        "1_day": "Un jour",
        "1_year": "1 année",

        "document_expired_title": "Document expiré!",
        "document_expired_message": "Ce document a expiré. Veuillez contacter l'expéditeur",

        "loading": "Chargement...",
        "reference_date": "Date de référence",
        "cheque_number": "numéro de chèque",
        "deposit_to": "Déposer vers",

        "password": "Mot de passe",
        "password_empty_title": "Mot de passe vide!",
        "password_empty_message": "Le mot de passe ne peut pas être vide. Veuillez entrer un mot de passe et réessayer!",
        "document_not_found_title": "Document introuvable!",
        "document_incorrect_password_title": "Mot de passe incorrect!",
        "document_incorrect_password_message": "Veuillez saisir le mot de passe correct et réessayer.",
        "proceed": "Procéder",
        "document_enter_password_title": "Document protégé par mot de passe.",
        "document_enter_password_message": "Veuillez entrer un mot de passe.",

        "preparing_document_email": "Préparation du document à envoyer par e-mail…",
        "preparing_document_print": "Préparation du document à imprimer…",
        "loading_the_page": "Chargement de la page",
        "sender": "Expéditeur",
        "reply_to": "Répondre à",
        "enter_sender_name": "Entrez le nom de l'expéditeur",
        "enter_reply_to_email": "Entrez la réponse à l'e-mail",
        "send_a_copy_to_myself_at": "M'envoyer une copie à",

        "payment": "Paiement",
        "paper_format": "Format papier",
        "please_provide_paper_format": "Veuillez indiquer le format papier",
        "paper_format_popup_note": "Vous pouvez toujours mettre à jour le format du papier à partir du panneau de configuration",
        "item_number": "Numéro d'article",
        "item_code": "code de l'article",
        "receive_from": "Formulaire reçu",
        "payment_details": "Détails du paiement",
        "sn_number": "Numéro de série",
        "document_details": "détails du document",
        "template_saved": "Modèle enregistré!",
        "you_design_template_has_been_saved": "Le modèle a été enregistré, vous pouvez fermer cette fenêtre et revenir à l'application LIVRES.",
        "you_design_template_has_been_saved_people": "Le modèle a été enregistré, vous pouvez fermer cette fenêtre et revenir à l'application PEOPLE.",
        "go_to_books": "Accéder aux livres",
        "item_tax": "Taxe sur les produits",
        "item_discount": "Remise sur le produit",
        "uom": "UOM",
        "unitPrice": "Prix unitaire",
        "quantity": "Quantité",
        "code": "Code",
        "machine_name": "Nom de la machine",
        "technician_name": "Nom du technicien",
        "start_date": "Date de début",
        "schedule_id": "ID de l'emploi du temps",
        "end_date": "Date de fin",
        "please_select_custom_field": "Veuillez sélectionner un champ personnalisé.",

        "preview_pdf": "Aperçu Pdf",

        "template_name": "Nom du modèle",
        "please_enter_template_name_to_save": "Veuillez saisir le nom du modèle à enregistrer.",
        "custom_template": "Modèle personnalisé",
        "add_new_template": "Ajouter un nouveau modèle personnalisé",
        "vendor_name": "Nom du fournisseur",

        "decimal_point": "Point décimal",
        "footer": "Pied de page",

        "save_as": "Enregistrer sous",
        "save_as_new_template": "Enregistrer comme nouveau modèle",
        "e_invoice_section": "Section facture électronique",
        "amount_before_tax": "Montant avant impôt",

        "order_no": "Numéro de commande",
        "PURCHASE_INVOICE": 'Factures',
        "PURCHASE_ORDER": 'Ordre',
        "SALES_INVOICE": "Facture",
        "QUOTATION": "Devis",
        "fulfillment": "Ordre d'exécution",
        "ship_from": "Expédier depuis",
        "company_address_placeholder_text_value": "Nom de l'entreprise\nAdresse de votre entreprise",
        "goods_receipt": "Reçu de marchandise",
        "required_qty": "Quantité requise",
        "received_qty": "Quantité reçue",
        "committed_qty": "Quantité engagée",
        "warehouse_code": "Code d'entrepôt",
        "warehouse_name": "Nom de l'entrepôt",
        "company_warehouse_name": "Nom de l'entreprise/de l'entrepôt",
        "payment_footer": "Paiement",
        "make_payment": "Effectuer le paiement",
        "receive_payment": "Recevoir le paiement",
        "deposit_advpayment": "Acompte",
        "expense_prepayment": "Paiement anticipé",

        "delete_template": "Supprimer le modèle",
        "delete_template_message": "Êtes-vous sûr de vouloir supprimer le modèle ? Une fois supprimé, le modèle n'est pas récupérable.",
        "delete_template_ok_label": "Oui. Supprimez-le.",
        "delete_template_success_message": "Modèle supprimé avec succès.",
        "delete_template_failure_message": "Impossible de supprimer les paramètres. Veuillez réessayer !",
        "before_tax": "Avant impô",
        "outstanding": "Montant impayé",

        "signature_section": "Section de signature",
        "prepared_by": "Préparé par",
        "approved_by": "Approuvé par",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Série/Lot",
        "amount_in_words": "Montant en mots",
        "name_with_description": "Nom",

        "invoice_number": "Numéro de facture",
        "bill_number": "Numéro de facture",
        "total_invoice_amount": "Montant total de la facture",
        "total_bill_amount": "Montant total de la facture",
        "payment_made": "Paiement effectué",
        "current_due_amount": "Montant actuel dû",
        "current_balance": "Solde actuel",
        "payment_medium": "Support de paiement",
        "cash": "Cash",
        "prepayment": "Paiement anticipé",
        "payment_label": "Paiement",
        "receipt_label": "Réception",
        "total_row": "Ligne de total",

        "journal_entry": "Entrée de journal",
        "journal_entry_number": "JE numéro.",
        "account_code": "Code de compte",
        "credit": "Crédit",
        "debit": "Débit",
        "GOOD_RECEIPT": "Reçu de marchandise",
        "MAKE_PAYMENT": "Paiement",
        "RECEIVE_PAYMENT": "Paiement",
        "CREDIT_NOTE": "Note de crédit",
        "DEBIT_NOTE": "Note de débit",
        "FULFILLMENT": "Ordre d'exécution",
        "SALES_RETURN": "Retour des ventes",
        "PURCHASE_RETURN": "Retour d'achat",
        "SALES_CONTRA": "Contra des ventes",
        "PURCHASE_CONTRA": "Contra d'achat",
        "BANK_TRANSFER": "Virement bancaire",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Réglage",
        "memo": "mémorandum",

        "pick_list": "Liste de sélection",
        "pack_list": "Liste de paquets",
        "ship_list": "Liste des naviresn",
        "invoice_or_quotation": "Facture/Devis",
        "product_code": "Code produit",
        "product_name": "Nom du produit",
        "barcode": "code à barre",
        "quantity_to_pick": "Quantité à choisir",
        "quantity_required_for_order": "Quantité requise pour la commande",
        "picked": "Choisi",
        "item_no": "N° d'article",
        "unit": "Unité",
        "packed_quantity": "Quantité emballée",
        "warehouse_address": "Adresse de l'entrepôt",
        "delivery": "Livraison",
        "carrier": "Transporteur",
        "number_of_carton": "Nombre de cartons",
        "weight": "Poids",
        "tracking_number": "Numéro de suivi",
        "package": "Emballer",
        "order_number": "Numéro de commande",

        "custom_field_is_missing_title": "Le champ personnalisé est manquant",
        "custom_field_is_missing_message": "Le champ personnalisé est manquant dans ce document. Veuillez ajouter un champ personnalisé à partir du livre ou essayer avec un autre document.",

        "repeatable_header": "En-tête de tableau répétable",
        "margin": "Marge",
        "default": "Défaut",
        "none": "Rien",
        "minimum": "Minimum",
        "large": "Grand",

        "receive_by": "Date de réception",
        "ship_by": "Date d'expédition",
        "additional_date": "Date supplémentaire",

        "header_footer_section": "Section de pied de page d'en-tête",
        "header": "Entête",
        "date_only": "Date seulement",
        "title_only": "Titre uniquement",
        "page_count_only": "Nombre de pages uniquement",
        "date_title": "Date et titre",
        "date_page_count": "Date et nombre de pages",
        "title_page_count": "Titre et nombre de pages",
        "date_title_space": "Date et titre avec espace",
        "date_page_count_space": "Date et nombre de pages avec espace",
        "title_page_count_space": "Titre et nombre de pages avec espace",
        "date_title_page_count": "Date, titre, nombre de pages",
        "custom": "Personnalisé",
        "left": "Côté gauche",
        "center": "Centre",
        "right": "Côté droit",
        "title": "Titre",
        "page_count": "Nombre de pages",
        "current_page_count": "Nombre de pages actuel",
        "total_page_count": "Nombre total de pages",
        "custom_text": "Texte personnalisé",
        "page": "Page",

        "company_phone_number": "Numéro de téléphone de l'entreprise",
        "company_gstin": "Société GSTIN",
        "company_state_name_and_code": "Nom et code de l'état de l'entreprise",
        "payment_terms": "Modalités de paiement",
        "customer_phone_number": "Numéro de téléphone du client",
        "customer_gstin": "GSTIN client",
        "customer_state_name_and_code": "Nom et code de l'état du client",
        "print_only_message": "Ceci est un ordinateur généré",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "Valeur imposable",
        "integrated_tax": "Taxe intégrée",
        "central_tax": "Taxe centrale",
        "state_tax": "Impôt d'État",
        "cess": "CESS",
        "state_name": "Nom d'état",
        "state_code": "Code",
        "label_state_code": "Code d'État",

        "address_format": "Format d'adresse",
        "address_1": "Adresse 1",
        "address_2": "Adresse 2",
        "city": "Ville",
        "state": "État",
        "postal_code": "code postal",
        "country": "Pays",
        "is_apply_address_format_to_other_addresses": "Appliquer le format d'adresse actuel à toutes les adresses.",
        "product_grouping": "Groupement de produits",
        "product_group":"Groupe de produits",
        "cheque": "Chèque",
        "bank_transfer": "Virement Bancaire",
        "card": "Carte",

        "payslip": "Fiche de paie",
        "earning_statement": "Déclaration de revenus",
        "pay_period": "Période de paie",
        "pay_date": "Date de paie",
        "ssn": "SSN",
        "taxable_marital_status": "État civil imposable",
        "exemptions": "Dérogations",
        "date_of_joining": "Date d'adhésion",
        "pf_a_c_NUMBER": "Numéro de climatisation PF",
        "esi_number": "Numéro ESI",
        "uan_number": "Numéro UAN",
        "employee_id": "ID d'employé",
        "designation": "La désignation",
        "employee_net_pay": "Salaire net des employés",
        "employee_earnings": "Gains des employés",
        "allowances": "Allocations",
        "deductions": "Déductions",
        "employer_contribution": "Cotisation de l'employeur",
        "employer_cpf": "CPF de l'employeur",
        "net_salary_paid": "Salaire net payé",
        "employee_taxes_withhold": "Retenue d'impôt sur les employés",
        "employer_taxes": "Impôts des employeurs",
        "employee_deductions": "Déductions des employés",
        "summary": "Résumé",
        "basic_salary": "Salaire de base",
        "net_pay": "Salaire net",
        "additional_earnings": "Gains supplémentaires",
        "gross_earnings": "Salaire brut",
        "duration": "Durée",
        "current": "Courant",
        "year_to_date": "Année à ce jour",
        "type": "Taper",
        "pre_tax_deductions_contributions": "Déductions avant impôts / cotisations",
        "taxes": "Impôts",
        "post_tax_deductions_contributions": "Déductions / cotisations après impôt",
        "net_pay_contributions": "Salaire net / cotisations",
        "check_amount": "Montant du chèque",
        "payslip_auto_message": "Il s'agit d'une fiche de paie générée par le système qui n'a pas besoin de sceau ni de signature",
        "employee_address": "adresse de l'employé",
        "field_configuration": "Configuration du champ",
        "table_configuration": "Configuration des tableaux",
        "employee_name_address": "Nom et adresse de l'employé",
        "employee_bank_acc_number": "Numéro de compte bancaire de l’employé",
        "header_title": "Titre de l'en-tête",
        "product_custom_field": "Champs personnalisés du produit",
        "quantity_and_uom": "Quantité et unité de mesure",
        "includes_product_custom_field": "Comprend des champs personnalisés de produit",
        "combines_product_custom_field": "Combine les champs personnalisés du produit",
        "serial_number": "Numéro de série",
        "batch_number": "Numéro de lot",
        "manufacturing_date": "Date de fabrication",
        "expiry_date": "Date d'expiration",
        "override_theme_color": "Remplacer la couleur du thème",
        "override_global_color": "Remplacer la couleur globale",
        "contact_number": "Numéro de téléphone",
        "contact_code": "Code de contact",
        "blank": "Vide",
        "pay_to": "Payer pour",
        "payroll_check": "Chèque de paie",
        "pay_to_the": "PAYER AU",
        "order_of": "ORDRE DE",
        "dollars": "DOLLARS",
        "payroll_period": "Paie pour la période de",
        "repeatable_header_footer": "En-tête de pied de page répétable",
        "repeatable_footer": "Pied de page répétable",
        "includes_hsn_or_sac_code": "Inclut le code HSN/SAC",
        "eway_bill_no":  "Facture Eway Non",
        "eway_bill_date": "Date de facturation Eway",
        "place_of_supply":'Lieu de fourniture',
        "destination_of_supply":"Destination de l'approvisionnement",
        "source_of_supply":"Source d'approvisionnement",
        "subTable_configuration": "Configuration des sous-tables",
        "additional_table_configuration": "Configuration de tableau supplémentaire",
        "select_new_type": "Sélectionnez un nouveau type",
        "qr_code": "QR Code",
        "qr_string": "Chaîne QR",
        "generated_qr": "QR généré",
        "qr_code_with_text": "Code QR avec texte",
        "image_with_text": "Image avec texte",
        "qr_information": "Informations QR",
        "additional_info": "Information additionnelle",
        "global_discount": "Remise globale",
        "pre_tax": "Avant impôt",
        "post_tax": "Après impôts",
        "includes_uom_schema": "Comprend le schéma UOM",
        "table": "Table",
        "font": "Police de caractère",
        "table_row_height": "Hauteur de ligne du tableau",
        "padding_horizontal": "Rembourrage horizontal",
        "padding_vertical": "Rembourrage vertical",
        "original": "Original",
        "original_for_recipient": "Original pour le destinataire",
        "duplicate_supply_of_goods": "Duplicata (Fourniture de Biens)",
        "duplicate_for_transporter": "Dupliquer pour le transporteur",
        "duplicate_supply_of_service": "Duplicata (Fourniture De Service)",
        "duplicate_for_supplier": "Dupliquer pour le fournisseur",
        "triplicate": "Triplicata",
        "triplicate_for_supplier": "Triple exemplaire pour le fournisseur",
        "position": "Position",
        "middle": "Milieu",
        "sample_watermark": "Exemple de filigrane",
        "extra_copy": "Copie supplémentaire",
        "quadruplicate": "Quadruple",
        "MAX_IMG_UPLOAD_ALERT": "La taille de l'image ne peut pas être supérieure à 500 Ko",
        "system_default": "Défaut du système",
        "qr_resize": "Redimensionnement QR",
        "top": "Haut",
        "bottom": "Fond",
        "click_here_to_pay": "Cliquez ici pour payer",
        "generated_message": "Message généré",
        "sales_order": "Bon de commande",
        "help": "Aider",
        "includes_account_custom_field": "Comprend des champs personnalisés de compte",
        "combines_account_custom_field": "Combine les champs personnalisés du compte",
        "account_custom_field": "Champs personnalisés du compte",
        "selection": "Sélectiona",
        "alias_name_address": "Alias ​​nom et adresse",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Jours payants",
        "unpaid_days": "Jours impayés",
        "payslip_overtime": "Au fil du temps",
        "payslip_LOP": "Perte de salaire",
        "outstanding_balance": "Solde impayé",
        "outstanding_balance_includes_current": "Solde impayé (y compris courant)",
        "top_section_above_table": "Section supérieure au-dessus du tableau",
        "is_group_additional_charges_tax": "Si la surtaxe de groupe",
        "supplier_invoice_no": "Facture fournisseur n°",
        "default_template": "Modèle par défaut",
        "contact_name": "Nom du contact",
        "current_date": "Date actuelle",
        "e_way_bill_summary": "Résumé de la facture Eway",
        "e_way_bill_detail": "Détail de la facture Eway",
        "table_column_alignment": "Alignement des colonnes du tableau",
        "table_column_vertical_alignment": "Alignement vertical",
        "earnings_configuration": "Configuration des revenus",
        "label_empty_field_message": "L'étiquette du champ ne peut pas être vide, veuillez saisir une valeur pour continuer.",
        "product_table": "Tableau des produits",
        "rounding_off": "Abrundung",
        "work_order": "Ordre de travail",
        "order_prep_completed": "Préparation de commande terminée",
        "barcode_settings": "Paramètres de code-barres",
        "barcode_resize": "Redimensionnement de code-barres",
        "comments": "Commentaires",
        "delivery_date": "Date de livraison",
        "estimated_time": "Temps estimé",
        "actual_quantity": "Quantité réelle",
        "manufacture_quantity": "Quantité de fabrication",
        "parent_work_order": "Ordre de travail parent",
        "stock_transfer": "Transfert de stock",
        "source": "Source",
        "destination": "Destination",
        "source_address": "Adresse source",
        "destination_address": "Adresse de destination",
        "transfer_date": "Date de transfert",
        "verify_date": "Date de vérification",
        "reason": "Raison",
        "warehouse": "Entrepôt",
        "stock_adjustment": "Ajustement des stocks",
        "source_warehouse_name": "Nom de l'entrepôt source",
        "source_warehouse_code": "Code de l'entrepôt source",
        "destination_warehouse_name": "Nom de l'entrepôt de destination",
        "destination_warehouse_code": "Code de l'entrepôt de destination",
        "product": "Produit",
        "show_product": "Afficher le produit",
        "hide_product_with_substitute": "Masquer le produit avec les substituts",
        "substitute": "Substitut",
        "show_all_substitute": "Afficher tous les substituts",
        "show_non_zero_substitute": "Afficher les substituts alloués",
        "hide_substitute": "Masquer le substitut",
        "combines_line_item_global_discount": "Combiner la remise globale de l'article",
        "unit_price_before_discount": "Prix unitaire avant remise",
        "unit_price_after_discount": "Prix unitaire après remise",
        "discount_per_unit": "Remise par unité",
        "show_all": "Afficher tout",
        "percentage": "Pourcentage",
        "cogs": "Coût des marchandises vendues",
        "na": "NA",
        "con_number": "Numéro de commande client",
        "document_label": "étiquette de code-barres",
        "expected_delivery_date": "Date de livraison prévue",
        "transaction_type": "Type de transaction",
        "transaction_no": "Numéro de transaction",
        "status": "Statut",
        "qc_document": "Document de contrôle qualité",
        "linked_po_so": "Commande d'achat/Commande de vente liée",
        "approver": "Approbateur",
        "sample_size": "Taille de l'échantillon",
        "number_of_qty_passed": "Nombre d'articles acceptés",
        "number_of_qty_rejected": "Nombre d'articles refusés",
        "final_remark": "Remarque finale",
        "value": "Valeur",
        "exchange_conversion": "Conversion d'échange",
        "contact_address": "Adresse de contact",
        "cheque_fields": "Champs de chèque",
        "cheque_details": "Détails du chèque",
        "sales_return": "Retour de ventes",
        "fulfilled_quantity": "Quantité remplie",
        "returned_quantity": "Quantité retournée",
        "parent_linked_document_no": "Numéro de document parent lié",
        "parent_linked_document_type": "Type de document parent lié",
        "swap_address": "Échanger l'adresse",
        "vendor_address": "Adresse du fournisseur",
        "job_work_out_order": "Ordre de travail externe",
        "machine":"Ordonnanceur de machine",
        "kind_of_packing": "No. et type d'emballage",
        "due_on": "Dû le",
        "per": "Par",
        "voucher_no": "Numéro de bon",
        "dated": "Daté du",
        "supplier_ref_order_no": "Référence du fournisseur/Numéro de commande",
        "mode_term_of_payment": "Mode/Terme de paiement",
        "dispatch_doc_no": "Numéro du document d'expédition",
        "dispatched_through": "Expédié par",
        "duration_of_process": "Durée du processus",
        "nature_of_processing": "Nature du traitement",
        "company_pan": "Numéro PAN de l'entreprise",
        "authorised_signatory": "Signataire autorisé",
        "additional_charges": "Frais supplémentaires",
        "contact_no": "Numéro de contact",
        "supplier_inv_no": "N° facture fournisseur",
        "basic_salary_wo_lop": "Salaire de base sans perte de salaire",
        "cost": "Coût",
        "stock_request": "Demande de stock",
        "target_warehouse_name": "Nom de l'entrepôt cible",
        "target_warehouse_code": "Code de l'entrepôt cible",
        "selected_row": "Ligne sélectionnée",
        "selected_rack": "Étagère sélectionnée",
        "selected_bin": "Bac sélectionné",
        "requested_qty": "Quantité demandée",
        "created_by": "Créé par",
        "approved_date": "Date d'approbation",
        "stock_issue": "Émission de stock",
        "gate_entry": "Entrée du Poste de Sécurité",
        "issued_quantity": "Quantité émise",
        "bom_product_table": "Tableau des produits BOM",
        "list": "Liste",
        "is_multi_product_table": "Tableau de produits multiples",
        "is_show_optional_group": "Groupe de produits facultatifs",
        "tax_registration_number": "Numéro d'enregistrement fiscal",
        "tax_payer_id": "Identifiant du contribuable",
        "wo_qty": "Quantité des ordres de travail",
        "adhoc_items_message": "* représente les éléments AdHoc",
        "production_checklist": "Check-list de production",
        "product_description": "Description du produit",
        "total_quantity": "Quantité totale",
        "job_status": "État de l'emploi",
        "qc_status": "Statut du contrôle qualité",
        "show_job_status": "Afficher l'état de la fiche de travail",
        "show_instruction": "Afficher les instructions",
        "optional": "Optionnel",
        "document_title": "Titre du document",
        "barcode_for_row_rack_bin": "Code-barres pour rangée/rack/boîte",
        "show_tagged_bin_warehouse": "Entrepôt de bacs marqués",
        "hsn_sac_tax_table": "Table de taxes HSN/SAC",
        "approval_section": "Section d'approbation",
        "border": "Bordure",
        "spacing": "Espacement",
        "checked_by": "Vérifié par",
        "authorized_by": "Autorisé par",
        "word_spacing": "Espacement des mots",
        "page_count_settings": "Paramètres du compte de pages",
        "single_digit": "Chiffre unique",
        "page_with_numeric": "Page avec numéro",
        "page_x_of_total_page": "Page x sur y",
        "page_x_of_total_page_slash": "page x / y",
        "service_requisition": "Demande de service",
        "service_description": "Description du service",
        "sc_no":"CS Non",
        "sc_date":"Date du CS",
        "sc_type":"Type SC",
        "delivery_order_doc_and_date": "Numéro et date du bon de livraison",
        "e_way_bill_doc_and_date": "Numéro et date du bon de voie",
        "party_order_doc_and_date": "Numéro et date de la commande du parti",
        "mode_of_transport": "Mode de transport",
        "bank_details": "Détails bancaires",
        "bank_name": "Nom de la banque",
        "company_seal": "Sceau de l'entreprise",
        "regd_office": "Bureau enregistré",
        "sub_total_in_words": "Sous-total en mots",
        "rounding_off_in_words": "Arrondissement en mots",
        "outstanding_balance_in_words": "Solde restant en mots",
        "outstanding_balance_includes_current_in_words": "Solde restant (y compris actuel) en mots",
        "total_tax_amount_in_words": "Montant total des taxes en mots",
        "before_tax_in_words": "Avant taxes en mots",
        "additional_charges_in_words": "Frais supplémentaires en mots",
        "global_discount_in_words": "Remise globale en mots",
        "cgst_in_words": "CGST en mots",
        "igst_in_words": "IGST en mots",
        "sgst_in_words": "SGST en mots",
        "person_name": "Nom de la personne",
        "store_officer": "Responsable de magasin",
        "department_holder": "Chef de département",
        "purchase_department": "département achat",
        "issue_by": "Émis par",
        "paper_orientation": "Orientation du papier",
        "landscape": "Paysage",
        "portrait": "Portrait",
        "purchase_requisition": " Demande d'achat",
        "phone": " Téléphone",
        "requisition_qty": " Quantité de demande",
        "stock_qty": " Quantité en stock",
        "prev_purchase_rate": " Précédent Tarif d'achat",
        "scheduled_date": " Date prévue",
        "estimate_rate": " Taux estimé",
        "estimate_value": " Valeur estimée",
        "cost_center": " Centre de coûts",
        "reserved": " Réservé",
        "division": " Division",
        "department_name": " Nom du département",
        "indent_no": " Retrait Non",
        "indent_date": " Date de retrait",
        "indent_type": " Type de retrait",
        "project": " Projet",
        "authorised_date": " Date autorisée",
        "requested_by": " Demandé par",
        "for": " Pour",
        "authorised_by": " Autorisé par",
        "available_qty": "Quantité disponible",
        "service_order": " Demande de service",
        "gst_no": " Non de TPS",
        "gstin_no": " Numéro GSTIN",
        "pan_no": " PAN Non",
        "cin_no": " Numéro CIN",
        "cont_person": " Suite. Personne",
        "order_date": " Date de commande",
        "shipment_address": " Adresse de livraison",
        "prepared_date_time": "Date et heure préparées",
        "only": "Seulement",
        "material_receipt_note": "Bon de réception de matériel",
        "mrn_no": "MRN Non",
        "authorized": "Autorisé",
        "inspected_by": "inspecté par",
        "store_manager": "Gérant de magasin",
        "hod": "HOTTE",
        "account_manager": "Gestionnaire de compte",
        "po_bill_no_date": "N° de bon de commande/Date\nN° de facture/Date",
        "short_excess_rejected_qty": "Quantité courte/excédentaire/rejetée.",
        "accepted_qty": "Quantité acceptée.",
        "basic_amt": "Montant de base.",
        "charges": "Des charges",
        "last_po": "Dernier bon de commande",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "Montant total",
        "total_passed_amount": "Montant total transféré",
        "inspection_required": "Inspection requise",
        "completed": "Terminé",
        "location_detail": "Détail de l'emplacement",
        "transporter_detail": "Détail du transporteur",
        "item_detail": "Détail de l'article",
        "qc_inspection": "Inspection QC",
        "work_order_no": "N° de bon de travail",
        "work_order_date": "Date du bon de travail",
        "work_order_due_date": "Date d'échéance du bon de travail",
        "work_order_status": "Statut du bon de travail",
        "edit_table_columns": "Modifier les colonnes du tableau",
        "incoming": "Entrant",
        "final": "Final",
        "label": "Étiquette",
        "both": "Les deux",
        "inspection_report": "Rapport d'inspection",
        "inspection_date": "Date",
        "parts": "Pièces",
        "reworks": "Retouches",
        "final_inspection": "Inspection finale",
        "product_listing": "Liste des produits",
        "paper_margin": "Marge du papier",
        "is_using_custom_margin": "Utilise une marge personnalisée",
        "soa_customer": "Relevé de Comptes",
        "soa_vendor": "Relevé de Comptes - Fournisseur",
        "company_reg_no": "N° d'enregistrement de l'entreprise",
        "gst_reg_no": "N° de TVA",
        "from_date": "À partir de la date",
        "as_of_date": "En date du",
        "to_date": "Jusqu'à la date",
        "document_number": "Numéro du document",
        "particular": "Particulier",
        "amount_in_base": "Montant en base",
        "balance_in_base": "Solde en Base",
        "ifsc_code": "Code IFSC",
        "purchase_request_for_quotes": "Demande de devis",
        "purchase_inward_quotation": "Devis d'achat entrant",
        "rfq_no": "N° de la demande de devis",
        "show_zero": "Afficher zéro",
        "show_total": "Afficher le total",
        "draft_watermark": "Filigrane de brouillon",
        "draft": "Brouillon",
        "rotation": "Rotation",
        "invoice_summary": "Résumé de la facture",
        "bill_summary": "Résumé de la facture",
        "amount_due": "Montant dû",
        "total_pending_balance": "Solde total en attente",
        "serial_batch_custom_fields": "Champs personnalisés pour lot en série",
        "supplier_part_name": "Nom de la pièce du fournisseur",
        "supplier_part_number": "Numéro de pièce du fournisseur",
        "supplier_part_description": "Description de la pièce du fournisseur",
        "deal_name": "Nom de l'affaire",
        "deal_owner": "Propriétaire de l'affaire",
        "show_running_number": "Afficher le numéro en cours",
        "aged_receivable": "Aged Receivable",
        "document_due_date": "Date d'échéance du document",
        "balance": "Solde",
        "aged_receivable_1_30": "1 à 30 jours",
        "aged_receivable_31_60": "31 à 60 jours",
        "aged_receivable_61_90": "61 à 90 jours",
        "aged_receivable_90_plus": "Plus de 90 jours", 
        "last_payment": "Dernier Paiement",
        "total_due": "Montant Total Dû",
        "remit_to": "Envoyer à",
        "amount_enclosed": "Montant Enclos",
        "statement_date": "Date de l'État",
        "account_no": "Numéro de Compte",
        "invoice_no": "Numéro de Facture",
        "current_amount": "Montant actuel",
        "batch_quantity": "Quantité de lot",
        "row": "Ligne",
        "rack": "Étagère",
        "bin": "Bac",
        "hide_optional_product": "Masquer le produit optionnel",
    }
};

export default fr
