import { INPUT_TYPE, DKIcons, DKLabel } from 'deskera-ui-library';
import { COLUMN_CODE } from '../../../Constants/TableConstants';
import { Store } from '../../../Redux/Store';
import Utility from '../../../Utility/Utility';
import { productWareHouseNameRenderer } from '../ProductionPlan/CreateProductionPlan';
import { ProductionPlanStatus } from './ProductionPlan';
import {
  FORECAST_MASTER_GRID_KEYS,
  REQUIRED_ITEM_TABLE,
  WORK_ORDER_OPERATION_TABLE,
  WO_MASTER_GRID_KEYS
} from './TableConstant';
import {
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION
} from '../../../Constants/Constant';
import ApiConstants from '../../../Constants/ApiConstants';

export const FORECAST_COLS: any[] = [
  {
    name: 'Material Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    key: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    id: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Available Qty',
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: FORECAST_MASTER_GRID_KEYS.FC_AVAILABLE_QTY,
    key: FORECAST_MASTER_GRID_KEYS.FC_AVAILABLE_QTY,
    id: FORECAST_MASTER_GRID_KEYS.FC_AVAILABLE_QTY,
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  }
];

export const MASTER_FORECAST_COLS: any[] = [
  {
    name: 'Raw Material Details',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    key: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    id: FORECAST_MASTER_GRID_KEYS.FC_PRODUCT_NAME,
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Preferred Vendor',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: FORECAST_MASTER_GRID_KEYS.FC_PREFERRED_VENDOR_NAME,
    key: FORECAST_MASTER_GRID_KEYS.FC_PREFERRED_VENDOR_NAME,
    id: FORECAST_MASTER_GRID_KEYS.FC_PREFERRED_VENDOR_NAME,
    systemField: true,
    allowFilter: true
  },
  {
    name: '',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: FORECAST_MASTER_GRID_KEYS.FC_QUANTITY_TYPE,
    key: FORECAST_MASTER_GRID_KEYS.FC_QUANTITY_TYPE,
    id: FORECAST_MASTER_GRID_KEYS.FC_QUANTITY_TYPE,
    systemField: true,
    allowFilter: true,
    textAlign: 'left'
  }
];

export const QUALITY_CHECK_COLS: any[] = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 240,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    key: 'name',
    id: 'name',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Materials',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 340,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'materials',
    key: 'materials',
    id: 'materials',
    systemField: true,
    allowFilter: false,
    renderer: null
  }
];

export const SUB_OPERATIONS_COLS: any[] = [
  {
    name: 'Operation',
    type: INPUT_TYPE.DROPDOWN,
    width: 400,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Sub Operation',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    id: 'subOperation',
    key: 'subOperation',
    columnCode: 'subOperation'
  },
  {
    name: 'Operation time',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 250,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'operationTime',
    key: 'operationTime',
    id: 'operationTime',
    textAlign: 'right'
  }
];

export const WORKSTATION_COLS: any[] = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'workstationName',
    key: 'workstationName',
    id: 'workstationName',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Production Capacity',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: 'productionCapacity',
    key: 'productionCapacity',
    id: 'productionCapacity',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Workstation Cost',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: 'workStationCost',
    key: 'workStationCost',
    id: 'workStationCost',
    systemField: true,
    allowFilter: false,
    allowColumnSort: false
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description',
    systemField: true,
    allowFilter: true
  }
];

export const HOLIDAY_COLUMN_CONFIG: any[] = [
  {
    name: 'List Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 350,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    key: 'name',
    id: 'name',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'From Date',
    type: INPUT_TYPE.DATE,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fromDate',
    key: 'fromDate',
    id: 'fromDate',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'To Date',
    type: INPUT_TYPE.DATE,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'toDate',
    key: 'toDate',
    id: 'toDate',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Total Holidays',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'numberOfHolidays',
    key: 'numberOfHolidays',
    id: 'numberOfHolidays',
    textAlign: 'right',
    allowFilter: false
  }
];

export const HOLIDAY_LIST_SUB_GRID: any[] = [
  {
    name: 'Sr.No',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'srNo',
    key: 'srNo',
    id: 'srNo'
  },
  {
    name: 'Holiday Date',
    type: INPUT_TYPE.DATE,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'holidayDate',
    key: 'holidayDate',
    id: 'holidayDate'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 200,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description'
  }
];

export const JOB_WORK_OUT_COLS: any[] = [
  {
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: null,
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    id: 'documentSequenceCode',
    allowFilter: false
  },
  {
    name: 'Contact',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: null,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'contact',
    key: 'contact',
    id: 'contact',
    allowFilter: false
  },
  {
    name: 'W.O. Date',
    columnCode: 'documentDate',
    key: 'documentDate',
    id: 'documentDate',
    type: INPUT_TYPE.DATE,
    index: 2,
    options: null,
    required: false,
    width: 115,
    editable: false,
    hidden: false,
    systemField: true,
    uiVisible: false,
    allowFilter: false
  },
  {
    name: 'Due Date',
    type: INPUT_TYPE.DATE,
    index: 4,
    options: null,
    required: true,
    width: 115,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'dueDate',
    key: 'dueDate',
    id: 'dueDate',
    allowFilter: false
  },
  {
    name: 'Total Amount',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'totalAmount',
    key: 'totalAmount',
    id: 'totalAmount',
    allowFilter: false,
    textAlign: 'right'
  },
  {
    name: 'Dispatch Status',
    type: INPUT_TYPE.SELECT,
    index: 3,
    options: [
      {
        id: 'PENDING_DISPATCH',
        name: 'Pending',
        color: 'bg-white text-gray border-m'
      },
      {
        id: 'PARTIALLY_DISPATCHED',
        name: 'Partially Dispatched',
        color: 'bg-chip-blue text-blue border-blue'
      },
      {
        id: 'DISPATCHED',
        name: 'Dispatched',
        color: 'bg-chip-green text-green border-green'
      }
    ],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    key: 'dispatchStatus',
    columnCode: 'dispatchStatus',
    id: 'dispatchStatus',
    datasource: [],
    allowColumnSort: true,
    allowFilter: false,
    textAlign: 'center'
  },
  {
    name: 'Goods Received',
    type: INPUT_TYPE.SELECT,
    index: 3,
    options: [
      {
        id: 'UNRECEIVED',
        name: 'Not Received',
        color: 'bg-white text-gray border-m'
      },
      {
        id: 'PARTIAL_RECEIVED',
        name: 'Partially Received',
        color: 'bg-chip-blue text-blue border-blue'
      },
      {
        id: 'FULLY_RECEIVED',
        name: 'Received',
        color: 'bg-chip-green text-green border-green'
      },
      {
        id: 'NOT_APPLICABLE',
        name: 'Not Applicable',
        color: 'bg-chip-green text-green border-green'
      }
    ],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    key: 'receiptStatus',
    columnCode: 'receiptStatus',
    id: 'receiptStatus',
    datasource: [],
    allowColumnSort: true,
    allowFilter: false,
    textAlign: 'center'
  },
  {
    name: 'Bill Status',
    type: INPUT_TYPE.SELECT,
    index: 3,
    options: [
      {
        id: 'PENDING',
        name: 'Pending',
        color: 'bg-white text-gray border-m'
      },
      {
        id: 'PARTIAL',
        name: 'Partial',
        color: 'bg-chip-blue text-blue border-blue'
      },
      {
        id: 'BILLED',
        name: 'Billed',
        color: 'bg-chip-green text-green border-green'
      }
    ],
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    key: 'billedStatus',
    columnCode: 'billedStatus',
    id: 'billedStatus',
    datasource: [],
    allowColumnSort: true,
    allowFilter: false,
    textAlign: 'center'
  },
  {
    name: 'Attachments',
    type: 'text',
    options: [],
    required: true,
    width: 130,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'attachments',
    dataSource: [],
    key: 'attachments',
    allowAddOption: false,
    allowFilter: false,
    id: 'attachments'
  }
];

export const getHolidayListSubGridColumns = (onDelete: any): any[] => {
  return [
    ...HOLIDAY_LIST_SUB_GRID,
    {
      key: 'actions',
      name: 'Actions',
      type: INPUT_TYPE.BUTTON,
      width: 122,
      options: [
        {
          title: '',
          className: ' p-0',
          icon: DKIcons.ic_delete,
          onClick: (data: any) => {
            onDelete(data);
          }
        }
      ]
    }
  ];
};
export enum WORK_ORDER_STATUS {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  OPEN = 'OPEN',
  ON_HOLD = 'ON_HOLD'
}

export enum JOB_CARD_STATUS {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED'
}

export enum MACHINE_SCHEDULE_STATUS {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED'
}

export const WORK_ORDER_COLS: any[] = [
  {
    name: 'Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_MASTER_CODE,
    key: WO_MASTER_GRID_KEYS.WO_MASTER_CODE,
    id: WO_MASTER_GRID_KEYS.WO_MASTER_CODE
  },
  {
    name: 'Sales Order',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_SO_CODE,
    key: WO_MASTER_GRID_KEYS.WO_SO_CODE,
    id: WO_MASTER_GRID_KEYS.WO_SO_CODE,
    allowFilter: false
  },
  {
    name: 'Material Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: true,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_PRODUCT_NAME,
    key: WO_MASTER_GRID_KEYS.WO_PRODUCT_NAME,
    id: WO_MASTER_GRID_KEYS.WO_PRODUCT_NAME
  },
  {
    name: 'Status',
    type: INPUT_TYPE.TEXT,
    index: 1,
    configuration: {
      OPEN: {
        class: 'mrp_bg_red text-white fw-m parent-size',
        title: 'Pending'
      },
      IN_PROGRESS: {
        class: 'mrp_bg_orange text-white fw-m parent-size',
        title: 'In Progress'
      },
      ON_HOLD: {
        class: 'mrp_bg_purple2 text-white fw-m parent-size',
        title: 'On Hold'
      },
      COMPLETED: {
        class: 'mrp_bg_green text-white fw-m parent-size',
        title: '✓ Completed'
      }
    },
    options: [
      {
        id: WORK_ORDER_STATUS.OPEN,
        name: ' Pending ', // renamed for now from 'OPEN'
        color: 'bg-red text-white border-radius-l'
      },
      {
        id: WORK_ORDER_STATUS.IN_PROGRESS,
        name: ' In Progress ',
        color: 'bg-deskera text-white border-radius-l'
      },
      {
        id: WORK_ORDER_STATUS.ON_HOLD,
        name: ' On Hold ',
        color: 'mrp_bg_purple2 text-white border-radius-l'
      },
      {
        id: WORK_ORDER_STATUS.COMPLETED,
        name: ' ✓ Completed ',
        color: 'bg-green text-white border-radius-l'
      }
    ],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_STATUS,
    key: WO_MASTER_GRID_KEYS.WO_STATUS,
    id: WO_MASTER_GRID_KEYS.WO_STATUS
  },
  {
    name: 'Job Status',
    type: INPUT_TYPE.TEXT,
    index: 1,
    // options: [
    //   {
    //     id: JOB_CARD_STATUS.OPEN,
    //     name: ' Pending ', // renamed for now from 'OPEN'
    //     color: 'bg-red text-white border-radius-l'
    //   },
    //   {
    //     id: JOB_CARD_STATUS.COMPLETED,
    //     name: ' ✓ Completed ',
    //     color: 'bg-green text-white border-radius-l'
    //   }
    // ],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_JB_STATUS,
    key: WO_MASTER_GRID_KEYS.WO_JB_STATUS,
    id: WO_MASTER_GRID_KEYS.WO_JB_STATUS,
    allowFilter: false
  },
  {
    name: 'QC Status',
    type: INPUT_TYPE.TEXT,
    index: 1,
    configuration: {
      PENDING: {
        class: 'mrp_bg_red text-white fw-m parent-size',
        title: 'Pending'
      },
      COMPLETED: {
        class: 'mrp_bg_green text-white fw-m parent-size',
        title: '✓ Completed'
      },
      NONE: {
        class: 'mrp_bg_gray text-white fw-m parent-size',
        title: 'Not Assigned'
      }
    },
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_QC_STATUS,
    key: WO_MASTER_GRID_KEYS.WO_QC_STATUS,
    id: WO_MASTER_GRID_KEYS.WO_QC_STATUS,
    allowFilter: false
  },
  {
    name: 'Planned Qty',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: WO_MASTER_GRID_KEYS.WO_MANUFACTURING_QTY,
    key: WO_MASTER_GRID_KEYS.WO_MANUFACTURING_QTY,
    id: WO_MASTER_GRID_KEYS.WO_MANUFACTURING_QTY,
    allowFilter: false
  },
  {
    name: 'Actual Qty',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: WO_MASTER_GRID_KEYS.WO_ACTUAL_QTY,
    key: WO_MASTER_GRID_KEYS.WO_ACTUAL_QTY,
    id: WO_MASTER_GRID_KEYS.WO_ACTUAL_QTY,
    allowFilter: false
  },
  {
    name: 'Parent WO',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_PARENT_WO,
    key: WO_MASTER_GRID_KEYS.WO_PARENT_WO,
    id: WO_MASTER_GRID_KEYS.WO_PARENT_WO
  },
  {
    name: 'Planned Operating Cost',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    formatter: (data: any) => {
      return Utility.getAmoutBlockForGrid(
        Store.getState()?.authInfo?.currentTenantInfo?.data?.currency,
        data.value
      );
    },
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: WO_MASTER_GRID_KEYS.WO_PLANNED_OP_COST,
    key: WO_MASTER_GRID_KEYS.WO_PLANNED_OP_COST,
    id: WO_MASTER_GRID_KEYS.WO_PLANNED_OP_COST,
    allowFilter: false
  },
  {
    name: 'Actual Operating Cost',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    formatter: (data: any) => {
      return Utility.getAmoutBlockForGrid(
        Store.getState()?.authInfo?.currentTenantInfo?.data?.currency,
        data.value
      );
    },
    options: [],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: WO_MASTER_GRID_KEYS.WO_ACTUAL_OP_COST,
    key: WO_MASTER_GRID_KEYS.WO_ACTUAL_OP_COST,
    id: WO_MASTER_GRID_KEYS.WO_ACTUAL_OP_COST,
    allowFilter: false
  },
  // {
  //   name: 'Additional Operating Cost',
  //   type: INPUT_TYPE.NUMBER,
  //   index: 4,
  //   options: [],
  //   formatter: (data: any) => {
  //     return Utility.getAmoutBlockForGrid(
  //       Store.getState()?.authInfo?.currentTenantInfo?.data?.currency,
  //       data.value
  //     );
  //   },
  //   required: false,
  //   width: 220,
  //   editable: false,
  //   hidden: false,
  //   uiVisible: true,
  //   textAlign: 'right',
  //   columnCode: WO_MASTER_GRID_KEYS.WO_ADDITIONAL_OP_COST,
  //   key: WO_MASTER_GRID_KEYS.WO_ADDITIONAL_OP_COST,
  //   id: WO_MASTER_GRID_KEYS.WO_ADDITIONAL_OP_COST,
  //   allowFilter: false
  // },
  {
    name: 'Total Operating Cost',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    formatter: (data: any) => {
      return Utility.getAmoutBlockForGrid(
        Store.getState()?.authInfo?.currentTenantInfo?.data?.currency,
        data.value
      );
    },
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: WO_MASTER_GRID_KEYS.WO_TOTAL_OP_COST,
    key: WO_MASTER_GRID_KEYS.WO_TOTAL_OP_COST,
    id: WO_MASTER_GRID_KEYS.WO_TOTAL_OP_COST,
    allowFilter: false
  },
  {
    name: 'Delivery Date',
    type: INPUT_TYPE.DATE,
    index: 5,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: WO_MASTER_GRID_KEYS.WO_DELIVERY_DATE,
    key: WO_MASTER_GRID_KEYS.WO_DELIVERY_DATE,
    id: WO_MASTER_GRID_KEYS.WO_DELIVERY_DATE,
    allowFilter: false
  }
];

export const WO_OPERATIONS_COLS: any[] = [
  {
    name: 'Operation Name',
    type: INPUT_TYPE.DROPDOWN,
    index: 0,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select Operation',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: null,
        dataParser: null
      },
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_NAME,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_NAME,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_NAME
  },
  {
    name: 'Operation Time',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 160,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_TIME,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_TIME,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_TIME,
    textAlign: 'right'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 2,
    options: [
      { id: 'PENDING', name: 'Pending', color: 'data-grid-badge-color-4' },
      {
        id: 'IN_PROGRESS',
        name: 'In Progress',
        color: 'data-grid-badge-color-6'
      },
      { id: 'COMPLETED', name: 'Completed', color: 'data-grid-badge-color-7' }
    ],
    required: false,
    width: 80,
    editable: true,
    hidden: false,
    uiVisible: true,
    allowAddOption: false,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_STATUS,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_STATUS,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_STATUS
  },
  {
    name: 'Operation Cost',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: [],
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_COST,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_COST,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_COST,
    textAlign: 'right'
  },
  {
    name: 'Planned Start Time',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: [],
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_START_TIME,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_START_TIME,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_START_TIME
  },
  {
    name: 'Planned End Time',
    type: INPUT_TYPE.DATE,
    index: 4,
    options: [],
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_END_TIME,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_END_TIME,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_PLANNED_END_TIME
  },
  // {
  //   name: 'Yield',
  //   type: INPUT_TYPE.NUMBER,
  //   index: 5,
  //   options: [],
  //   required: false,
  //   width: 120,
  //   editable: true,
  //   hidden: false,
  //   uiVisible: true,
  //   columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_YIELD,
  //   key: WORK_ORDER_OPERATION_TABLE.OPERATION_YIELD,
  //   id: WORK_ORDER_OPERATION_TABLE.OPERATION_YIELD,
  //   textAlign: 'right'
  // },
  // {
  //   name: 'Actual Yield',
  //   type: INPUT_TYPE.NUMBER,
  //   index: 6,
  //   options: [],
  //   required: false,
  //   width: 120,
  //   editable: true,
  //   hidden: false,
  //   uiVisible: true,
  //   columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_ACTUAL_YIELD,
  //   key: WORK_ORDER_OPERATION_TABLE.OPERATION_ACTUAL_YIELD,
  //   id: WORK_ORDER_OPERATION_TABLE.OPERATION_ACTUAL_YIELD,
  //   textAlign: 'right'
  // },
  // {
  //   name: 'Location',
  //   type: INPUT_TYPE.DROPDOWN,
  //   index: 7,
  //   width: 250,
  //   systemField: true,
  //   editable: true,
  //   hidden: false,
  //   required: true,
  //   uiVisible: true,
  //   dropdownConfig: {
  //     title: 'Select location',
  //     allowSearch: true,
  //     searchableKey: 'name',
  //     style: { minWidth: 230 },
  //     className: 'shadow-m',
  //     searchApiConfig: {
  //       getUrl: null,
  //       dataParser: null,
  //       debounceTime: 300
  //     },
  //     data: [],
  //     renderer: null,
  //     onSelect: (index: any, obj: any, rowIndex: any) => { }
  //   },
  //   id: WORK_ORDER_OPERATION_TABLE.OPERATION_WAREHOUSE,
  //   key: WORK_ORDER_OPERATION_TABLE.OPERATION_WAREHOUSE,
  //   columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_WAREHOUSE
  // },
  {
    name: 'Completed Qty',
    type: INPUT_TYPE.NUMBER,
    index: 8,
    options: [],
    required: false,
    width: 120,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_COMPLETED_QTY,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_COMPLETED_QTY,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_COMPLETED_QTY,
    textAlign: 'right'
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 9,
    options: [],
    required: false,
    width: 200,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: WORK_ORDER_OPERATION_TABLE.OPERATION_DESCRIPTION,
    key: WORK_ORDER_OPERATION_TABLE.OPERATION_DESCRIPTION,
    id: WORK_ORDER_OPERATION_TABLE.OPERATION_DESCRIPTION
  }
];

export const REQUIRED_ITEMS_COLS: any[] = [
  {
    name: 'Item',
    type: INPUT_TYPE.DROPDOWN,
    width: 250,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select item',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    id: REQUIRED_ITEM_TABLE.ITEM_NAME,
    key: REQUIRED_ITEM_TABLE.ITEM_NAME,
    columnCode: REQUIRED_ITEM_TABLE.ITEM_NAME
  },
  {
    name: 'Source Location',
    type: INPUT_TYPE.DROPDOWN,
    width: 280,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    dropdownConfig: {
      title: 'Select location',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    id: REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE,
    key: REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE,
    columnCode: REQUIRED_ITEM_TABLE.SOURCE_WAREHOUSE
  },
  {
    name: 'Available Qty',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: REQUIRED_ITEM_TABLE.AVAILABLE_QTY,
    key: REQUIRED_ITEM_TABLE.AVAILABLE_QTY,
    id: REQUIRED_ITEM_TABLE.AVAILABLE_QTY,
    textAlign: 'right'
  },
  {
    name: 'Required Qty',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: REQUIRED_ITEM_TABLE.REQUIRED_QTY,
    key: REQUIRED_ITEM_TABLE.REQUIRED_QTY,
    id: REQUIRED_ITEM_TABLE.REQUIRED_QTY,
    textAlign: 'right'
  }
];

export const MRP_PRODUCTION_COLUMN: any[] = [
  {
    name: 'Number',
    index: 0,
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    key: 'documentSequenceCode',
    id: 'documentSequenceCode',
    columnCode: 'documentSequenceCode'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    index: 1,
    width: 150,
    editable: false,
    uiVisible: true,
    systemField: true,
    options: Object.keys(ProductionPlanStatus).map((key: any, index) => ({
      id: `${index + 1}`,
      name: ProductionPlanStatus[key as keyof typeof ProductionPlanStatus],
      color: `data-grid-badge-color-${index + 1}`
    })),
    key: 'status',
    id: 'status',
    columnCode: 'status'
  },
  {
    name: 'Posting Date',
    index: 2,
    type: INPUT_TYPE.DATE,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    id: 'postingDate',
    key: 'postingDate',
    columnCode: 'postingDate'
  },
  {
    name: 'Total Planned Quantity',
    index: 3,
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'right',
    id: 'totalPlannedQuantity',
    key: 'totalPlannedQuantity',
    columnCode: 'totalPlannedQuantity'
  },
  {
    name: 'Total Produced Quantity',
    index: 4,
    type: INPUT_TYPE.NUMBER,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'right',
    id: 'totalProducedQuantity',
    key: 'totalProducedQuantity',
    columnCode: 'totalProducedQuantity'
  },
  {
    name: 'Linked Document Type',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    id: 'linkedDocumentType',
    key: 'linkedDocumentType',
    columnCode: 'linkedDocumentType'
  }
];

export const JOB_CARD_COLS: any[] = [
  {
    name: 'Job Card ID',
    index: 0,
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'jobCardCode',
    id: 'jobCardCode',
    columnCode: 'jobCardCode'
  },
  {
    name: 'Work Order',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'workOrderSequenceCode',
    id: 'workOrderSequenceCode',
    columnCode: 'workOrderSequenceCode'
  },
  {
    name: 'SO/SI Number',
    index: 2,
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'salesOrderSequenceCode',
    id: 'salesOrderSequenceCode',
    columnCode: 'salesOrderSequenceCode'
  },
  {
    name: 'SO Customer Order No.',
    type: 'text',
    index: 3,
    options: [],
    required: true,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'salesOrderCustomerOrderNumber',
    key: 'salesOrderCustomerOrderNumber',
    id: 'salesOrderCustomerOrderNumber',
    allowFilter: false
  },
  {
    name: 'Posting Date',
    type: INPUT_TYPE.TEXT,
    systemField: true,
    index: 3,
    required: false,
    width: 140,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'jobCardDate',
    key: 'jobCardDate',
    id: 'jobCardDate'
  },
  {
    name: 'Status',
    type: INPUT_TYPE.SELECT,
    options: [
      {
        id: 'OPEN',
        name: ' Not Started ', // renamed for now from 'OPEN'
        color: 'bg-blue text-white border-radius-l'
      },
      {
        id: 'IN_PROGRESS',
        color: 'bg-deskera text-white border-radius-l',
        name: ' In Progress '
      },
      {
        id: 'COMPLETED',
        name: ' ✓ Completed ',
        color: 'bg-green text-white border-radius-l'
      },
      {
        id: 'ON_HOLD',
        name: ' On Hold ',
        color: 'mrp_bg_purple2 text-white border-radius-l'
      },
      {
        id: 'CANCELLED',
        name: ' Cancelled ',
        color: 'bg-red text-white border-radius-l'
      }
    ],
    systemField: true,
    index: 4,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'status',
    key: 'status',
    id: 'status'
  },
  {
    name: 'Operators',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'operators',
    key: 'operators',
    id: 'operators'
  },
  {
    name: 'Operator Cost',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: 'operatorCost',
    key: 'operatorCost',
    id: 'operatorCost',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'WO Item Name',
    type: INPUT_TYPE.TEXT,
    systemField: true,
    index: 5,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    columnCode: 'workOrderItemName',
    key: 'workOrderItemName',
    id: 'workOrderItemName'
  },
  {
    name: 'Workstation Name',
    type: INPUT_TYPE.TEXT,
    systemField: true,
    index: 6,
    required: false,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    columnCode: 'workstationId',
    key: 'workstationId',
    id: 'workstationId'
  },
  {
    name: 'Operation Name',
    type: INPUT_TYPE.TEXT,
    systemField: true,
    index: 7,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    columnCode: 'operationId',
    key: 'operationId',
    id: 'operationId'
  },
  {
    name: 'Operation Cost',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    columnCode: 'operationCost',
    key: 'operationCost',
    id: 'operationCost',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Total Completed Quantity',
    index: 8,
    type: INPUT_TYPE.NUMBER,
    width: 200,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'right',
    key: 'totalCompletedQuantity',
    id: 'totalCompletedQuantity',
    columnCode: 'totalCompletedQuantity'
  }
];
export const PRODUCTION_PLAN_BILL_OF_MATERIAL_COLS: any[] = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.productName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 115,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productCode',
    key: 'productCode'
  },
  {
    name: 'Planned Quantity',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    width: 140,
    editable: true,
    textAlign: 'right',
    uiVisible: true,
    systemField: true,
    columnCode: 'plannedQuantity',
    key: 'plannedQuantity'
  },
  {
    id: 'warehouse',
    columnCode: 'warehouse',
    key: 'warehouse',
    name: 'For Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 130,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.warehouseName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, warehouse: any) => {
        return warehouse?.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Planned Start Date',
    type: INPUT_TYPE.DATE,
    index: 4,
    width: 160,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'plannedStartDate',
    key: 'plannedStartDate'
  }
];
export const PRODUCTION_PLAN_RAW_MATERIAL_COLS: any[] = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 120,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.productName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      }
    }
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 115,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productCode',
    key: 'productCode'
  },
  {
    name: 'Available Quantity',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    width: 150,
    editable: false,
    textAlign: 'right',
    uiVisible: true,
    systemField: true,
    columnCode: 'availableQuantity',
    key: 'availableQuantity'
  },
  {
    name: 'Quantity As Per Bom',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    width: 170,
    editable: false,
    uiVisible: true,
    systemField: true,
    textAlignment: 'right',
    columnCode: 'quantityAsPerBOM',
    key: 'quantityAsPerBOM'
  },
  {
    name: 'Quantity to Request',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    width: 170,
    editable: true,
    uiVisible: true,
    systemField: true,
    textAlignment: 'right',
    columnCode: 'quantityToRequest',
    key: 'quantityToRequest'
  },
  {
    id: 'warehouse',
    columnCode: 'warehouse',
    key: 'warehouse',
    name: 'For Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 130,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.warehouseName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: {},
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: productWareHouseNameRenderer,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  }
];

export const SUBSTITUTE_PRODUCT_COLUMNS = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.productName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.warehouse?.name;
    },
    dropdownConfig: {
      title: 'Select location',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: {
        getUrl: null,
        dataParser: null,
        debounceTime: 300
      },
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    id: 'warehouse',
    key: 'warehouse',
    columnCode: 'warehouse'
  },
  {
    name: 'Available Qty',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 140,
    editable: false,
    uiVisible: true,
    systemField: true,
    textAlign: 'right',
    columnCode: 'availableQuantity',
    key: 'availableQuantity'
  },
  {
    name: 'Assign/Require',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 140,
    editable: true,
    uiVisible: true,
    systemField: true,
    textAlign: 'right',
    columnCode: 'assignedQuantity',
    key: 'assignedQuantity'
  }
];

export const SUBSTITUTE_PRODUCT_POPUP_TAB_COLUMNS = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.productName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productDocumentSeqCode',
    key: 'productDocumentSeqCode',
    id: 'productDocumentSeqCode'
  }
];

export const OPERATOR_COLUMN_CONFIG: any[] = [
  {
    name: 'Full Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    key: 'name',
    id: 'name',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Job Title',
    type: INPUT_TYPE.Text,
    index: 4,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'jobTitle',
    key: 'jobTitle',
    id: 'jobTitle',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  },
  // {
  //   name: 'Operator time',
  //   type: INPUT_TYPE.NUMBER,
  //   index: 4,
  //   options: [],
  //   required: false,
  //   width: 220,
  //   editable: false,
  //   hidden: false,
  //   uiVisible: true,
  //   columnCode: 'time',
  //   key: 'time',
  //   id: 'time',
  //   textAlign: 'right',
  //   systemField: true,
  //   allowFilter: false
  // },
  {
    name: 'Cost Per Hour',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    required: false,
    width: 135,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'costPerHour',
    key: 'costPerHour',
    id: 'costPerHour',
    textAlign: 'right',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Fixed Rate',
    type: INPUT_TYPE.NUMBER,
    index: 4,
    options: [],
    required: false,
    width: 135,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fixedRate',
    key: 'fixedRate',
    id: 'fixedRate',
    textAlign: 'right',
    systemField: true,
    allowFilter: true
  },
  // {
  //   name: 'Total Cost',
  //   type: INPUT_TYPE.NUMBER,
  //   index: 1,
  //   required: false,
  //   width: 135,
  //   editable: false,
  //   hidden: false,
  //   uiVisible: true,
  //   columnCode: 'totalCost',
  //   key: 'totalCost',
  //   id: 'totalCost',
  //   systemField: true,
  //   allowFilter: true,
  //   textAlign: 'right'
  // },
  {
    name: 'Email',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'email',
    key: 'email',
    id: 'email',
    textAlign: 'left',
    systemField: true,
    allowFilter: false
  },
  {
    name: 'Phone',
    type: INPUT_TYPE.TEXT,
    index: 2,
    options: [],
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'contactNumber',
    key: 'contactNumber',
    id: 'contactNumber',
    textAlign: 'left',
    systemField: true,
    allowFilter: true
  }
];

export const JC_KANBAN_STATUS_DATA: any[] = [
  {
    name: 'Open',
    status: 'OPEN',
    jbCard: []
  },
  {
    name: 'In Progress',
    status: 'IN_PROGRESS',
    jbCard: []
  },
  {
    name: 'On Hold',
    status: 'ON_HOLD',
    jbCard: []
  },
  {
    name: 'Cancelled',
    status: 'CANCELLED',
    jbCard: []
  },
  {
    name: 'Completed',
    status: 'COMPLETED',
    jbCard: []
  }
];

export const MACHINE_KANBAN_STATUS_DATA: any[] = [
  {
    name: 'Open',
    status: 'OPEN',
    jbCard: []
  },
  {
    name: 'In Progress',
    status: 'IN_PROGRESS',
    jbCard: []
  },
  {
    name: 'On Hold',
    status: 'ON_HOLD',
    jbCard: []
  },
  {
    name: 'Cancelled',
    status: 'CANCELLED',
    jbCard: []
  },
  {
    name: 'Completed',
    status: 'COMPLETED',
    jbCard: []
  }
];

export const MRP_JC_COLORS = {
  MRP_BG_BLUE: 'mrp_bg_blue',
  MRP_BG_ORANGE: 'mrp_bg_orange',
  MRP_BG_GRAY: 'bg-gray',
  MRP_BG_RED: 'mrp_bg_red',
  MRP_BG_GREEN: 'mrp_bg_green',
  MRP_BG_PURPLE_2: 'mrp_bg_purple2'
};

export const MRP_SCHEDULING_COLORS = {
  MRP_BG_PURPLE: 'mrp_bg_purple',
  MRP_BG_SEA_GREEN: 'mrp_bg_sea_green'
};

export const JC_STATUS = {
  OPEN: {
    title: 'Open',
    color: MRP_JC_COLORS.MRP_BG_BLUE,
    code: 'OPEN'
  },
  IN_PROGRESS: {
    title: 'In-Progress',
    color: MRP_JC_COLORS.MRP_BG_ORANGE,
    code: 'IN_PROGRESS'
  },
  ON_HOLD: {
    title: 'On-Hold',
    color: MRP_JC_COLORS.MRP_BG_PURPLE_2,
    code: 'ON_HOLD'
  },
  CANCELLED: {
    title: 'Cancelled',
    color: MRP_JC_COLORS.MRP_BG_RED,
    code: 'CANCELLED'
  },
  COMPLETED: {
    title: 'Completed',
    color: MRP_JC_COLORS.MRP_BG_GREEN,
    code: 'COMPLETED'
  }
};

export const JOB_CARD_CHIP_CONFIGURATION: any = {
  OPEN: {
    title: ' Not Started ', // renamed for now from 'OPEN'
    class: 'mrp_bg_blue text-white'
  },
  IN_PROGRESS: {
    class: 'mrp_bg_orange text-white',
    title: ' In Progress '
  },
  COMPLETED: {
    title: ' ✓ Completed ',
    class: 'mrp_bg_green text-white'
  },
  ON_HOLD: {
    title: ' On Hold ',
    class: 'mrp_bg_purple2 text-white'
  },
  CANCELLED: {
    title: ' Cancelled ',
    class: 'mrp_bg_red text-white'
  }
};

export const COMPONENT_PRODUCTS_COL_CONF = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.itemName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 120,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    id: 'documentSequenceCode'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    width: 100,
    editable: true,
    uiVisible: true,
    systemField: true,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: '',
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      searchApiConfig: {},
      data: [],
      renderer: (index: number, product: any) => {
        const name = product?.name || '-';
        return Utility.convertInTitleCase(name);
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    },
    columnCode: 'uom',
    key: 'uom',
    id: 'uom'
  },
  {
    name: 'Quantity',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 100,
    editable: true,
    uiVisible: true,
    systemField: true,
    formatter: (data: any) => {
      return data?.rowData?.productType === PRODUCT_TYPE.NON_TRACKED
        ? '-'
        : data?.value;
    },
    columnCode: 'quantity',
    key: 'quantity',
    id: 'quantity',
    textAlign: 'right'
  },
  {
    name: 'Enter Cost',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 100,
    editable: true,
    uiVisible: true,
    systemField: true,
    columnCode: 'cost',
    key: 'cost',
    id: 'cost',
    textAlign: 'right'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 160
  }
];

export const BY_PRODUCTS_COL_CONF = [
  {
    id: 'product',
    columnCode: 'product',
    key: 'product',
    name: 'Product Name',
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.itemName;
    },
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 120,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'documentSequenceCode',
    key: 'documentSequenceCode',
    id: 'documentSequenceCode'
  },
  {
    id: 'produceProductType',
    columnCode: 'produceProductType',
    key: 'produceProductType',
    name: 'Type',
    type: INPUT_TYPE.DROPDOWN,
    width: 90,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return Utility.convertInTitleCase(data?.rowData?.produceProductType);
    },
    dropdownConfig: {
      title: '',
      allowSearch: false,
      searchableKey: '',
      className: 'shadow-m width-auto',
      searchApiConfig: {},
      data: ['SCRAP', 'COPRODUCT'],
      renderer: (index: number, product: any) => {
        return Utility.convertInTitleCase(product);
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Quantity',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 100,
    editable: true,
    uiVisible: true,
    systemField: true,
    columnCode: 'quantity',
    key: 'quantity',
    id: 'quantity',
    textAlign: 'right'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 100,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'uom',
    key: 'uom',
    id: 'uom'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 80
  }
];

export const ADDITIONAL_COST_CONFT = [
  {
    name: 'Title',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 220,
    editable: true,
    uiVisible: true,
    systemField: true,
    columnCode: 'label',
    key: 'label',
    id: 'label'
  },
  {
    name: 'Value',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    width: 220,
    editable: true,
    uiVisible: true,
    systemField: true,
    columnCode: 'price',
    key: 'price',
    id: 'price'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 120
  }
];

export const OPERATION_COST_CONFT = [
  {
    id: 'operation',
    columnCode: 'operation',
    key: 'operation',
    name: 'Title',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.operationName;
    },
    dropdownConfig: {
      title: 'Select Operation',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, operation: any) => {
        return operation?.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Time (Mins)',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'operationTime',
    key: 'operationTime',
    id: 'operationTime',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Dependent On',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'opDependency',
    key: 'opDependency',
    id: 'opDependency',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: 'Select Dependent',
      allowSearch: true,
      // multiSelect: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      selectedIndexes: [],
      renderer: (index: number, operation: any) => {
        return operation?.name ?? '';
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Process Type',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 120,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'processType',
    key: 'processType',
    id: 'processType',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      allowSearch: false,
      style: { minWidth: 120 },
      className: 'shadow-m width-auto',
      data: [],
      renderer: (index: number, option: any) => {
        return option?.label;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Items',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'product',
    key: 'product',
    id: 'product',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: 'Select Item',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 130 },
      className: 'shadow-m width-auto',
      data: [],
      renderer: (index: number, value: any) => {
        return value?.itemName ?? '';
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'QC Needed',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 100,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'qcNeeded',
    key: 'qcNeeded',
    id: 'qcNeeded',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      data: ['Yes', 'No'],
      renderer: (index: number, value: any) => {
        return value;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Cost Per Hour',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'costPerHour',
    key: 'costPerHour',
    id: 'costPerHour',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Fixed Rate',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'fixedRate',
    key: 'fixedRate',
    id: 'fixedRate',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Total Cost',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'totalCost',
    key: 'totalCost',
    id: 'totalCost',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100,
    options: []
  }
];

export const MRP_PRODUCT_PURCHASE_PRICE_COLUMN = {
  name: 'Purchase Price',
  type: INPUT_TYPE.NUMBER,
  index: 1,
  required: false,
  width: 150,
  editable: false,
  hidden: false,
  uiVisible: true,
  columnCode: COLUMN_CODE.PRODUCT.PurchasePrice,
  key: COLUMN_CODE.PRODUCT.PurchasePrice,
  id: COLUMN_CODE.PRODUCT.PurchasePrice,
  systemField: true,
  allowFilter: true,
  textAlign: 'right'
};

export const JOB_CARD_ALLOWED_FILTER_COLUMNS = [
  'workStationName',
  'workOrderSeqCode',
  'salesOrderSequenceCode',
  'operationName',
  'status',
  'workOrderItemName',
  'jobCardCode'
];

export const JC_VIEW_INDEX = {
  KANBAN: 'Kanban',
  GRID: 'Grid'
};

export const STOCK_TRANSFER_VIEW_INDEX = {
  INTERNAL: 'Internal',
  EXTERNAL: 'External'
};

export const FILTER_JOB_CARD = {
  status: 'status',
  operator: 'operator',
  workStation: 'workStation'
};

export const QC_PARAMETERS_COL_CONF = [
  {
    name: 'Parameter',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'label',
    key: 'label',
    id: 'label'
  },
  {
    name: 'Options',
    type: INPUT_TYPE.TEXT,
    index: 1,
    width: 180,
    editable: true,
    uiVisible: true,
    systemField: true,
    columnCode: 'attributes',
    key: 'attributes',
    id: 'attributes',
    renderer: (obj: any) => {}
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100
  }
];

export const QC_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  NONE: 'NONE'
};

export const PRODUCTION_REPORT_COLUMN_CONFIG = [
  {
    name: 'WO Number',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'workOrderSeqCode',
    key: 'workOrderSeqCode',
    allowColumnSort: true
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productSequenceCode',
    key: 'productSequenceCode'
  },
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productName',
    key: 'productName',
    allowColumnSort: true
  },
  {
    name: 'SO/Invoice Number',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'salesOrderSeqCode',
    key: 'salesOrderSeqCode',
    allowColumnSort: true
  },
  {
    name: 'WO Date',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'woEndDate',
    key: 'woEndDate',
    allowColumnSort: true
  },
  {
    name: 'Quantity Produced',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'quantityProduced',
    key: 'quantityProduced',
    textAlign: 'right',
    allowColumnSort: true
  },
  {
    name: 'Warehouse',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'warehouseName',
    key: 'warehouseName',
    allowColumnSort: false
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'uom',
    key: 'uom',
    allowColumnSort: false
  }
];

export const PRODUCTINO_REPORT_CHILD_COLUMN_CONFIG = [
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productName',
    key: 'productName',
    allowColumnSort: false
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'productSequenceCode',
    key: 'productSequenceCode'
  },
  {
    name: 'Quantity Consumed',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'quantityConsumed',
    key: 'quantityConsumed',
    textAlign: 'right'
  },
  {
    name: 'Quantity Produced',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'quantityProduced',
    key: 'quantityProduced',
    textAlign: 'right'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'uom',
    key: 'uom'
  },
  {
    name: 'Batch Details',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: false,
    columnCode: 'serialBatchData',
    key: 'serialBatchData'
  }
];

export const PRODUCTION_SUMMARY_COLUMN_CONFIG = [
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'productSequenceCode',
    key: 'productSequenceCode'
  },
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 1,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'productName',
    key: 'productName'
  },
  {
    name: 'Quantity Produced',
    type: INPUT_TYPE.NUMBER,
    index: 2,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    allowColumnSort: true,
    columnCode: 'quantityProduced',
    key: 'quantityProduced',
    textAlign: 'right'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'uom',
    key: 'uom',
    allowColumnSort: false
  }
];

export const FG_COMPONENT_TRACKER_REPORT_COLUMN_CONFIG = [
  {
    name: 'FG Name',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'fg_name',
    key: 'fg_name'
  },
  {
    name: 'FG Code',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'fg_code',
    key: 'fg_code'
  },
  {
    name: 'Quantity',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'qty',
    key: 'qty',
    textAlign: 'right'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'uom',
    key: 'uom'
  },
  {
    name: 'Serial/Batch',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'serial/batch',
    key: 'serial/batch'
  },
  {
    name: 'Manufacturing Date',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'mfg_date',
    key: 'mfg_date'
  },
  {
    name: 'Expiry Date',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'exp_date',
    key: 'exp_date'
  },
  {
    name: 'WO Number',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'wo_number',
    key: 'wo_number'
  }
];

export const FG_COMPONENT_TRACKER_REPORT_CHILD_COLUMN_CONFIG = [
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productName',
    key: 'productName'
  },
  {
    name: 'Product Code',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'productCode',
    key: 'productCode'
  },
  {
    name: 'Quantity Consumed',
    type: INPUT_TYPE.NUMBER,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'quantityConsumed',
    key: 'quantityConsumed',
    textAlign: 'right'
  },
  {
    name: 'UOM',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'uom',
    key: 'uom'
  },
  {
    name: 'Serial Batch Numbers',
    type: INPUT_TYPE.TEXT,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'serialBatchNumbers',
    key: 'serialBatchNumbers'
  },
  {
    name: 'Manufacturing Date',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'manufacturingDate',
    key: 'manufacturingDate'
  },
  {
    name: 'Expiry Date',
    type: INPUT_TYPE.DATE,
    index: 3,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'expiryDate',
    key: 'expiryDate'
  }
];

export const FORECAST_COLUMNS: any[] = [
  {
    name: 'Title',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 220,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'name',
    id: 'name',
    columnCode: 'name'
  },
  {
    name: 'Material Name',
    index: 1,
    type: INPUT_TYPE.TEXT,
    width: 220,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'productName',
    id: 'productName',
    columnCode: 'productName',
    allowColumnSort: false
  },
  {
    name: 'Period',
    index: 2,
    type: INPUT_TYPE.TEXT,
    width: 425,
    systemField: true,
    editable: false,
    hidden: false,
    required: true,
    uiVisible: true,
    textAlign: 'left',
    key: 'period',
    id: 'period',
    columnCode: 'period',
    allowColumnSort: false
  },
  {
    name: 'Period Frequency',
    type: INPUT_TYPE.SELECT,
    options: [
      {
        id: 'WEEKLY',
        name: 'Weekly',
        color: 'data-grid-badge-color-3'
      },
      {
        id: 'MONTHLY',
        name: 'Monthly',
        color: 'data-grid-badge-color-1'
      },
      {
        id: 'YEARLY',
        name: 'Yearly',
        color: 'data-grid-badge-color-5'
      },
      {
        id: 'QUARTERLY',
        name: 'Quarterly',
        color: 'data-grid-badge-color-7'
      }
    ],
    systemField: true,
    index: 3,
    required: false,
    width: 160,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'periodFrequency',
    key: 'periodFrequency',
    id: 'periodFrequency'
  }
];

export const WO_JWO_COLUMNS = [
  {
    name: 'JWO Code',
    key: 'documentSequenceCode',
    type: INPUT_TYPE.TEXT,
    width: 120
  },
  {
    name: 'Dispatch Status',
    key: 'dispatchStatus',
    type: INPUT_TYPE.TEXT,
    width: 130
  },
  {
    name: 'Goods Receipt Status',
    key: 'receiptStatus',
    type: INPUT_TYPE.TEXT,
    width: 130
  },
  {
    name: 'Billing Status',
    key: 'billedStatus',
    type: INPUT_TYPE.TEXT,
    width: 130
  },
  {
    name: 'Total Cost',
    key: 'totalAmount',
    type: INPUT_TYPE.TEXT,
    width: 140
  }
];

export const WO_OPERATION_COLUMN = [
  {
    name: 'Operation',
    key: 'operationName',
    type: INPUT_TYPE.DROPDOWN,
    width: 132,
    dropdownConfig: {
      title: '',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, product: any) => {
        return product.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Process Type',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'processType',
    key: 'processType',
    id: 'processType',
    displayKey: 'label',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      allowSearch: false,
      style: { minWidth: 120 },
      className: 'shadow-m width-auto',
      displayKey: 'label',
      data: []
    }
  },
  {
    name: 'Items',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'product',
    key: 'product',
    id: 'product',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: 'Select Item',
      allowSearch: true,
      searchableKey: 'itemName',
      style: { minWidth: 130 },
      className: 'shadow-m width-auto',
      data: []
    }
  },
  {
    name: 'QC Needed',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 120,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'qcNeeded',
    key: 'qcNeeded',
    id: 'qcNeeded',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      data: ['Yes', 'No']
    }
  },
  {
    name: 'Duration',
    key: 'operationTime',
    type: INPUT_TYPE.NUMBER,
    width: 150
  },
  {
    name: 'Operation Cost',
    key: 'operationCost',
    type: INPUT_TYPE.NUMBER,
    width: 150
  },
  {
    name: 'Operator Cost',
    key: 'operatorCost',
    type: INPUT_TYPE.NUMBER,
    width: 150
  },
  {
    name: 'Workstation Cost',
    key: 'workstationCost',
    type: INPUT_TYPE.NUMBER,
    width: 180
  },
  {
    name: 'LinkedIn Machines',
    key: 'linkedInMachines',
    type: INPUT_TYPE.NUMBER,
    width: 250,
    editable: false
  },
  {
    name: '',
    key: 'actions',
    type: INPUT_TYPE.BUTTON,
    width: 120
  }
];

export const WO_ADDITIONAL_CHARGES_COLUMNS = [
  {
    name: 'Name',
    key: 'name',
    type: INPUT_TYPE.TEXT,
    width: 220,
    required: true,
    editable: true
  },
  {
    name: 'Charge Value',
    key: 'chargeValue',
    type: INPUT_TYPE.NUMBER,
    width: 120,
    required: true,
    editable: true
  },
  {
    name: 'Type',
    key: 'additionalCostType',
    type: INPUT_TYPE.NUMBER,
    width: 120
  },
  {
    name: 'Total',
    key: 'chargeValue',
    type: INPUT_TYPE.NUMBER,
    textAlign: 'right',
    width: 160
  },
  {
    name: '',
    key: 'actions',
    type: INPUT_TYPE.BUTTON,
    options: [],
    width: 46
  }
];

export const COST_CONFIG_TYPE = {
  WO: 'Work Order',
  BOM: 'BOM'
};

export const WO_ADDITIONAL_CHARGES_KEYS = {
  NAME: 'name',
  CHARGE_VALUE: 'chargeValue',
  ACTION: 'actions',
  TOTAL: 'Total',
  CHARGE_VALUE_TITLE: 'Charge Value',
  PRE_CHARGES_SUB_TOTAL: 'SUB_TOTAL',
  PRE_CHARGES_TOTAL: 'TOTAL'
};

export const BOM_SELECTION_POPUP_CONFIG = [
  {
    id: 'name',
    key: 'name',
    name: 'Product',
    type: INPUT_TYPE.TEXT,
    width: 300,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'selectedBom',
    key: 'selectedBom',
    name: 'BOM',
    type: INPUT_TYPE.DROPDOWN,
    width: 400,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    formatter: (obj: any) => {
      return obj?.rowData?.selectedBom?.name ?? '';
    },
    dropdownConfig: {
      title: 'Select BOM',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 400 },
      className: 'shadow-m width-auto',
      searchApiConfig: null,
      data: [],
      renderer: (index: number, obj: any) => {
        return obj.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  }
];

export const ROUTING_TEMPLATES_COLS: any[] = [
  {
    name: 'Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 240,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'name',
    key: 'name',
    id: 'name',
    systemField: true,
    allowFilter: true,
    allowColumnSort: false
  },
  {
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 440,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'description',
    key: 'description',
    id: 'description',
    systemField: true,
    allowFilter: false,
    renderer: null,
    allowColumnSort: false
  }
];

export enum BOM_EXPLOSION_COLUMN_KEYS {
  EXPANDED = 'expanded',
  PRODUCT_SELECTION = 'selected',
  PRODUCT_CODE = 'productCode',
  PRODUCT_NAME = 'productName',
  AVAILABLE_QUANTITY = 'availableQuantity',
  AVAILABLE_UOM_QUANTITY = 'availableUomQuantity',
  TOTAL_AVAILABLE_STOCK_IN_TAGGED_WH = 'totalAvailableStockInTaggedWH',
  ALLOTED_QUANTITY = 'allotedQuantity',
  REQUIRED_QUANTITY = 'requiredQuantity',
  REQUIRED_UNIT_QUANTITY = 'quantityRequired',
  UOM = 'uom',
  AVAILABILITY = 'availability',
  AVAILABILITY_TARGET_WH = 'availabilityTargetWH',
  LEAD_TIME = 'leadTime',
  RECEIVED_BY = 'displayReceivedBy',
  PRODUCT_TYPE = 'displayProductType',
  LINKED_WO_CODE = 'linkedWOCode',
  LINKED_DOC_CODE = 'linkedDocCode',
  LINKED_JWO_DOC_CODE = 'linkedJwoDocCode',
  ACTIONS = 'actions',
  SUBSTITUTE = 'isExplodeSubstitute',
  TAGGED_WH_DETAIL = 'taggedWHProductAvailabilityDetails',
  COMPONENT_PRODUCTS = 'bomProductConfiguration',
  AVAILABLE_SUBSTITUTES = 'bomProductSubstitutesDetails',
  ASSIGNED_SUBSTITUTES = 'productSubstitutesDetails',
  CHARGES = 'costPerUnit',
  TOTAL_AMOUNT = 'amount',
  STOCK_UOM = 'stockUom',
  OLD_STOCK_UOM = 'oldStockUOM',
  ADVANCED_TRACKING = 'advancedTracking',
  MASTER_HEADER_CHECKBOX = 'masterHeaderCheckbox'
}

export const BOM_EXPLOSION_CUSTOM_EXPAND_COLUMN_CONFIG = {
  id: 'expanded',
  key: 'expanded',
  columnCode: 'expanded',
  name: ' ',
  type: INPUT_TYPE.TEXT,
  width: 40,
  systemField: true,
  editable: false,
  hidden: false,
  uiVisible: true,
  textAlign: 'left'
};

export enum PRODUCT_SUBSTITUTE_COLUMN_KEYS {
  PRODUCT_DOCUMENT_SEQ_CODE = 'productDocumentSeqCode',
  PRODUCT_NAME = 'productName',
  DESCRIPTION = 'description',
  BARCODE = 'barcode',
  STOCK_UOM_STRING = 'stockUomString',
  LEAD_TIME = 'leadTime',
  WEIGHT = 'weight',
  AVAILABLE_QUANTITY = 'availableQuantity',
  RESERVED_QUANTITY = 'reservedQuantity'
}

export const ROUTING_TEMPLATE_OPERATION_CONFT = [
  {
    id: 'operation',
    columnCode: 'operation',
    key: 'operation',
    name: 'Title',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: (data: any) => {
      return data?.rowData?.operationName;
    },
    dropdownConfig: {
      title: 'Select Operation',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      renderer: (index: number, operation: any) => {
        return operation?.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Time (Mins)',
    type: INPUT_TYPE.NUMBER,
    index: 1,
    required: false,
    width: 100,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'operationTime',
    key: 'operationTime',
    id: 'operationTime',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Dependent On',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'opDependency',
    key: 'opDependency',
    id: 'opDependency',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: 'Select Dependent',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 150 },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        getUrl: null,
        dataParser: {},
        debounceTime: 300
      },
      data: [],
      selectedIndexes: [],
      renderer: (index: number, operation: any) => {
        return operation?.name ?? '';
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Process Type',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 120,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'processType',
    key: 'processType',
    id: 'processType',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      allowSearch: false,
      style: { minWidth: 120 },
      className: 'shadow-m width-auto',
      data: [],
      renderer: (index: number, option: any) => {
        return option?.label;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'Items',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 150,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'product',
    key: 'product',
    id: 'product',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: 'Select Item',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 130 },
      className: 'shadow-m width-auto',
      data: [],
      renderer: (index: number, value: any) => {
        return value?.itemName ?? '';
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    name: 'QC Needed',
    type: INPUT_TYPE.DROPDOWN,
    index: 1,
    required: false,
    width: 100,
    editable: true,
    hidden: false,
    uiVisible: true,
    columnCode: 'qcNeeded',
    key: 'qcNeeded',
    id: 'qcNeeded',
    systemField: true,
    allowFilter: false,
    dropdownConfig: {
      title: '',
      allowSearch: false,
      style: { minWidth: 100 },
      className: 'shadow-m width-auto',
      data: ['Yes', 'No'],
      renderer: (index: number, value: any) => {
        return value;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'action',
    key: 'action',
    name: '',
    type: INPUT_TYPE.BUTTON,
    width: 100,
    options: []
  }
];

export const soToWoReportColumnConfigs = [
  {
    name: 'Sales order Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'salesOrderNumber',
    key: 'salesOrderNumber',
    id: 'salesOrderNumber',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Product Name',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'productName',
    key: 'productName',
    id: 'productName',
    systemField: true,
    allowFilter: true
  },
  {
    name: 'Order Qty',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'orderQty',
    key: 'orderQty',
    id: 'orderQty',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Work order Qty',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'workOrderQty',
    key: 'workOrderQty',
    id: 'workOrderQty',
    systemField: true,
    allowFilter: true,
    textAlign: 'right'
  },
  {
    name: 'Work order Number',
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: [],
    required: false,
    width: 170,
    editable: false,
    hidden: false,
    uiVisible: true,
    columnCode: 'workOrderNumber',
    key: 'workOrderNumber',
    id: 'workOrderNumber',
    systemField: true,
    allowFilter: true
  }
];

export const COLUMN_CONFIGS_FOR_FULFILLMENT = [
  {
    id: 'products',
    key: 'products',
    name: 'Products',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'warehouse',
    key: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    formatter: (data: any) => {
      return data.value?.name;
    },
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: true,
      searchableKey: 'warehouseCode',
      className: 'shadow-m width-auto',
      data: [],
      renderer: (index: number, value: any) => {
        return value.name;
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'uom',
    key: 'uom',
    name: 'Uom',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'required',
    key: 'required',
    name: 'Required',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    formatter: (obj: any = 0) => {
      return `${Utility.roundingOff(
        Number(obj.value),
        QTY_ROUNDOFF_PRECISION
      )}`;
    }
  },
  {
    id: 'committing',
    key: 'committing',
    name: 'Committing',
    type: INPUT_TYPE.NUMBER,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    formatter: (obj: any = 0) => {
      return `${Utility.roundingOff(
        Number(obj.value),
        QTY_ROUNDOFF_PRECISION
      )}`;
    }
  }
];

export const COLUMN_CONFIGS_FOR_RG = [
  {
    id: 'products',
    key: 'products',
    name: 'Products',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'warehouse',
    key: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    formatter: (data: any) => {
      return data.value?.name;
    },
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: true,
      searchableKey: 'warehouseCode',
      className: 'shadow-m width-auto',
      data: [],

      searchApiConfig: {
        getUrl: (searchValue: string) => {
          const query: string = `?limit=50&page=0&search=${searchValue}&allRRBDetails=true&query=active=true`;
          const finalEndPoint =
            ApiConstants.URL.BASE +
            ApiConstants.URL.ACCOUNTS.WAREHOUSES +
            query;
          return finalEndPoint;
        },
        dataParser: (response: any) => {
          let filtered = response?.content?.filter(
            (wh: any) => wh.warehouseType === 'NONE'
          );
          return filtered.map((warehouse: any) => {
            return {
              label: warehouse.name,
              value: warehouse.code
            };
          });
        },
        debounceTime: 300
      },
      onSelect: (index: any, obj: any, rowIndex: any) => {}
    }
  },
  {
    id: 'uom',
    key: 'uom',
    name: 'Uom',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'required',
    key: 'required',
    name: 'Required',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    formatter: (obj: any = 0) => {
      return `${Utility.roundingOff(
        Number(obj.value),
        QTY_ROUNDOFF_PRECISION
      )}`;
    }
  },
  {
    id: 'receiving',
    key: 'receiving',
    name: 'Receiving',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'left',
    formatter: (obj: any = 0) => {
      return `${Utility.roundingOff(
        Number(obj.value),
        QTY_ROUNDOFF_PRECISION
      )}`;
    }
  }
];

export const COLUMN_CONFIGS_FOR_FF_RECORD = [
  {
    id: 'products',
    key: 'products',
    name: 'Products',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'uom',
    key: 'uom',
    name: 'Uom',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'required',
    key: 'required',
    name: 'Required',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'committing',
    key: 'committing',
    name: 'committing',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  }
];
export const COLUMN_CONFIGS_FOR_PPS_RECORD = [
  {
    id: 'products',
    key: 'products',
    name: 'Products',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'description',
    key: 'description',
    name: 'Description',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'required',
    key: 'required',
    name: 'Required',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  },
  {
    id: 'committing',
    key: 'committing',
    name: 'committing',
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'left'
  }
];

export const WO_GRID_LAST_UPDATED_COLUMN = [
  {
    name: 'Last Updated By',
    type: INPUT_TYPE.SELECT,
    index: 3,
    options: [],
    required: false,
    allowFilter: false,
    allowColumnSort: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'updatedBy',
    key: 'updatedBy',
    id: 'updatedBy'
  },
  {
    name: 'Last Updated On',
    type: INPUT_TYPE.DATE,
    index: 3,
    required: false,
    allowFilter: false,
    allowColumnSort: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'updatedOn',
    key: 'updatedOn',
    id: 'updatedOn'
  }
];

export const FORECAST_DRIVEN_PO_COLS: any[] = [
  {
    id: 'periodName',
    key: 'periodName',
    columnCode: 'periodName',
    name: ' Forecast Period',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 150,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: true
  },
  {
    id: 'productName',
    key: 'productName',
    columnCode: 'productName',
    name: 'Product Name',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 140,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'productDescription',
    key: 'productDescription',
    columnCode: 'productDescription',
    name: 'Product Description',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 190,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'productDocSeqCode',
    key: 'productDocSeqCode',
    columnCode: 'productDocSeqCode',
    name: 'Product Id',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 125,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'preferredSupplier',
    key: 'preferredSupplier',
    columnCode: 'preferredSupplier',
    name: 'Preferred Supplier',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 165,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'quantityToOrder',
    key: 'quantityToOrder',
    columnCode: 'quantityToOrder',
    name: 'Quantity to Order',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 165,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'leadTime',
    key: 'leadTime',
    columnCode: 'leadTime',
    name: 'Lead Time ',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 130,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'orderByDate',
    key: 'orderByDate',
    columnCode: 'orderByDate',
    name: 'Place Order By Date',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 180,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    id: 'status',
    key: 'status',
    columnCode: 'status',
    name: 'Status',
    index: 5,
    type: INPUT_TYPE.SELECT,
    options: [
      {
        id: 'PENDING',
        name: 'Pending',
        color: 'data-grid-badge-color-10'
      },
      {
        id: 'ORDERED',
        name: 'Ordered',
        color: 'data-grid-badge-color-6'
      }
    ],
    width: 110,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true,
    allowColumnSort: false
  },
  {
    key: 'actions',
    name: 'Actions',
    type: INPUT_TYPE.BUTTON,
    width: 130,
    allowColumnSort: false
  }
];

export const FORECAST_DRIVEN_PO_COLS_LINKED_DOC: any[] = [
  {
    id: 'docType',
    key: 'docType',
    columnCode: 'docType',
    name: 'Document Type',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'documentSequenceCode',
    key: 'documentSequenceCode',
    columnCode: 'documentSequenceCode',
    name: 'Document Code',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'productDocumentSequenceCode',
    key: 'productDocumentSequenceCode',
    columnCode: 'productDocumentSequenceCode',
    name: 'Product Code',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: 'productName',
    key: 'productName',
    columnCode: 'productName',
    name: 'Product Name',
    index: 5,
    type: INPUT_TYPE.TEXT,
    width: 250,
    systemField: false,
    editable: false,
    hidden: false,
    uiVisible: true
  }
];
