import {
  BUY_TYPE,
  DOCUMENT_MODE,
  DOC_TYPE,
  DropdownSelectionType,
  FORM_ELEMENTS,
  PAYMENT_STATUS,
  RECEIVED_GOODS_STATUS,
  TEXT_ALIGNMENT
} from '../Constants/Constant';
import {
  GridItem,
  GRID_COLUMN_NAME,
  GRID_INPUT_STYLE,
  GRID_ITEMS,
  HeaderItem
} from './Grid';
import { CallBackPayloadType, SequenceFormat } from './Interfaces';
import { DropdownTag } from './NewContact';
import { CalendarType } from './PaymentPopup';

export interface ExpenseBillProps {
  passingInteraction: (callback: CallBackPayloadType) => void;
  documentMode: DOCUMENT_MODE;
  draftData?: any;
  formData?: any;
  isCenterAlign?: boolean;
  populateFormData?: any;
  canValidate?: boolean;
  width?: string;
  auditLogView?: boolean;
}

export interface ExpenseBillState {
  contact: any;
  billDate: CalendarType;
  dueDate: CalendarType;
  gridInfo: GridInfo;
  summaryInfo: SummaryInfo;
  documentType: DOC_TYPE;
  sequenceFormat: any;
  manualMode: boolean;
  documentSequenceCode: string;
  memo: string;
  isDocumentTouched?: boolean;
}

interface GridInfo {
  gridName: string;
  headerConfig: HeaderItem[];
  inlineItems: GridItem[];
}

interface SummaryInfo {
  subTotal: number;
  taxAmount: number;
  totalAmount: number;
  currency: string;
  discount: number;
}

export const expenseBillGridItem: any = {
  account: null,
  description: '',
  amount: 0,
  tax: null,
  taxAmount: '',
  totalAmount: 0,
  paymentAmount: {
    defaultValue: 0,
    itemType: GRID_ITEMS.FORMAT_AMOUNT,
    columnName: GRID_COLUMN_NAME.PAYMENT_AMOUNT
  }
};

export const initialExpenseBillState = (tenantInfo: any): any => {
  return {
    contact: null,
    customField: null,
    dueDate: { isOpen: false, date: '24-08-2021' },
    billDate: { isOpen: false, date: '24-08-2021' },
    sequenceFormat: '',
    documentSequenceCode: '',
    supplierInvoiceNo: '',
    manualMode: false,
    gridInfo: {
      gridName: 'New Expense',
      headerConfig: [
        {
          text: 'Account',
          textAlign: TEXT_ALIGNMENT.LEFT,
          width: 200,
          minWidth: 200
        },
        {
          text: 'Description',
          textAlign: TEXT_ALIGNMENT.LEFT,
          width: 160
        },
        {
          text: 'Amount',
          textAlign: TEXT_ALIGNMENT.RIGHT,
          width: 120,
          minWidth: 120,
          maxWidth: 120
        },
        {
          text: 'Tax',
          textAlign: TEXT_ALIGNMENT.RIGHT,
          width: 120,
          minWidth: 120
        },
        {
          text: 'Tax Amount',
          textAlign: TEXT_ALIGNMENT.RIGHT,
          width: 130
        },
        {
          text: `Amount(${tenantInfo.currency})`,
          textAlign: TEXT_ALIGNMENT.RIGHT,
          width: 180,
          minWidth: 180
        },
        {
          text: '',
          textAlign: TEXT_ALIGNMENT.RIGHT,
          width: 30,
          minWidth: 30
        }
      ],
      inlineItems: []
    },
    summaryInfo: {
      subTotal: 0,
      taxAmount: 0,
      totalAmount: 0,
      currency: '',
      discount: 0
    },
    documentType: DOC_TYPE.EXPENSE,
    memo: '',
    docCurrencyCode: '',
    docExchangeRate: 1,
    gstExchangeRate: 1,
    amountInBaseCurrency: 0,
    previousExchangeRate: 1,
    isDocumentTouched: false,
    applyRcmCheck: false,
    seqCodeAlreadyDumped: false
  };
};

export enum EXPENSE_BILL_CALENDAR_TYPE {
  BILL_DATE = 'billDate',
  DUE_DATE = 'dueDate'
}

export interface ExpenseBillPayload {
  contactCode: string;
  currency: string;
  paymentTerm: string;
  currencyCode: string;
  customField?: any[];
  status: string;
  sequenceFormat?: string;
  manualMode?: boolean;
  documentType: DOC_TYPE;
  documentCode?: string;
  exchangeRate: number;
  gstExchangeRate?: number;
  primaryExchangeRate?: number;
  shipFrom: any;
  shipTo: any;
  billTo: any;
  contact: any;
  documentDate: string;
  validTillDate: string;
  fulfillmentDate: string;
  purchaseInvoiceDate: string;
  purchaseInvoiceDueDate: string;
  receiveGoodsStatus: RECEIVED_GOODS_STATUS;
  buyType: BUY_TYPE;
  dropShip: boolean;
  purchaseInvoiceType: DOC_TYPE;
  purchaseInvoiceAccounts: InvoiceAccount[];
  totalAmount: number;
  placeOfSupply: string;
  dueAmount?: number;
  id?: number;
  entityId?: number;
  purchaseInvoiceCode?: string;
  documentSequenceCode?: string;
  openingInvoice: boolean;
  paymentStatus?: PAYMENT_STATUS;
  memo?: string;
  attachmentIds?: any;
  attachments?: any;
  totalTdsAmount?: number;
  taxSystem?: string;
  totalAmountInBaseCurrency?: number;
  tdsProcessedFlag?: any;
  roundOffAmountInBaseCurrency?: number;
  roundOffAmountInDocumentCurrency?: number;
  isDocumentTouched?: boolean;
  journalEntryCode?: string;
  unitPriceGstInclusive?: boolean;
  supplierInvoiceNo?: string;
  gstin?: string;
  applyRcmCheck?: boolean;
  approvalStatus?: string;
  seqCodeAlreadyDumped: boolean;
  draftReferenceId: string;
}

export interface InvoiceAccount {
  name: string;
  accountCode: string;
  accountDescription: string;
  tax?: any;
  taxDetails?: any[];
  taxCode: string;
  taxName: string;
  taxAmount: number;
  amount: number;
  currency: string;
  totalAmount: number;
  lineNumber: number;
  id?: number;
  invalidFields?: string[];
  tdsInfoIndia?: any;
  tdsRate?: number;
  tdsAmount?: number;
  customField?: any[];
  isTdsApplicableAccount?: boolean;
  isTdsApplicableContact?: boolean;
  isTdsApplicableProduct?: boolean;
  userSetTaxes?: boolean;
  itcAdjustment?: string;
  itcIneligibleType?: string;
  glAccountCode?: string;
}
