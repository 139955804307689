import { useEffect, useMemo, useRef, useState } from 'react';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import Utility, {
  deepClone,
  getCapitalized,
  isDocumentLocked
} from '../../Utility/Utility';
import {
  PRODUCTION_COL_SO,
  PRODUCTION_STATUS_OPTIONS,
  SO_COL,
  STATIC_ORDER_PROGRESS,
  salesOrderInitialState
} from '../../Constants/OrderConstants';
import DataGridHolder from '../../SharedComponents/DataGridHolder';
import {
  COLUMN_CODE,
  REMOTE_CONFIG_TABLES,
  TABLES,
  TABLE_DISPLAY_NAME
} from '../../Constants/TableConstants';
import {
  showAlert,
  showLoader,
  removeLoader,
  DKButton,
  DKIcons,
  showToast,
  TOAST_TYPE,
  DKLabel,
  DKTooltipWrapper,
  DKIcon,
  INPUT_TYPE,
  DKListPicker
} from 'deskera-ui-library';
import SalesOrderService, {
  defaultConfig,
  SalesOrderAPIConfig
} from '../../Services/SalesOrder';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  activeTenantInfo,
  featurePermissions,
  subscriptionInfo
} from '../../Redux/Slices/AuthSlice';
import {
  addSalesOrderColumnConfig,
  fetchSalesOrders,
  selectSalesOrder,
  selectSalesOrderColumnConfig,
  selectSalesOrderColumnConfigTableId,
  updateColumnConfig
} from '../../Redux/Slices/SalesOrderSlice';
import DateFormatService from '../../Services/DateFormat';
import {
  APPROVAL_STATUS_LIST,
  BOOKS_DATE_FORMAT,
  COLUMN_TYPE,
  COUNTRY_CODES,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  DOC_TYPE,
  EXPORT_FILE_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  IMPORT_CONSTANTS,
  LABELS,
  MODULES_NAME,
  MODULE_NAME_FOR_STORAGE_UTILITY,
  MODULE_TYPE,
  OPENING_DOCUMENT_LABEL,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  RECURRING_DOCUMENT_TYPE,
  TemplateMappingEvent,
  TEMPLATE_CATEGORY,
  TAX_SYSTEM,
  MRP_APP_NAME,
  MOBILE_APP_ACTIONS,
  PRODUCT_TYPE,
  UOM_NA_ID,
  STATUS_TYPE,
  APPROVAL_STATUS,
  FILTER_KEYS
} from '../../Constants/Constant';
import {
  salesOrderFilter,
  selectApplySalesPersonFilter,
  selectSalesOrderFilter
} from '../../Redux/Slices/BookFilterSlice';
import useDebounce from '../../Hooks/useDebounce';
import {
  bulkDeleteDrafts,
  createBlankDraft,
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  payloadColumnKey,
  removeDraft,
  selectDrafts,
  selectDraftsColumnConfig,
  selectSalesOrderDraftsData,
  setDraftCenter,
  setPopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import {
  SelectEmailTriggerIdsData,
  selectCurrencyListWithExchangeRate,
  selectCustomFields,
  setEmailTriggerIds
} from '../../Redux/Slices/CommonDataSlice';
import { DraftTypes } from '../../Models/Drafts';

import {
  getUpdatedSalesOrderObject,
  salesOrderEmailTemplateConfig
} from './SalesOrderHelper';
import SellConfig, { SELL_CONFIG_BTN } from '../../Configs/SellConfig';
import { MainDocumentGridSettings } from '../../SharedComponents/MainDocumentGridSettings';
import {
  FILTER_DATE,
  SessionStorageService,
  SHOW_TOTAL_IN_BASE_CURRENCY
} from '../../Services/SessionStorageService';
import {
  BtnType,
  CallBackPayloadType,
  IState,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useConfirm from '../../Hooks/useConfirm';
import { SalesOrder, SalesOrderInitialState } from '../../Models/SalesOrder';
import { InvoiceInitialState } from '../../Models/Invoice';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import SalesOrderToInvoicePopup from './SalesOrderToInvoice';
import PrintService from '../../Services/Print';
import ApiConstants from '../../Constants/ApiConstants';
import {
  getBulkPrintPreview,
  getPrintPreview,
  getEmailPopup,
  getMinimizedDocInfo,
  getTenantTaxSystem,
  getEmailStatusForGrid,
  checkMultiApprovalRequired,
  showAlertOnDocAPIError,
  getApprovalLevel,
  isSGAndPeppolOptInEnabled,
  addressRenderer,
  getAdvanceTrackingStatusFromLineItems,
  LINEITEMS_ADV_TRACK_STATUS,
  isStockReservedInDocLineItems
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Fulfillment from '../../SharedComponents/FulfillmentPopup/Fulfillment';
import FulfillmentConfirmation from '../../SharedComponents/FulfillmentPopup/FulfillmentConfirmationPopup';
import ApprovalOrReject from '../../SharedComponents/FulfillmentPopup/ApprovalOrRejectPopup';
import FulfillmentService from '../../Services/FulfillmentService';
import FulfillmentRecords from '../../SharedComponents/FulfillmentPopup/FulfillmentRecords';
import BulkFulillment from '../../SharedComponents/FulfillmentPopup/BulkFulfillment';
import { showAddCustomField } from '../../SharedComponents/CustomField/AddCustomField';
import CustomFieldService from '../../Services/CustomField';
import { ColumnDeleteType, IColumn, IColumnType } from '../../Models/Table';
import {
  updateColumnInfo,
  updateColumnShift
} from '../../Helper/TableColumnUpdateHelper';
import { Attachments } from '../../SharedComponents/Attachments';
import QuotationService from '../../Services/Quotation';
import { getUpdatedQuoteObject } from '../Quotations/QuoteHelper';
import DropshipWarning from '../../SharedComponents/FulfillmentPopup/DropshipWarningPopup';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../Settings/GranularPermissions/GranularPermissionsHelper';
import {
  FEATURE_PERMISSIONS,
  PERMISSIONS_BY_MODULE
} from '../../Constants/Permission';
import SalesReturnService from '../../Services/SalesReturn';
import {
  EXPORTED_FILES_TYPE_EXTENSION,
  RETURN_MODE
} from '../../Constants/Enum';
import SalesReturnRecords from '../../SharedComponents/SalesReturn/SalesReturnRecords';
import SalesReturnPopup from '../../SharedComponents/SalesReturn/SalesReturn';
import CreateAndEditNotes from '../Accounting/CreateAndEditNotes/CreateAndEditNotes';
import ExpenseDepositForm from '../Bank/ExpenseDeposit/ExpenseDepositForm';
import EximService from '../../Services/Exim';
import { localizedText } from '../../Services/Localization/Localization';
import ContactService, { ContactAPIConfig } from '../../Services/Contact';
import ic_whatsapp from '../../Assets/Icons/ic_whatsapp.png';
import WhatsAppHelper from '../../Helper/WhatsAppHelper';
import AddNewWorkOrder from '../Mrp/WorkOrder/AddWorkOrder';
import { fetchOperationsList } from '../../Redux/Slices/MRP/OperationSlice';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import AppManager from '../../Managers/AppManager';
import { ResponsiveDateRangePicker } from '../responsive/ResponsiveDateRangePicker';
import PurchaseOrderService from '../../Services/PurchaseOrder';
import { getUpdatedPurchaseOrderObject } from '../PurchaseOrders/PurchaseOrderHelper';
import DetailsOpener from '../Common/DetailsOpener';
import LinkedRecordsPopup from '../../SharedComponents/LinkedRecords/LinkedRecordsPopup';
import RateAnalysisForm from '../../SharedComponents/DocumentForm/RateAnalysisForm';
import GenerateLabelPopup from '../LabelTemplate/GenerateLabelPopup';
import { LABEL_DOCUMENT_TYPE } from '../LabelTemplate/LabelTemplateHelper';
import { CONTACT_LEFT_PANEL_WIDTH } from '../Contacts/Details';
import { COLUMN_VISIBILITY_BUTTON_CONFIG } from '../../SharedComponents/GridColumnVisibility';
import DraftService from '../../Services/Drafts';
import NumberFormatService from '../../Services/NumberFormat';
import AuthService from '../../Services/Auth';
import AutomationService from '../../Services/Automation';
import ApprovalHistory from '../../SharedComponents/ApprovalHistory';
import {
  WorkOrderHelper,
  validateSelectedSOForInterLinkedWO
} from '../Mrp/WorkOrder/WorkOrderHelper';
import { VatReturnDetail } from '../../Constants/RoutingGuardPath';
import RateAnalysisService from '../../Services/RateAnalysis';
import ConvertWorkOrder from '../../SharedComponents/ConvertWorkOrder';
import ViewAdvancedTrackingReservedData from '../Common/ViewAdvancedTrackingReservedData';
import { fetchContacts } from '../../Redux/Slices/ContactsSlice';
import { COUNTRIES_WITH_CURRENCIES } from '../PriceBook/CountriesWithCurrencies';
import { selectDimensions } from '../../Redux/Slices/LocationSlice';
import ProductService from '../../Services/Product';
import { Store } from '../../Redux/Store';
import DropshipConfirmation from '../../SharedComponents/FulfillmentPopup/DropshipConfirmationPopup';
import { DocumentConfigUtility } from '../../Utility/DocumentConfigUtility';
import { DocumentConfigManager } from '../../Managers/DocumentConfigManger';

const initialState: IState = {
  columnData: [],
  rowData: [],
  originalData: [],
  filter: []
};

export interface ISalesOrdersProps {
  listByContact?: boolean;
  contact?: any;
  location?: any;
}

const SalesOrders = (props: ISalesOrdersProps) => {
  const { t, i18n } = useTranslation();
  const { confirm } = useConfirm();
  //states
  const [salesOrders, setSalesOrders] = useState(salesOrderInitialState);
  const dimensionData = useAppSelector(selectDimensions);
  const [showRateAnalysisForm, setShowRateAnalysisForm] = useState(false);
  const [rateAnalysisData, setRateAnalysisData] = useState<any>();
  const [updating, setUpdating] = useState<boolean>(false);
  const [filterDates, setFilterDates] = useState<any>(undefined);
  const [isOpeningForm, setIsOpeningForm] = useState(false);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState<any>('');
  const [selectedWO, setSelectedWO] = useState(null);
  const [filters, setFilters] = useState<any>([]);
  const [fulfillmentType, setFulfillmentType] = useState<any>(
    'Fulfillment Records'
  );
  const [searchTerm, setSearchTerm] = useState<any>(undefined);
  const [showTotalInBaseCurrency, setShowTotalInBaseCurrency] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [showConversionPopup, setShowConversionPopup] =
    useState<boolean>(false);
  const [editableSalesOrder, setEditableSalesOrder] = useState<any>();
  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [
    showFulfillmentConfirmationPopup,
    setShowFulfillmentConfirmationPopup
  ] = useState<boolean>(false);
  const [showFulfillmentRecordsPopup, setShowFulfillmentRecordsPopup] =
    useState<boolean>(false);
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const [showBulkFulfillmentPopup, setShowBulkFulfillmentPopup] =
    useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [currentSelectedRows, setCurrentSelectedRows] = useState<any[]>([]);
  const [bulkFulfillmentRows, setBulkFulfillmentRows] = useState<any[]>([]);
  const [bulkFulfillmentDetails, setBulkFulfillmentDetails] = useState<any[]>(
    []
  );
  const [isApprovalInprogress, setIsApprovalInprogress] = useState(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState<any[]>([]);
  const [convertWOData, setConvertWOData] = useState<any[]>([]);
  const [showApprovalHistoryPopup, setShowApprovalHistoryPopup] =
    useState(false);
  const [bulkPrintRecordData, setBulkPrintRecordData] = useState<any[]>([]);
  const [showAttachmentPopup, setShowAttachmentPopup] = useState(false);
  const [showDropShipPopup, setShowDropShipPopup] = useState<boolean>(false);
  const [showDropShipWarningPopup, setShowDropShipWarningPopup] =
    useState<boolean>(false);
  const [showAddWorkOrderPopup, setShowAddWorkOrderPopup] = useState(false);
  const [showConvertWorkOrderPopup, setShowConvertWorkOrderPopup] =
    useState(false);
  const [selectedWorkOrderRow, setSelectedWorkOrderRow] = useState<any>();
  const [isFulfillmentForDropship, setisFulfillmentForDropship] =
    useState<boolean>(false);
  const [showDropshipConfirmationPopup, setShowDropshipConfirmationPopup] =
    useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const tenantInfo = useAppSelector(activeTenantInfo);
  const salesOrderFilterData: any = useAppSelector(selectSalesOrderFilter);
  const columnConfig = useAppSelector(selectSalesOrderColumnConfig);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const salesOrdersData = useAppSelector(selectSalesOrder);
  const salesOrderDraftData = useAppSelector(
    selectSalesOrderDraftsData
  ) as any[];
  const availableCustomFields = useAppSelector(selectCustomFields);
  const activeMultiCurrencyList = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const draftsTableId = useAppSelector(draftTableId);
  const activeDrafts: any[] = useAppSelector(selectDrafts);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const columnConfigTableId = useAppSelector(
    selectSalesOrderColumnConfigTableId
  );
  const dispatch = useAppDispatch();
  const { id: docCodeInURL, contactId: contactIdInURL }: any = useParams();

  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };

  const [showSalesReturnPopup, setShowSalesReturnPopup] =
    useState<boolean>(false);
  const [showSalesReturnRecordsPopup, setShowSalesReturnRecordsPopup] =
    useState<boolean>(false);
  const [salesReturnsDetails, setSalesReturnsDetails] = useState<any>();
  const [showExpenseButton, setshowExpenseButton] = useState<any>();
  const [expenseData, setExpenseData] = useState<any>();
  const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
  const [generateLabelPopup, setGenerateLabelPopup] = useState<boolean>(false);
  const [showLinkRecordsPopup, setShowLinkRecordsPopup] =
    useState<boolean>(false);
  const [salesOrderLinkRecordsData, setSalesOrderLinkRecordsData] =
    useState<any>({});
  const [wasDraftFilterApply, setWasDraftFilterApply] =
    useState<boolean>(false);
  const payloadColumn = useAppSelector(payloadColumnKey);
  const subscriptionDetails = useAppSelector(subscriptionInfo);
  const featurePermissionsInfo = useAppSelector(featurePermissions);

  const emailTriggerIds = useAppSelector(SelectEmailTriggerIdsData);

  const expenseRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [showCNAndDNInfo, setShowCNAndDNInfo] = useState({
    showPopup: false,
    data: null,
    documentType: DOC_TYPE.CREDIT_NOTE
  });
  // Approval/Reject PopUp
  const [showApprovalOrRejectPopup, setShowApprovalOrRejectPopup] =
    useState<boolean>(false);

  const [approvalOrRejectData, setApprovalOrRejectData] = useState<any>({
    title: '',
    actionDetails: null
  });
  const createCreditRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const history = useHistory();

  const IMPORT_OPTIONS = Utility.isSupportedCountriesForImport(
    tenantInfo.country
  )
    ? ['Sales Orders', 'Fulfillment']
    : ['Sales Orders'];

  const [showImportOptions, setShowImportOptions] = useState<boolean>(false);

  const [detailsPopupData, setDetailsPopupData] = useState<any>();

  const [consolidateRows, setConsolidateRows] = useState<any[]>([]);

  const [viewReservedQuantityPopup, setViewReservedQuantityPopup] =
    useState(false);
  const [selectedRowForReservedData, setSelectedRowForReservedData] =
    useState(null);

  const canApplySalesPersonFilterOnSO = useAppSelector(
    selectApplySalesPersonFilter
  );

  const NON_FILTER_COLUMNS = [
    COLUMN_CODE.SALES_ORDER.Number,
    COLUMN_CODE.SALES_ORDER.SaleOrderDate,
    COLUMN_CODE.SALES_ORDER.TotalAmount,
    COLUMN_CODE.SALES_ORDER.COGS,
    COLUMN_CODE.SALES_ORDER.Profit,
    COLUMN_CODE.SALES_ORDER.GrossMargin,
    COLUMN_CODE.SALES_ORDER.Attachments,
    COLUMN_CODE.SALES_ORDER.AdvancedTrackingStatus,
    COLUMN_CODE.SALES_ORDER.StockReserved,
    COLUMN_CODE.SALES_ORDER.OrderProgress,
    COLUMN_CODE.SALES_ORDER.AmountPaid,
    COLUMN_CODE.SALES_ORDER.BalanceDue
  ];

  let approvalConditions = Store.getState().automation?.data;
  const findApprovalLevels = (inputData: any[]) => {
    const result: Record<string, string> = {};

    const levelHandled = new Set();
    if (Array.isArray(inputData)) {
      inputData.forEach((item) => {
        item.fields.forEach((field: any) => {
          if (field.field_type === 'SALES_ORDER') {
            field.multiApprovalDetails.forEach((approvalDetail: any) => {
              if (!levelHandled.has(approvalDetail.level)) {
                const key = `${field.field_type}_PENDING_${approvalDetail.level}_${approvalDetail.levelLabel}`;
                const rejectedKey = `${field.field_type}_REJECTED_${approvalDetail.level}_${approvalDetail.levelLabel}`;
                result[key] = `Pending at ${approvalDetail.levelLabel}`;
                result[
                  rejectedKey
                ] = `Rejected at ${approvalDetail.levelLabel}`;
                levelHandled.add(approvalDetail.level);
              }
            });
          }
        });
      });
    }

    const sortedResult: Record<string, string> = {};
    Object.keys(result)
      .sort((a, b) => {
        if (a.includes('REJECTED') && b.includes('PENDING')) {
          return -1;
        }
        if (a.includes('PENDING') && b.includes('REJECTED')) {
          return 1;
        }
        return 0;
      })
      .forEach((key) => {
        sortedResult[key] = result[key];
      });

    return sortedResult;
  };

  const approvalLevels = useMemo(() => {
    return findApprovalLevels(approvalConditions ?? []);
  }, [approvalConditions]);

  const convertPopupBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const fulfillmentConfirmationPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const fulfillmentRecordsBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.FULLFILLMENT_RECORDS.BUTTON.CLOSE`),
      class: 'bg-app text-white mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const dropShipButtonConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.NO`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.DROPSHIP.BUTTON.YES`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CONFIRM_DROPSHIP
    }
  ];

  const bulkFulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.BULK_FULFILMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(`INVOICES.DIALOG.BULK_FULFILMENT_OF_INVOICES.BUTTON.FULLFILL`),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.BULK_FULFILLMENT
    }
  ];
  const salesReturnPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Return',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SALES_RETURN
    }
  ];
  const directExpenseBtnConfig: BtnType[] = [
    {
      title: 'Cancel',
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT
    }
  ];
  const CNBtnConfig: BtnType[] = [
    {
      title: 'Close',
      class: 'bg-gray2 border-m mr-r',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Save',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.CREATE_CREDIT
    }
  ];

  useEffect(() => {
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  const onAttachmentCLicked = (data: any) => {
    const salesOrdersList: any[] = salesOrdersData['content'];
    const salesOrderId: number = data.rowData.id;
    const salesOrderData = salesOrdersList.filter(
      (salesOrder) => salesOrder.id === salesOrderId
    )[0];
    if (salesOrderData) {
      loadSalesOrderDetailsForEdit(
        salesOrderData,
        POPUP_CLICK_TYPE.OPEN_ATTACHMENT
      );
    }
  };

  const addSalesOrderRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowConversionPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowFulfillmentPopup(false);
        setShowSalesReturnPopup(false);
        setShowSalesReturnRecordsPopup(false);
        setshowExpenseButton(false);
        setShowApprovalHistoryPopup(false);
        setShowApprovalOrRejectPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowCNAndDNInfo({ ...showCNAndDNInfo, showPopup: false });
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addSalesOrderRef.current?.storeCallbacksRef.fulfillment();
        break;
      case POPUP_CLICK_TYPE.BULK_FULFILLMENT:
        addSalesOrderRef.current?.storeCallbacksRef.bulkFulfillment();
        break;
      case POPUP_CLICK_TYPE.CONFIRM_DROPSHIP:
        let poDetails: any = Utility.createDropShip(
          { ...editableSalesOrder },
          DOC_TYPE.SALES_ORDER
        );
        dispatch(
          createBlankDraft({
            payloadData: {
              title: LABELS.PURCHASE_ORDERS,
              type: LABELS.PURCHASE_ORDERS,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig,
              populateFormData: poDetails,
              isCenterAlign: true,
              isMaximized: true
            },
            draftType: DraftTypes.NEW
          })
        );
        setShowDropShipWarningPopup(false);
        break;
      case POPUP_CLICK_TYPE.SALES_RETURN:
        addSalesOrderRef.current?.storeCallbacksRef.updateInvoice();
        break;
      case POPUP_CLICK_TYPE.CREATE_EXPENSE_DEPOSIT:
        expenseRef.current?.storeCallbacksRef.createExpenseDeposit();
        break;
      case POPUP_CLICK_TYPE.CREATE_CREDIT:
        createCreditRef.current?.storeCallbacksRef.createCredit();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowFulfillmentRecordsPopup(false);
        setShowBulkFulfillmentPopup(false);
        setShowDropShipPopup(false);
        setShowDropShipWarningPopup(false);
        setShowSalesReturnPopup(false);
        setShowApprovalHistoryPopup(false);
        setshowExpenseButton(false);
        setShowSalesReturnRecordsPopup(false);
        setShowDropshipConfirmationPopup(false);
        setShowCNAndDNInfo({ ...showCNAndDNInfo, showPopup: false });
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addSalesOrderRef.current.storeCallbacksRef.fulfillment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.BULK_FULFILLMENT:
        addSalesOrderRef.current.storeCallbacksRef.bulkFulfillment =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(false);
        break;
      case POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT_FOR_DROPSHIP:
        setShowFulfillmentPopup(true);
        setShowFulfillmentConfirmationPopup(false);
        setisFulfillmentForDropship(true);
        break;

      case POPUP_CALLBACKS_TYPE.DROPSHIP_FULFILLMENT:
        setShowFulfillmentPopup(false);
        setShowFulfillmentConfirmationPopup(false);
        setShowDropShipPopup(false);
        setShowDropShipWarningPopup(true);
        break;
      case POPUP_CALLBACKS_TYPE.PICK_PACK_SHIP:
        setShowFulfillmentConfirmationPopup(false);
        // RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP);
        break;
      case POPUP_CALLBACKS_TYPE.SALES_RETURN:
        addSalesOrderRef.current.storeCallbacksRef.updateInvoice =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_EXPENSE_DEPOSITE:
        expenseRef.current.storeCallbacksRef.createExpenseDeposit =
          passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CREDIT:
        createCreditRef.current.storeCallbacksRef.createCredit =
          passingData.data;
        break;
    }
  };

  useEffect(() => {
    if (!props.listByContact) {
      RouteManager.setPresenter({ props });
    }

    if (!Utility.isEmpty(tenantInfo)) {
      setInitialConfigFromStorage();
    }
    getAllOperations();
    if (!props.listByContact) {
      dispatch(
        fetchDrafts({
          tableId: draftsTableId,
          isSaveColumnId: isSavedColumnId,
          draftTypeColId: draftTypeColId,
          draftTypeColValue: LABELS.SALES_ORDER
        })
      );
    }

    return () => setSalesOrders(initialState);
  }, []);

  // Open doc from document code in the URL
  useEffect(() => {
    if (!props.listByContact) {
      if (!Utility.isEmpty(tenantInfo) && !Utility.isEmpty(docCodeInURL)) {
        const openedAndMaximizedDrafts = activeDrafts?.filter(
          (draft: any) => draft.isMaximized && draft.isCenterAlign
        );
        if (!Utility.isEmpty(docCodeInURL)) {
          directOpenDocument(docCodeInURL);
        } else {
          openedAndMaximizedDrafts.forEach((draft: any) => {
            dispatch(removeDraft(draft.id));
          });
        }
      }
    }
  }, [docCodeInURL, tenantInfo]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo) && Utility.isEmpty(filterDates)) {
      setInitialConfigFromStorage();
    }
  }, [tenantInfo]);

  const setInitialConfigFromStorage = () => {
    const savedSettings = SessionStorageService.fetchConfigSettings(
      MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
      DOC_TYPE.SALES_ORDER
    );
    if (!Utility.isEmpty(savedSettings)) {
      setShowTotalInBaseCurrency(savedSettings[SHOW_TOTAL_IN_BASE_CURRENCY]);
    }
    setFilterDates({
      startDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(salesOrderFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              salesOrderFilterData?.filterDates.startDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialStartDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      ),
      endDate: DateFormatService.getDateStrFromDate(
        Utility.isObject(salesOrderFilterData?.filterDates)
          ? DateFormatService.getDateFromStr(
              salesOrderFilterData?.filterDates.endDate,
              BOOKS_DATE_FORMAT['YYYY-MM-DD']
            )
          : Utility.getFinancialEndDate(),
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      )
    });
  };

  const setFilterDataInStore = (
    config: any,
    SearchTerm?: any,
    queries?: any,
    filterDates?: any
  ) => {
    dispatch(
      salesOrderFilter({
        config,
        SearchTerm,
        queries,
        filterDates
      })
    );
  };

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      let config = {
        ...defaultConfig,
        Query: `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}`,
        QueryParam: `&sort=salesOrderDate&sortDir=DESC`,
        QuerySummary: `fromDate=${DateFormatService.getFormattedDateString(
          filterDates.startDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )}&toDate=${DateFormatService.getFormattedDateString(
          filterDates.endDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        )}`,
        Page: salesOrderFilterData?.config?.Page
          ? salesOrderFilterData?.config?.Page
          : 0
      };

      if (
        canApplySalesPersonFilterOnSO.canApplyFilter &&
        canApplySalesPersonFilterOnSO?.loggedInUserInfo?.[0]?.id
      ) {
        config.SalesPerson =
          canApplySalesPersonFilterOnSO?.loggedInUserInfo?.[0]?.id;
      }

      SalesOrderService.apiConfig = config;

      // Check selected filter already exist
      if (Utility.isObject(salesOrderFilterData?.config)) {
        let filteredQueryList = Utility.getFilterParameters(
          salesOrderFilterData?.queries,
          REMOTE_CONFIG_TABLES.SALES_ORDER
        );

        const list = Utility.getFilteredQueryList(filteredQueryList);
        let withoutFilter, withFilter;
        if (
          list.newFilteredQueyListArr &&
          list.newFilteredQueyListArr.length > 0
        ) {
          withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
        }
        if (list.customField && list.customField.length > 0) {
          assignProductModule('CLASS', 'CLASS', list?.customField);
          assignProductModule('CLASS', 'LOCATION', list?.customField);
          withFilter = Utility.getQueryString(list.customField);
        }

        config = {
          ...config,
          SearchTerm: salesOrderFilterData?.SearchTerm,
          Query:
            `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}` +
            (withoutFilter ? `,${withoutFilter}` : ''),
          QueryParam: `&sort=salesOrderDate&sortDir=DESC&${withFilter}`
        };
        SalesOrderService.apiConfig = config;

        setFilterDataInStore(
          { ...config },
          salesOrderFilterData?.SearchTerm,
          deepClone(salesOrderFilterData?.queries),
          {
            startDate: filterDates.startDate,
            endDate: filterDates.endDate
          }
        );
      } else {
        setFilterDataInStore(
          { ...config },
          salesOrderFilterData?.SearchTerm,
          deepClone(salesOrderFilterData?.queries),
          { startDate: filterDates.startDate, endDate: filterDates.endDate }
        );
      }
    }
  }, [filterDates]);

  useEffect(() => {
    if (!Utility.isEmpty(filterDates)) {
      getInitialFilterData();
    }
  }, [salesOrderFilterData]);

  useEffect(() => {
    if (
      typeof debouncedSearchTerm !== 'undefined' &&
      debouncedSearchTerm !== null
    ) {
      const config: SalesOrderAPIConfig = {
        ...SalesOrderService.apiConfig,
        Page: 0,
        SearchTerm: debouncedSearchTerm
      };
      setFilterDataInStore(
        { ...config },
        debouncedSearchTerm,
        deepClone(salesOrderFilterData?.queries)
      );
    }
  }, [debouncedSearchTerm]);

  /**
   * Load salesOrder Filter Data
   */

  const getInitialFilterData = () => {
    if (Utility.isObject(salesOrderFilterData?.config)) {
      if (!Utility.isEmpty(salesOrderFilterData.SearchTerm)) {
        // Set search value in datagrid
        setSearchTerm(salesOrderFilterData.SearchTerm);
      }

      if (salesOrderFilterData.queries.length) {
        setFilters(
          salesOrderFilterData?.queries.length
            ? deepClone(salesOrderFilterData?.queries)
            : []
        );
      }
      loadSalesOrders(salesOrderFilterData.config);
    } else {
      loadSalesOrders({});
    }
  };

  const loadSalesOrders = async (config: SalesOrderAPIConfig) => {
    try {
      setUpdating(true);
      if (Utility.isEmpty(config)) {
        config = defaultConfig;
      }

      if (props.listByContact && !config?.Query?.includes('contactCode')) {
        config = deepClone(config);
        config.Query += `${config.Query ? ',' : ''}contactCode=${
          props?.contact?.code
        }`;
      }

      SalesOrderService.apiConfig = config;
      await dispatch(fetchSalesOrders());
      setUpdating(false);
    } catch (err) {
      console.error('Error loading sales orders: ', err);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(salesOrdersData)) {
      const payload: any = Utility.getKeyOfColumn(
        draftsColumnConfig,
        COLUMN_CODE.DRAFTS.Payload
      );

      const payloadKey = payload && payload.length ? payload[0].id : null;

      let nonFilterColumns = NON_FILTER_COLUMNS;

      let draftSalesOrders;
      if (payloadKey) {
        const userMap = Utility.getUserIdMap();
        draftSalesOrders = salesOrderDraftData?.map((data: any) => {
          if (data.cells[payloadKey]) {
            return {
              ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
              id: data._id,
              isDraftDocument: true,
              approvalStatus: data.cells['approvalStatus'],
              draftCode: data.cells.documentSequenceCode,
              createdBy: userMap.get(data.createdBy)
            };
          }
        });
        if (draftSalesOrders) {
          draftSalesOrders = draftSalesOrders?.filter((ele) => {
            if (!Utility.isEmpty(searchTerm)) {
              let safePattern = Utility.escapeRegex(searchTerm);
              return (
                ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                ele.draftCode?.search(new RegExp(safePattern, 'i')) >= 0
              );
            } else {
              return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
            }
          });
        }
      }

      const { content, pageable, totalPages } = salesOrdersData;

      let combinedSalesOrders = [...content];
      const nonDraftFilter = Utility.isEmpty(filters)
        ? filters
        : filters?.filter(
            (row: any) => !['product', 'contact'].includes(row?.key)
          );
      if (
        draftSalesOrders &&
        draftSalesOrders.length > 0 &&
        Utility.isEmpty(nonDraftFilter) &&
        !props.listByContact
      ) {
        combinedSalesOrders = [...draftSalesOrders, ...combinedSalesOrders];
      }

      if (
        !Utility.isEmpty(combinedSalesOrders) ||
        (filters && filters.length > 0)
      ) {
        let tempColumnConfig = deepClone(columnConfig) as any[];
        tempColumnConfig =
          Utility.updateLocationAndClassTitleForGrid(tempColumnConfig);

        let configToEditIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'contact'
        );
        if (tenantInfo.country === COUNTRY_CODES.IN) {
          tempColumnConfig = localiseColumnConfig(tempColumnConfig);
        }
        //Check existing column for Paid, due amount, Progress
        let newColAmountNProgress = tempColumnConfig.findIndex(
          (column) => column.columnCode === 'orderProgress'
        );
        if (newColAmountNProgress === -1) {
          // New Column Paid, Process, and Balance Due
          let newColFields = SO_COL;

          let productionColIndex = newColFields.findIndex(
            (column) => column.columnCode === 'productionStatus'
          );

          if (Utility.isMRP() && productionColIndex === -1) {
            newColFields.splice(1, 0, PRODUCTION_COL_SO);
          }

          tempColumnConfig = [...tempColumnConfig, ...newColFields];
        }
        // Convert user type to select type to support filter
        tempColumnConfig = Utility.convertUserTypeColumnToSelectType([
          ...tempColumnConfig
        ]);

        if (configToEditIndex !== -1) {
          tempColumnConfig[configToEditIndex] = {
            ...tempColumnConfig[configToEditIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              return (
                <div className="row justify-content-between">
                  <div
                    onClick={() => {
                      contactDetailOpener(
                        obj?.rowData?.contactDto?.code ||
                          obj?.rowData?.contactCode
                      );
                    }}
                  >
                    <DKLabel
                      text={obj.value}
                      className="underline cursor-hand fs-m mr-r"
                    />
                  </div>
                  <div className="row width-auto">
                    {getEmailStatusForGrid(obj.rowData, DOC_TYPE.SALES_ORDER)}
                    <DKLabel text={Utility.getIconForGrid(obj.rowData)} />
                    {(obj.rowData.linkedSalesInvoices?.length > 0 ||
                      obj.rowData.linkedSalesInvoiceDocuments?.length > 0 ||
                      obj.rowData.linkedQuotationDocuments?.length > 0) && (
                      <DKTooltipWrapper
                        contentRenderer={() => getTootltipContent(obj.rowData)}
                        tooltipClassName="bg-deskera-secondary width-auto"
                      >
                        <DKIcon
                          src={DKIcons.data_type.ic_url}
                          className="ic-xs opacity-60 cursor-pointer ml-s"
                        />
                      </DKTooltipWrapper>
                    )}
                  </div>
                </div>
              );
            }
          };

          if (!!props.listByContact) {
            tempColumnConfig.splice(configToEditIndex, 1);
          }
        }

        const docNumberIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.Number
        );
        if (docNumberIndex !== -1) {
          tempColumnConfig[docNumberIndex] = {
            ...tempColumnConfig[docNumberIndex],
            renderer: (obj: any) => {
              return !obj?.rowData?.isDraftDocument &&
                isDocumentLocked({
                  ...obj.rowData,
                  documentType: DOC_TYPE.SALES_ORDER
                }) ? (
                <DKTooltipWrapper
                  content={'Approved document is locked'}
                  tooltipClassName="bg-deskera-secondary w-80"
                >
                  <div className="row">
                    {' '}
                    <div className="column cursor-pointer">
                      <img style={{ width: '15px' }} src={DKIcons.ic_lock} />
                    </div>{' '}
                    <span
                      className="column  fs-m border-radius-s ml-xs"
                      style={{}}
                    >
                      {obj.value}
                    </span>{' '}
                  </div>
                </DKTooltipWrapper>
              ) : (
                <>
                  {
                    <div
                      className="fs-m"
                      dangerouslySetInnerHTML={{ __html: obj.value }}
                    />
                  }
                </>
              );
            }
          };
        }

        let totalAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.TotalAmount
        );

        if (totalAmountColumnIndex !== -1) {
          let name = tempColumnConfig[totalAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[totalAmountColumnIndex] = {
            ...tempColumnConfig[totalAmountColumnIndex],
            allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }

        let paidAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.AmountPaid
        );

        if (paidAmountColumnIndex !== -1) {
          let name = tempColumnConfig[paidAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[paidAmountColumnIndex] = {
            ...tempColumnConfig[paidAmountColumnIndex],
            allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }

        let balanceAmountColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.BalanceDue
        );

        if (balanceAmountColumnIndex !== -1) {
          let name = tempColumnConfig[balanceAmountColumnIndex].name as string;
          name = name.split('(')[0];
          tempColumnConfig[balanceAmountColumnIndex] = {
            ...tempColumnConfig[balanceAmountColumnIndex],
            allowFilter: false,
            name: showTotalInBaseCurrency
              ? `${name}(${tenantInfo.currency})`
              : name
          };
        }

        let billToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.BillTo
        );

        let shipToConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.ShipTo
        );

        if (billToConfigIndex !== -1) {
          tempColumnConfig[billToConfigIndex] = {
            ...tempColumnConfig[billToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.billTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.BillTo);
              } else {
                return '';
              }
            }
          };
        }

        if (shipToConfigIndex !== -1) {
          tempColumnConfig[shipToConfigIndex] = {
            ...tempColumnConfig[shipToConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              if (!Utility.isEmpty(obj?.rowData?.shipTo)) {
                return addressRenderer(obj, COLUMN_CODE.SALES_ORDER.ShipTo);
              } else {
                return '';
              }
            }
          };
        }

        let dueDateConfigIndex = tempColumnConfig.findIndex(
          (config) =>
            config.columnCode === COLUMN_CODE.SALES_ORDER.SalesOrderDueDate
        );
        if (dueDateConfigIndex !== -1) {
          tempColumnConfig[dueDateConfigIndex] = {
            ...tempColumnConfig[dueDateConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              const infoObj = Utility.getDueDateInfoForGrid(
                obj.value,
                obj.rowData.isDraftDocument
              );
              const status = obj.rowData?.status
                ? obj.rowData?.status[0]
                : null;
              return (
                <div className="column">
                  {status && status === DOCUMENT_STATUS.PROCESSED && (
                    <DKLabel
                      text={DateFormatService.getDateStrFromDate(obj.value)}
                      className={`${infoObj.class}`}
                    />
                  )}
                  {status && status !== DOCUMENT_STATUS.PROCESSED && (
                    <DKTooltipWrapper
                      content={infoObj.text}
                      tooltipClassName="bg-deskera-secondary width-auto"
                    >
                      <DKLabel
                        text={DateFormatService.getDateStrFromDate(obj.value)}
                        className={`${infoObj.class}`}
                      />
                    </DKTooltipWrapper>
                  )}
                </div>
              );
            }
          };
        }

        if (!isSGAndPeppolOptInEnabled()) {
          let paymentTerm = tempColumnConfig.findIndex(
            (config) => config.columnCode === COLUMN_CODE.BILL.PaymentTerm
          );
          if (paymentTerm !== -1) {
            tempColumnConfig.splice(paymentTerm, 1);
          }
          let orderReferenceCol = tempColumnConfig.findIndex(
            (config) =>
              config.columnCode === COLUMN_CODE.SALES_ORDER.OrderReference
          );
          if (orderReferenceCol !== -1) {
            tempColumnConfig.splice(orderReferenceCol, 1);
          }
        }
        let attachmentColumnIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'attachments'
        );

        if (attachmentColumnIndex !== -1) {
          tempColumnConfig[attachmentColumnIndex] = {
            ...tempColumnConfig[attachmentColumnIndex],
            renderer: (obj: any) => {
              if (obj?.rowData?.attachmentsWithLink?.length) {
                return (
                  <div className="row">
                    <div onClick={() => onAttachmentCLicked(obj)}>
                      <DKIcon
                        src={DKIcons.ic_attachment}
                        className="ic-s cursor-pointer opacity-60 hover:opacity-50"
                      />
                    </div>
                    <DKButton
                      title="View"
                      className="text-blue underline p-v-0"
                      onClick={() => onAttachmentCLicked(obj)}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            }
          };
        }

        let remarksConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.QUOTE.Remarks
        );

        if (remarksConfigIndex !== -1) {
          tempColumnConfig[remarksConfigIndex] = {
            ...tempColumnConfig[remarksConfigIndex],
            allowFilter: true,
            renderer: (obj: any) => {
              let remarksData = '';
              if (
                obj.rowData.multiApprovalDetails?.approvalHistory?.length > 0
              ) {
                const allRemarks =
                  obj.rowData.multiApprovalDetails.approvalHistory
                    .filter((history: any) => history?.remarks)
                    .map(
                      (history: any) =>
                        `Level ${history.level}: ${history.remarks}`
                    );

                remarksData = allRemarks.join(', ');
              }
              return (
                <div className="column">
                  <DKTooltipWrapper
                    content={remarksData}
                    tooltipClassName="bg-deskera-secondary width-auto"
                  >
                    <DKLabel text={obj.value} />
                  </DKTooltipWrapper>
                </div>
              );
            }
          };
        }

        let productionConfigIndex = tempColumnConfig.findIndex(
          (config) =>
            config.columnCode === COLUMN_CODE.SALES_ORDER.ProductionStatus
        );
        if (productionConfigIndex !== -1) {
          tempColumnConfig[productionConfigIndex] = {
            ...tempColumnConfig[productionConfigIndex],
            allowFilter: true,
            options: [
              {
                id: 'Pending',
                name: 'Pending',
                color: 'data-grid-badge-color-4'
              },
              {
                id: 'Scheduled',
                name: 'Scheduled',
                color: 'data-grid-badge-color-8'
              },
              {
                id: 'Delayed',
                name: 'Delayed',
                color: 'data-grid-badge-color-10'
              },
              {
                id: 'Completed',
                name: 'Completed',
                color: 'data-grid-badge-color-6'
              }
            ],

            renderer: (obj: any) => {
              let filterStatus = PRODUCTION_STATUS_OPTIONS.filter(
                (item) => item.name === obj.value
              );
              return (
                <div className="column parent-width">
                  <DKLabel
                    text={filterStatus?.[0]?.name}
                    className={`parent-width text-align-center border-radius-s ${filterStatus?.[0]?.color}`}
                    style={{ padding: '2px 6px' }}
                  />
                </div>
              );
            }
          };
        }

        let progressConfigIndex = tempColumnConfig.findIndex(
          (config) =>
            config.columnCode === COLUMN_CODE.SALES_ORDER.OrderProgress
        );
        if (progressConfigIndex !== -1) {
          tempColumnConfig[progressConfigIndex] = {
            ...tempColumnConfig[progressConfigIndex],
            renderer: (obj: any) => {
              return (
                <div className="row" style={{ gap: 2 }}>
                  {obj.value &&
                    obj?.value?.map((value: any) => getProgressStatus(value))}
                </div>
              );
            }
          };
        }

        const mrpProduct = subscriptionDetails?.ProductsEnabled?.find(
          (product: any) => {
            return product === MRP_APP_NAME;
          }
        );

        let linkedWorkorder = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'linkedWorkOrder'
        );
        if (
          window.location.origin?.includes(
            ApiConstants.URL.APP_MRP_URL || ''
          ) &&
          !Utility.isEmpty(mrpProduct)
        ) {
          if (linkedWorkorder !== -1) {
            tempColumnConfig[linkedWorkorder] = {
              ...tempColumnConfig[linkedWorkorder],
              allowFilter: true,
              renderer: (obj: any) => {
                return (
                  <div className="" style={{ gap: 1 }}>
                    {obj.rowData?.linkedWorkOrderDocuments &&
                      obj?.rowData?.linkedWorkOrderDocuments
                        ?.filter(
                          (item: any, index: any, self: any) =>
                            index ===
                            self.findIndex(
                              (t: any) =>
                                t.documentSequenceCode ===
                                item.documentSequenceCode
                            )
                        )
                        ?.map((value: any) => getLinkedWorkOrders(value))}
                  </div>
                );
              }
            };
          }
        }

        let createdByConfigIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === COLUMN_CODE.SALES_ORDER.CreatedBy
        );

        if (createdByConfigIndex !== -1) {
          tempColumnConfig[createdByConfigIndex] = {
            ...tempColumnConfig[createdByConfigIndex],
            options: Utility.getUserOptions(),
            allowFilter: true
          };
        }

        if (!Utility.isApprovalColumn(DOC_TYPE.SALES_ORDER)) {
          tempColumnConfig = tempColumnConfig.filter(
            (config) =>
              config.columnCode !== COLUMN_CODE.SALES_ORDER.ApprovalStatus
          );
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.ApprovalStatus);
        }

        let approvalStatusConfigIndex = tempColumnConfig.findIndex(
          (config) =>
            config.columnCode === COLUMN_CODE.SALES_ORDER.ApprovalStatus
        );

        if (approvalStatusConfigIndex !== -1) {
          const options = Object.keys(approvalLevels).map((key) => {
            return {
              id: key,
              name: approvalLevels[key],
              color: 'data-grid-badge-color-9'
            };
          });

          tempColumnConfig[approvalStatusConfigIndex] = {
            ...tempColumnConfig[approvalStatusConfigIndex],
            type: IColumnType.select,
            options: [
              {
                id: 'APPROVED',
                name: 'Approved',
                color: ''
              },
              ...options
            ]
          };
        }

        tempColumnConfig = getUpdatedColumnConfig(tempColumnConfig);

        if (!Utility.isMRP()) {
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.LinkedWorkOrder);
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.ProductionStatus);
        }

        const filteredColumns = tempColumnConfig
          .filter((config) => !nonFilterColumns.includes(config.columnCode))
          .map((conf: any) => {
            let columnConfig = { ...conf };
            if (
              INPUT_TYPE.DROPDOWN === conf.type ||
              INPUT_TYPE.SELECT === conf.type
            ) {
              columnConfig.filterConditions = [
                { title: 'Equal to', value: 'eq' }
              ];
            }
            return columnConfig;
          });

        const rowData = getRowData(combinedSalesOrders);
        let columns = getFilteredColumns(filteredColumns);
        const filterColumns = getColumnsForFilter(columns);
        const passingData = {
          columnData: getFilteredColumns(tempColumnConfig),
          filterColumns: filterColumns,
          rowData,
          filter: filters,
          originalData: rowData
        };
        setSalesOrders(passingData);
        setTotalPageCount(totalPages);
        setCurrentPage(+pageable?.pageNumber + 1);
        // Add pagination here
      } else {
        const rowData = getRowData(combinedSalesOrders);
        let tempColumnConfig = deepClone(columnConfig) as any[];
        tempColumnConfig =
          Utility.updateLocationAndClassTitleForGrid(tempColumnConfig);

        let configToEditIndex = tempColumnConfig.findIndex(
          (config) => config.columnCode === 'contact'
        );
        if (!!props.listByContact && configToEditIndex !== -1) {
          tempColumnConfig.splice(configToEditIndex, 1);
        }

        if (tenantInfo.country === COUNTRY_CODES.IN) {
          tempColumnConfig = localiseColumnConfig(tempColumnConfig);
        }
        // Convert user type to select type to support filter
        tempColumnConfig = Utility.convertUserTypeColumnToSelectType([
          ...tempColumnConfig
        ]);

        tempColumnConfig = getUpdatedColumnConfig(tempColumnConfig);

        if (!Utility.isApprovalColumn(DOC_TYPE.SALES_ORDER)) {
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.ApprovalStatus);
        }

        if (!Utility.isMRP()) {
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.LinkedWorkOrder);
          nonFilterColumns.push(COLUMN_CODE.SALES_ORDER.ProductionStatus);
        }

        const filteredColumns = tempColumnConfig
          .filter((config) => !nonFilterColumns.includes(config.columnCode))
          .map((conf: any) => {
            let columnConfig = { ...conf };
            if (
              INPUT_TYPE.DROPDOWN === conf.type ||
              INPUT_TYPE.SELECT === conf.type
            ) {
              columnConfig.filterConditions = [
                { title: 'Equal to', value: 'eq' }
              ];
            }
            return columnConfig;
          });
        let columns = getFilteredColumns(filteredColumns);
        const filterColumns = getColumnsForFilter(columns);
        const passingData = {
          columnData: getFilteredColumns(deepClone(tempColumnConfig)),
          filterColumns: filterColumns,
          rowData,
          filter: filters,
          originalData: rowData
        };
        setSalesOrders(passingData);
        setTotalPageCount(totalPages);
      }
    }
  }, [
    salesOrdersData,
    salesOrderDraftData,
    showTotalInBaseCurrency,
    availableCustomFields
  ]);

  const getColumnsForFilter = (columnConfig: any[]) => {
    let tempColumnConfigList: any[] =
      columnConfig.filter(
        (col) =>
          col?.allowFilter !== false &&
          !['contact', 'product'].includes(col.columnCode)
      ) || [];
    const productColumnForFilter = {
      id: 'product',
      key: 'product',
      name: 'Product',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      filterConditions: [{ title: 'Equal to', value: 'eq' }],
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (search: any) =>
            DocumentConfigManager.getProductURLForFilter(search),
          dataParser: (data: any) =>
            DocumentConfigUtility.productDataParser(data, DOC_TYPE.ORDER),
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };

    const contactColumnForFilter = {
      id: 'contact',
      key: 'contact',
      name: 'contact',
      type: INPUT_TYPE.DROPDOWN,
      formatter: DocumentConfigUtility.formatterDocumentForFilter,
      filterConditions: [{ title: 'Equal to', value: 'eq' }],
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'z-index-3 shadow-m width-auto',
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 10,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };
            ContactService.apiConfig = config;

            return ContactService.getContactsApiUrl();
          },
          dataParser: (response: any) => {
            return response?.content || [];
          },
          debounceTime: 300
        },
        data: [],
        renderer: DocumentConfigUtility.rendererForFilter,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    };
    tempColumnConfigList.push(productColumnForFilter);
    tempColumnConfigList.push(contactColumnForFilter);

    return tempColumnConfigList;
  };

  const getUpdatedColumnConfig = (tempColumnConfig: any) => {
    let advanceTrackingStatusColIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'advancedTrackingStatus'
    );

    if (advanceTrackingStatusColIndex !== -1) {
      tempColumnConfig[advanceTrackingStatusColIndex] = {
        ...tempColumnConfig[advanceTrackingStatusColIndex],
        allowFilter: false,
        allowColumnSort: false,
        renderer: (data: any) => {
          let advTrackLineItemStatus = getAdvanceTrackingStatusFromLineItems(
            data?.rowData?.salesOrderItems
          );
          let color = '';
          if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.BATCH_TRACKED
          ) {
            color = 'data-grid-badge-color-2';
          } else if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.SERIAL_TRACKED
          ) {
            color = 'data-grid-badge-color-4';
          } else if (
            advTrackLineItemStatus === LINEITEMS_ADV_TRACK_STATUS.BOTH
          ) {
            color = 'data-grid-badge-color-5';
          }
          return (
            <DKLabel
              text={Utility.convertInTitleCase(advTrackLineItemStatus ?? '-')}
              style={{
                height: 24,
                padding: 4
              }}
              className={`text-align-center border-radius-s ${color}`}
            />
          );
        }
      };
    }

    let stockReservedColIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === 'stockReserved'
    );

    if (stockReservedColIndex !== -1) {
      tempColumnConfig[stockReservedColIndex] = {
        ...tempColumnConfig[stockReservedColIndex],
        allowFilter: false,
        allowColumnSort: false,
        renderer: (data: any) => {
          let isStockReserved = isStockReservedInDocLineItems(
            data?.rowData?.salesOrderItems
          );
          return (
            <div className="row">
              <DKLabel
                text={isStockReserved ? 'Stock Reserved' : 'Not Reserved'}
                className={`text-align-center ${
                  isStockReserved ? 'text-green' : 'text-red'
                }`}
              />
              {isStockReserved && (
                <span
                  className={`underline text-green ml-s cursor-hand`}
                  onClick={() => {
                    setSelectedRowForReservedData(data?.rowData);
                    setViewReservedQuantityPopup(true);
                  }}
                >
                  (View)
                </span>
              )}
            </div>
          );
        }
      };
    }

    return tempColumnConfig;
  };

  const contactDetailOpener = (contactId: any) => {
    let documentCode = contactId;
    setDetailsPopupData({
      documentSeqCode: documentCode,
      documentType: DOC_TYPE.Contact,
      showDetailsOpener: true
    });
  };

  const changeGridTitle = (
    tempColumnConfig: any,
    targetKey: string,
    newTitle: string
  ) => {
    let salesOrderDateConfigIndex = tempColumnConfig.findIndex(
      (config: any) => config.columnCode === targetKey
    );
    if (salesOrderDateConfigIndex !== -1) {
      tempColumnConfig[salesOrderDateConfigIndex] = {
        ...tempColumnConfig[salesOrderDateConfigIndex],
        name: newTitle
      };
    }
    return tempColumnConfig;
  };

  const getProgressStatus = (progressStatus: any) => {
    return (
      <div className="column align-items-center">
        <DKLabel text={progressStatus.field} className={`text-align-center`} />
        <div
          className={getColorForStatus(progressStatus.status)}
          style={{ width: 43, height: 5, marginTop: 2 }}
        ></div>
      </div>
    );
  };

  const getLinkedWorkOrders = (workorder: any) => {
    return (
      <div className="column align-items-center">
        {/* <DKLabel text={workorder.documentSequenceCode} className={`text-align-center`} /> */}
        <DKButton
          title={workorder.documentSequenceCode}
          className="text-blue underline p-v-0"
          onClick={() =>
            setDetailsPopupData({
              documentSeqCode: workorder.documentSequenceCode,
              documentType: DOC_TYPE.WORK_ORDER,
              showDetailsOpener: true
            })
          }
        />
        {/* <div
          className={getColorForStatus(progressStatus.status)}
          style={{ width: 43, height: 5, marginTop: 2 }}
        ></div> */}
      </div>
    );
  };

  const getColorForStatus = (status: string) => {
    switch (status) {
      case 'OPEN':
        return 'data-grid-badge-color-2';
      case 'IN_PROGRESS':
        return 'bg-yellow';
      case 'COMPLETED':
        return 'bg-green';
    }
  };

  const getAllOperations = async () => {
    try {
      const data = await dispatch(fetchOperationsList());
    } catch (err) {}
  };

  const localiseColumnConfig = (tempColumnConfig: any) => {
    const fulfillmentColumnConfigIndex = tempColumnConfig.findIndex(
      (config: any) =>
        config.columnCode === COLUMN_CODE.SALES_ORDER.FulfillmentStatus
    );
    if (fulfillmentColumnConfigIndex !== -1) {
      tempColumnConfig[fulfillmentColumnConfigIndex] = {
        ...tempColumnConfig[fulfillmentColumnConfigIndex],
        name: 'Delivery Status',
        options: [
          {
            id: 'FULLY_FULFILLED',
            name: 'Delivered',
            color: 'data-grid-badge-color-6'
          },
          {
            id: 'PARTIAL_FULFILLED',
            name: 'Partially Delivered',
            color: 'data-grid-badge-color-5'
          },
          {
            id: 'UNFULFILLED',
            name: 'Not Delivered',
            color: 'data-grid-badge-color-10'
          },
          {
            id: 'PENDING_FOR_APPROVAL',
            name: 'Pending Approval',
            color: 'data-grid-badge-color-8'
          }
        ]
      };
      return tempColumnConfig;
    }
  };

  const getLinkedSalesInvoices = (rowData: any) => {
    const linkedSalesInvoices = [];

    if (rowData.linkedSalesInvoices?.length > 0) {
      linkedSalesInvoices.push(...rowData.linkedSalesInvoices);
    }
    if (rowData.linkedSalesInvoiceDocuments?.length > 0) {
      linkedSalesInvoices.push(...rowData.linkedSalesInvoiceDocuments);
    }

    return linkedSalesInvoices;
  };

  const getTootltipContent = (rowData: any) => {
    let total = 0;
    const linkedSalesInvoices = getLinkedSalesInvoices(rowData);
    const linkedOrders = rowData?.linkedQuotationDocuments?.filter(
      (ele: any) => ele.documentType === DOC_TYPE.ORDER
    );
    const linkedQuotes = rowData?.linkedQuotationDocuments?.filter(
      (ele: any) => ele.documentType === DOC_TYPE.QUOTE
    );

    return (
      <div className="column" style={{ width: 200 }}>
        {!Utility.isEmpty(linkedSalesInvoices) && (
          <>
            <div className="row parent-width font-semibold">
              Linked Invoices
            </div>
            <div className="row parent-width font-semibold justify-content-between mr-s">
              <div className="column parent-width">
                {linkedSalesInvoices?.map((item: any) => {
                  total += +(item.totalAmount || 0);
                  return (
                    <div className="row parent-width justify-content-between mt-s">
                      <div
                        className="column cursor-hand"
                        onClick={(e) => {
                          e.stopPropagation();
                          return;
                        }}
                      >
                        {item.documentSequenceCode}
                      </div>
                      {item.totalAmount && (
                        <div className="column">
                          {Utility.getShortAmoutBlockForLabel(
                            rowData.currencyCode,
                            item.totalAmount
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row justify-content-between parent-width mt-s">
              <div className="column">Total Amount</div>
              <div className="column">
                {Utility.getShortAmoutBlockForLabel(
                  rowData.currencyCode,
                  total + ''
                )}
              </div>
            </div>
          </>
        )}
        {!Utility.isEmpty(linkedQuotes) && (
          <>
            {!Utility.isEmpty(linkedSalesInvoices) && <br />}
            <div className="row parent-width font-semibold">Linked Quotes</div>
            <div className="row parent-width font-semibold justify-content-between  mr-s">
              <div className="column parent-width">
                {linkedQuotes?.map((item: any) => {
                  total += +(item.dueAmount || 0);
                  return (
                    <div className="row parent-width justify-content-between mt-s">
                      <div
                        className="column cursor-hand"
                        onClick={(e) => {
                          e.stopPropagation();
                          return;
                        }}
                      >
                        {item.documentSequenceCode}
                      </div>
                      {item.dueAmount && (
                        <div className="column">
                          {Utility.getShortAmoutBlockForLabel(
                            rowData.currencyCode,
                            item.dueAmount
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {!Utility.isEmpty(linkedOrders) && (
          <>
            {!(
              Utility.isEmpty(linkedQuotes) &&
              Utility.isEmpty(linkedSalesInvoices)
            ) && <br />}
            <div className="row parent-width font-semibold">
              Linked Purchase Orders
            </div>
            <div className="row parent-width font-semibold justify-content-between  mr-s">
              <div className="column parent-width gap-1">
                {linkedOrders?.map((item: any) => {
                  return (
                    <div
                      className="row cursor-hand gap-1 align-items-end "
                      onClick={(e) => {
                        e.stopPropagation();
                        return;
                      }}
                    >
                      <DKLabel text={item.documentSequenceCode} />
                      <DKLabel
                        className="text-gray fs-s"
                        text={`(${Utility.convertInTitleCase(
                          item.documentType.replaceAll('_', ' ')
                        )})`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const getAmountBlock = (docCurrency: string, amount: number) => {
    const tenantCurrency = tenantInfo.currency;
    let exchangeRate = 1;
    if (showTotalInBaseCurrency && tenantCurrency !== docCurrency) {
      exchangeRate = activeMultiCurrencyList.find(
        (currency) => currency.currencyCode === docCurrency
      )?.currencyExchangeRate;
    }
    if (exchangeRate) {
      return Utility.getAmoutBlockForGrid(
        showTotalInBaseCurrency ? tenantCurrency : docCurrency,
        amount / exchangeRate + ''
      );
    }
  };

  const bulkFulfillment = (data: any) => {
    const hasFulfilled = Utility.isFulfilledReceived(data, MODULE_TYPE.SELL);

    if (hasFulfilled) {
      const docCodes = data
        .filter(
          (obj: any) =>
            obj.fulfillmentStatus[0] === FULFILLMENT_STATUS.FULLY_FULFILLED
        )
        .map((filteredObj: any) => '#' + filteredObj.documentSequenceCode);
      showAlert(
        '',
        t(`INVOICES.TOAST.THE_SELECTED_QUOTE_INVOICES`) +
          ', ' +
          docCodes +
          t(
            `INVOICES.TOAST.FULLY_FULLFILLED_PLEASE_DESELECT_THIS_AND_TRY_AGAIN`
          )
      );
      return;
    }
    let selectedRowCode: any = selectedRows;
    if (selectedRowCode && selectedRowCode.length > 0) {
      FulfillmentService.getInvoiceBulkFulfillmentDetails(selectedRows).then(
        (data: any) => {
          setBulkFulfillmentDetails(data);
          setEditableSalesOrder(data[0]);
          setShowBulkFulfillmentPopup(true);
        }
      );
    }
  };

  const setBulkFulfillmentRecords = (selectedRowIds: any[] = []) => {
    const salesOrderList: any[] = salesOrdersData['content'];
    const salesOrderData =
      salesOrderList &&
      salesOrderList.filter(
        (salesOrder) =>
          selectedRowIds && selectedRowIds.includes(salesOrder.salesOrderCode)
      );
    if (
      salesOrderData &&
      salesOrderData.length > 0 &&
      selectedRowIds.length > 0
    ) {
      const allEqual = salesOrderData.every((v) => {
        return (
          (v.contactCode || v.contactDto.code) ===
            salesOrderData[0].contactCode &&
          v.status === DOCUMENT_STATUS.OPEN &&
          v.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED &&
          (v.fulfillmentType === FULFILLMENT_TYPE.DEFAULT ||
            v.fulfillmentType === FULFILLMENT_TYPE.NONE)
        );
      });
      if (allEqual) {
        setBulkFulfillmentRows(salesOrderData);
        return;
      } else {
        setBulkFulfillmentRows([]);
      }
    }
    setBulkFulfillmentRows([]);
  };

  const recheckDpl = (contact: any) => {
    const billingAddress: any[] = [];
    const countryCodeMap: any = {};
    COUNTRIES_WITH_CURRENCIES.forEach((country) => {
      countryCodeMap[country?.country?.toLowerCase()] = country?.countryCode;
    });
    if (contact?.billingAddress) {
      contact?.billingAddress?.forEach((address: any) => {
        if (address?.country) {
          billingAddress.push({
            ...address,
            countryCode: countryCodeMap?.[address?.country?.toLowerCase()]
          });
        }
      });
    }
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    );
    if (!dplContactCustomField || dplContactCustomField?.length == 0) return;
    const payload = {
      name: contact?.name ?? '',
      billingAddress: billingAddress,
      customField: dplContactCustomField
    };
    showLoader();
    ContactService.recheck(contact?.id, payload)
      .then((res) => {
        removeLoader();
        if (res) {
          showToast(
            'The contact you are using is under denied party list',
            TOAST_TYPE.SUCCESS
          );
        } else {
          showToast(
            'The contact you are using is not under denied party list',
            TOAST_TYPE.SUCCESS
          );
        }
        dispatch(fetchContacts(''));
      })
      .catch((err) => {
        removeLoader();
      });
  };

  const fulfillment = (data: any) => {
    if (Utility.isUSorg() && Utility.isDPLSettingEnabled()) {
      recheckDpl(data?.rowData?.contactDto);
    }
    const salesOrderList: any[] = salesOrdersData['content'];
    const salesOrderId: number = data.rowData.id;
    const salesOrderData = salesOrderList.filter(
      (salesOrder) => salesOrder.id === salesOrderId
    )[0];
    const isDraft = data.rowData.isDraftDocument;
    const isFulfilled: boolean =
      salesOrderData.fulfillmentStatus === FULFILLMENT_STATUS.FULLY_FULFILLED;
    if (isDraft) {
      showAlert(
        '',
        t(`INVOICES.TOAST.FULLFILLMENT_AGAINST_DRAFT_INVOICE_IS_NOT_ALLOWED`)
      );
      return;
    }
    if (isFulfilled) {
      showAlert('', t(`INVOICES.TOAST.THIS_DOCUMENT_IS_FULLY_FULFILLED`));
      return;
    }

    if (salesOrderData) {
      // if (salesOrderData?.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
      //   showLoader();
      //   //RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
      //   return;
      // }

      const displayFulfillmentConfirmation =
        salesOrderData.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED &&
        salesOrderData.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP;

      if (displayFulfillmentConfirmation) {
        loadSalesOrderDetailsForEdit(
          salesOrderData,
          POPUP_CLICK_TYPE.FULFILLMENT
        );
      } else {
        loadSalesOrderDetailsForEdit(
          salesOrderData,
          POPUP_CLICK_TYPE.FULFILLMENT
        );
      }
    }
  };

  const loadSalesOrderFulfillmentRecords = (
    salesOrderDetails: any,
    popupActionType: POPUP_CLICK_TYPE
  ) => {
    const salesOrderCode: string = salesOrderDetails.salesOrderCode;
    const salesOrderDetailsData: any = salesOrderDetails;
    setEditableSalesOrder(salesOrderDetailsData);
    let salesInvoiceCodeFilters = [
      `documentSequenceCode=${salesOrderDetailsData?.documentSequenceCode}`
    ];
    if (Utility.isNotEmpty(salesOrderDetails?.linkedSalesInvoices)) {
      salesInvoiceCodeFilters.push(
        ...(salesOrderDetails?.linkedSalesInvoices
          ?.filter((doc: any) => Utility.isNotEmpty(doc?.documentSequenceCode))
          ?.map(
            (doc: any) => `documentSequenceCode=${doc?.documentSequenceCode}`
          ) ?? [])
      );
    }
    if (Utility.isNotEmpty(salesOrderDetails?.linkedQuotationDocuments)) {
      salesInvoiceCodeFilters.push(
        ...(salesOrderDetails?.linkedQuotationDocuments
          ?.filter((doc: any) => Utility.isNotEmpty(doc?.documentSequenceCode))
          ?.map(
            (doc: any) => `documentSequenceCode=${doc?.documentSequenceCode}`
          ) ?? [])
      );
    }
    const salesInvoiceCodeQuery = salesInvoiceCodeFilters?.join(',');
    setFulfillmentDetails([]);
    setShowFulfillmentRecordsPopup(false);
    FulfillmentService.getFulfillmentRecords(
      salesOrderCode,
      DOC_TYPE.SALES_ORDER
    ).then((data1: any) => {
      if (
        Utility.isNotEmpty(salesInvoiceCodeQuery) &&
        (GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PPS.READ
        ) ||
          GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.PPS.CREATE
          ))
      ) {
        FulfillmentService.getLinkedPPSFulfillmentRecords(
          salesInvoiceCodeQuery
        ).then((data2: any) => {
          setFulfillmentDetails((prev: any) => {
            if (Utility.isEmpty(data1) && Utility.isEmpty(data2?.content)) {
              showAlert('Error', 'Fulfillment record not found');
              return;
            }
            if (
              Utility.isNotEmpty(data1) &&
              Utility.isNotEmpty(data2?.content)
            ) {
              setFulfillmentType('Fulfillment Records');
            } else if (Utility.isNotEmpty(data1)) {
              setFulfillmentType('Fulfillment Records');
            } else {
              setFulfillmentType('PPS Records');
            }
            const mappedRecords = data2?.content?.map((row: any) => {
              return {
                ...row,
                category: 'PPS Fulfillment',
                fulfillment_code: row?.documentSequenceCode
              };
            });
            setShowFulfillmentRecordsPopup(true);
            return [
              ...(prev ?? []),
              ...(data1 ?? []),
              ...(mappedRecords ?? [])
            ];
          });
        });
      } else {
        if (Utility.isEmpty(data1)) {
          showAlert('Error', 'Fulfillment record not found');
          return;
        }
        setFulfillmentType('Fulfillment Records');
        setFulfillmentDetails(data1);
        setShowFulfillmentRecordsPopup(true);
      }
    });
  };

  const getFulfillmentRecords = (data: any) => {
    const salesOrderList: any[] = salesOrdersData['content'];
    const salesOrderId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    const isNotFulfilled: boolean =
      data.rowData.fulfillmentStatus[0] === FULFILLMENT_STATUS.UNFULFILLED;
    if (isDraft) {
      showAlert(
        '',
        t(
          `INVOICES.TOAST.CANNOT_GET_FULFILLMENT_RECORDS_AGAINST_DRAFT_INVOICES`
        )
      );
      return;
    }
    if (isNotFulfilled) {
      showAlert(
        'Fulfillment Info',
        t(`INVOICES.TOAST.THERE_IS_NO_FULFILLMENT_RECORDS_IN_THIS_DOCUMENT`)
      );
      return;
    }
    const salesOrderData = salesOrderList.filter(
      (salesOrder) => salesOrder.id === salesOrderId
    )[0];
    if (salesOrderData) {
      loadSalesOrderFulfillmentRecords(
        salesOrderData,
        POPUP_CLICK_TYPE.FULFILLMENT_RECORDS
      );
    }
  };

  const salesReturn = (data: any) => {
    const salesOrderList: any[] = salesOrdersData['content'];
    const salesOrderId: number = data.rowData.id;
    const isDraft = data.rowData.isDraftDocument;
    if (isDraft) {
      showAlert('', 'Sales Return not allowed on draft');
      return;
    }
    const salesOrder = salesOrderList.filter(
      (order) => order.id === salesOrderId
    )[0];
    if (salesOrder) {
      Promise.all([
        FulfillmentService.getFulfillmentRecords(
          salesOrder.salesOrderCode,
          DOC_TYPE.SALES_ORDER
        ),
        SalesReturnService.isFullyReturned(
          salesOrder.salesOrderCode,
          DOC_TYPE.SALES_ORDER
        )
      ])
        .then(
          (data: any) => {
            let returnedData = [];
            if (!Utility.isEmpty(data?.[1])) {
              returnedData = data[1];
            }
            let filteredFulfillment = data?.[0];
            if (
              data &&
              data[1] &&
              Array.isArray(returnedData) &&
              returnedData.length > 0 &&
              !Utility.hasQtyToReturn(
                returnedData,
                RETURN_MODE.SALE,
                filteredFulfillment
              )
            ) {
              showAlert('', 'This sales order has been fully returned.');
            } else {
              loadSalesOrderDetailsForEdit(
                salesOrder,
                POPUP_CLICK_TYPE.SALES_RETURN
              );
            }
          },
          (err: any) => {
            console.error('Error getting fulfillment records: ', err);
          }
        )
        .catch((err: any) => {
          loadSalesOrderDetailsForEdit(
            salesOrder,
            POPUP_CLICK_TYPE.SALES_RETURN
          );
        });
    }
  };

  const getSalesReturnRecords = (data: any) => {
    const salesOrderList: any[] = salesOrdersData['content'];
    const quoteId: number = data.rowData.id;
    const salesOrder = salesOrderList.filter(
      (quote) => quote.id === quoteId
    )[0];
    setEditableSalesOrder(salesOrder);
    SalesReturnService.isFullyReturned(
      salesOrder.salesOrderCode,
      DOC_TYPE.SALES_ORDER
    )
      .then((res: any) => {
        // let data = Utility.filterReturnRecords(res, DOC_TYPE.SALES_ORDER);
        let data = res;

        if (data?.length === 0) {
          showAlert('', 'Sales Returns Records not available.');
        } else {
          setSalesReturnsDetails(data);
          setShowSalesReturnRecordsPopup(true);
        }
      })
      .catch((err) => {
        removeLoader();
        showAlert('Error', 'Unable to get Sales Returns Records');
      });
  };

  const createDocForReturn = (data: any) => {
    let msg =
      'Returned Goods have been added back to your selected warehouse. To refund , create a Credit Note or a Direct Expense for this contact.';
    showAlert('Sales Return Created Successfully', msg, [
      {
        title: 'Do it later',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {}
      },
      {
        title: 'Direct Expense',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          const record: any = {
            gstExchangeRate: 1,
            exchangeRate: 1,
            referenceNumber: '',
            makePaymentItemDtoList: [],
            isTdsPaymentIndia: true,
            contactCode: editableSalesOrder.contactCode,
            contactName: editableSalesOrder.contact.name
          };
          setExpenseData(record);
          setshowExpenseButton(true);
        }
      },
      {
        title: 'Credit Note',
        className: 'bg-button text-white border-m whitespace-nowrap',
        onClick: () => {
          createCNPayload(data);
        }
      }
    ]);
  };

  const createCNPayload = (data: any) => {
    const documentItems = editableSalesOrder.items;
    const returnedItems = data.salesReturnItems;
    data['returnedItems'] = data.salesReturnItems;
    let arr: any = [];
    let srAccountDetails = documentItems
      .filter((item1: any) =>
        returnedItems.some(
          (item2: any) =>
            item1.productCode === item2.productCode &&
            item2.quantityToReturn > 0
        )
      )
      .reduce((res: any, item: any) => {
        let accountCode = item?.product?.salesReturnAccountCode;
        let obj: any = {};
        if (!res[accountCode]) {
          obj = {
            accountCode: accountCode,
            amountDue: 0,
            amount: 0,
            taxAmount: 0,
            taxCode: item.taxCode,
            taxList: []
          };
        }
        let qtyToReturn = returnedItems.find(
          (i: any) => i.productCode === item.productCode
        ).quantityToReturn;
        obj.amount += item.unitPrice * qtyToReturn;
        obj.taxAmount =
          (Number(item.taxAmount) / Number(item.productQuantity)) * qtyToReturn;
        arr.push(obj);
        return arr;
      }, {});

    const record: any = {
      gstExchangeRate: 1,
      exchangeRate: 1,
      referenceNumber: '',
      lineItems: arr,
      contactCode: editableSalesOrder.contactCode,
      contact: {
        ...editableSalesOrder.contact,
        code: editableSalesOrder.contactCode
      },
      cnDate: data.salesReturnDate,
      currency: editableSalesOrder.currency,
      type: 'SALES'
    };
    setShowCNAndDNInfo({
      showPopup: true,
      data: record,
      documentType: DOC_TYPE.CREDIT_NOTE
    });
  };

  // Load linked Quote
  const loadLinkedQuote = async (code: any) => {
    try {
      const quote = await QuotationService.getQuoteByCode(code);
      const quoteDetailsData: any = getUpdatedQuoteObject(quote);
      if (!Utility.isEmpty(quoteDetailsData)) {
        let payloadData: any = {
          id: quoteDetailsData.id,
          type: LABELS.QUOTES,
          title: Utility.convertInTitleCase(localizedText('quote')),
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: quoteDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked quote: ', err);
    }
  };

  // Load linked Order
  const loadLinkedOrder = async (code: any) => {
    try {
      const order = await PurchaseOrderService.fetchOrderDetails(code);
      const orderDetailsData: any = getUpdatedPurchaseOrderObject(order as any);
      if (!Utility.isEmpty(orderDetailsData)) {
        let payloadData: any = {
          id: orderDetailsData.id,
          type: LABELS.PURCHASE_ORDERS,
          title: LABELS.PURCHASE_ORDERS,
          isMaximized: true,
          isCenterAlign: true,
          populateFormData: orderDetailsData,
          isSaved: true,
          draftsTableId,
          draftsColumnConfig
        };

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: DraftTypes.READONLY
          })
        );
      }
    } catch (err: any) {
      console.error('Error loading linked order: ', err);
    }
  };

  const setBulkSOForPrint = (selectedRows: any[]) => {
    const selectedRowIds =
      selectedRows?.map((row: any) => row.salesOrderCode) || [];
    const salesOrdersInStore: any[] = salesOrdersData.content;
    const documentData = salesOrdersInStore.filter((x) =>
      selectedRowIds.includes(x.salesOrderCode)
    );
    if (documentData && documentData.length > 1) {
      getBulkPrintPreview(
        TEMPLATE_CATEGORY.SALES_ORDER,
        documentData.map((row: any) => row.salesOrderCode) || []
      );
    }
  };

  const onBulkPrintlick = (data: any) => {
    setBulkSOForPrint(data);
  };

  const updateBulkPrintRecord = (selectedRows: any[] = []) => {
    if (selectedRows && selectedRows.length > 1) {
      let draft = selectedRows.find(
        (x) => x.isDraftDocument === true || x.rowType === 'draft'
      );
      if (!draft) {
        setBulkPrintRecordData(selectedRows);
        return;
      }
    }
    setBulkPrintRecordData([]);
  };

  // Linked Record Action
  const onLinkedRecords = (data: any) => {
    setSalesOrderLinkRecordsData(data.rowData);
    setShowLinkRecordsPopup(true);
  };

  const getContextMenuForRow = (salesOrder?: any) => {
    let menuItems: any[] = [];
    if (
      Utility.isEmpty(salesOrder?.draftCode) &&
      !Utility.isEmpty(salesOrder?.documentSequenceCode)
    ) {
      if (
        (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT) &&
          SellConfig.showActionButton(
            SELL_CONFIG_BTN.EDIT,
            salesOrder,
            DOC_TYPE.SALES_ORDER
          )) ||
        tenantInfo?.additionalSettings?.SO_EDIT_SETTING
      ) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          onClick: (data: any) => {
            onSalesOrderEdit(data, DOCUMENT_MODE.EDIT);
          }
        });
      } else {
        if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW)) {
          menuItems.push({
            title: 'Open',
            icon: DKIcons.ic_open,
            onClick: (data: any) => {
              onSalesOrderEdit(data, DOCUMENT_MODE.VIEW);
            }
          });
        }
      }

      if (
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE) &&
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.CONVERT,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        )
      ) {
        menuItems.push({
          title: 'Convert to Invoice',
          icon: DKIcons.ic_convert_3,
          onClick: ({ rowIndex, rowData }: any) => {
            loadSalesOrderDetailsForEdit(
              rowData,
              POPUP_CLICK_TYPE.OPEN_CONVERT_SO_OPTIONS
            );
          }
        });
      }

      if (
        checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE) &&
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.WORK_ORDER,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.WORK_ORDER.CREATE) &&
        Utility.isMRPWithURLCheck() &&
        Utility.isEmpty(salesOrder?.linkedWorkOrderDocuments)
      ) {
        menuItems.push({
          title: 'Create Work Order',
          icon: DKIcons.ic_document,
          onClick: async (data: any) => {
            let newSalesOrder =
              WorkOrderHelper.deductConvertedQuantityForSOtoWOFlow(salesOrder);
            await validateSelectedSOForInterLinkedWO(newSalesOrder, () => {
              setSelectedSalesOrder(newSalesOrder);
              setShowAddWorkOrderPopup(true);
            });
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL)
      ) {
        menuItems.push({
          title: localizedText('Fulfill'),
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => fulfillment(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.CREATE
        )
      ) {
        menuItems.push({
          title: 'Create Sales Return',
          icon: DKIcons.ic_delivery,
          onClick: (data: any) => salesReturn(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.FULFILL_RECORD,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL)
      ) {
        menuItems.push({
          title: localizedText('Fulfillment Records'),
          icon: DKIcons.ic_document,
          onClick: (data: any) => getFulfillmentRecords(data)
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.RETURN_RECORDS,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        GranularPermissionsHelper.hasPermissionFor(
          PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.VIEW
        )
      ) {
        menuItems.push({
          title: 'Sales Returns Records',
          icon: DKIcons.ic_document,
          onClick: (data: any) => getSalesReturnRecords(data)
        });
      }

      if (
        Utility.isApprovalColumn(DOC_TYPE.SALES_ORDER) &&
        !Utility.isEmpty(salesOrder.approvalStatus) &&
        salesOrder.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
      ) {
        menuItems.push({
          title: 'Approval/Rejection History',
          icon: DKIcons.ic_document,
          className: 'p-v-0',
          onClick: (data: any) => {
            getApprovalHistory(data, false);
          }
        });
      }

      if (
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE) &&
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.COPY,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        )
      ) {
        menuItems.push({
          title: 'Copy',
          icon: DKIcons.ic_copy,
          onClick: ({ rowIndex, rowData }: any) => {
            loadSOForCopy(rowData);
          }
        });
      }

      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.VIEW,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        !Utility.isEmpty(salesOrder?.contactDto?.contactNumber)
      ) {
        menuItems.push({
          title: 'Whatsapp',
          icon: ic_whatsapp,
          onClick: ({ rowIndex, rowData }: any) => {
            sendDocumentLinkThroughWhatsapp(salesOrder);
          }
        });
      }

      if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.PRINT)) {
        menuItems.push({
          title: 'Print',
          icon: DKIcons.ic_printer,
          onClick: ({ rowIndex, rowData }: any) => {
            if (!isTabletView()) {
              setSOForPrint(rowData);
            } else {
              Utility.postMobileAppActions(
                MOBILE_APP_ACTIONS.MOBILE_PRINT + ':' + JSON.stringify(rowData)
              );
            }
          }
        });
        menuItems.push({
          title: 'Design',
          icon: DKIcons.ic_paint,
          onClick: (data: any) => {
            openDesigner(data.rowData);
          }
        });
      }

      if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EMAIL)) {
        menuItems.push({
          title: 'Email',
          icon: DKIcons.ic_email,
          onClick: ({ rowIndex, rowData }: any) => {
            setSOForEmail(rowData);
          }
        });
      }

      if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT)) {
        menuItems.push({
          title: 'Attachment',
          icon: DKIcons.ic_attachment,
          onClick: (data: any) => onAttachmentCLicked(data)
        });
      }

      if (
        featurePermissionsInfo?.Supported?.includes(
          FEATURE_PERMISSIONS.LABEL_PRINT
        ) &&
        tenantInfo?.additionalSettings?.LABEL_PRINT === true
      ) {
        menuItems.push({
          title: 'Generate Label',
          icon: DKIcons.ic_barcode,
          onClick: ({ rowIndex, rowData }: any) =>
            loadSalesOrderDetailsForEdit(
              rowData,
              POPUP_CLICK_TYPE.GENERATE_LABEL
            )
        });
      }
    } else {
      if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT)) {
        menuItems.push({
          title: 'Edit',
          icon: DKIcons.ic_edit,
          onClick: (data: any) => {
            onSalesOrderEdit(data, DOCUMENT_MODE.EDIT);
          }
        });
      }
    }

    if (
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.LINKED_RECORDS,
        salesOrder,
        DOC_TYPE.SALES_ORDER
      ) &&
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW)
    ) {
      menuItems.push({
        title: 'Linked Records',
        icon: DKIcons.ic_document,
        className: 'p-v-0',
        onClick: (data: any) => {
          onLinkedRecords(data);
        }
      });
    }
    if (
      tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_SALES_ORDER &&
      salesOrder.status === 'OPEN'
    ) {
      menuItems.push({
        title: 'Rate Analysis',
        icon: DKIcons.ic_document,
        onClick: ({ rowIndex, rowData }: any) => {
          getRateAnalysis(rowData);
        }
      });
    }

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.DELETE) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.DELETE,
        salesOrder,
        DOC_TYPE.SALES_ORDER
      )
    ) {
      menuItems.push({
        title: 'Delete',
        icon: DKIcons.ic_delete,
        onClick: async (data: any) => {
          const rowData = [data.rowData];
          if (!Utility.isEmpty(rowData)) {
            const isConfirmed = await confirm(
              t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`)
            );

            if (isConfirmed) {
              if (
                rowData[0].fulfillmentStatus[0] ===
                  FULFILLMENT_STATUS.UNFULFILLED ||
                rowData[0]?.isDraftDocument ||
                rowData[0]?.draft
              ) {
                onSalesOrderDelete(rowData);
              } else {
                showAlert(
                  '',
                  `Sales order <b>${
                    salesOrder?.documentSequenceCode
                      ? salesOrder?.documentSequenceCode
                      : ''
                  }</b> is in use, so cannot be deleted`
                );
              }
            }
          }
        }
      });
    }

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT) &&
      salesOrder.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
    ) {
      if (
        Utility.isApproverButtonShow(
          salesOrder.documentType || DOCUMENT_TYPE.SALES_ORDER,
          salesOrder,
          APPROVAL_STATUS.APPROVED
        )
      ) {
        menuItems.push({
          title: 'Approve',
          icon: DKIcons.ic_check_mark,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Approve',
              actionDetails: data
            });
          }
        });
      }
      if (
        Utility.isApproverButtonShow(
          salesOrder.documentType || DOCUMENT_TYPE.SALES_ORDER,
          salesOrder,
          APPROVAL_STATUS.REJECTED
        )
      ) {
        menuItems.push({
          title: 'Reject',
          icon: DKIcons.ic_close,
          className: 'p-v-0',
          onClick: (data: any) => {
            setShowApprovalOrRejectPopup(true);
            setApprovalOrRejectData({
              title: 'Reject',
              actionDetails: data
            });
          }
        });
      }
    }

    if (
      Utility.isApprovalColumn(DOC_TYPE.SALES_ORDER) &&
      salesOrder.approvalStatus !== APPROVAL_STATUS['NOT_REQUIRED']
    ) {
      menuItems.push({
        title: 'Approval/Rejection History',
        icon: DKIcons.ic_document,
        className: 'p-v-0',
        onClick: (data: any) => {
          getApprovalHistory(data, true);
        }
      });
    }

    return menuItems;
  };

  // Send document link through Whatsapp
  const sendDocumentLinkThroughWhatsapp = (rowData: any) => {
    const payload: any = {
      category: DOC_TYPE.SALES_ORDER,
      contactCode: rowData?.contactCode,
      documentCode: rowData?.salesOrderCode,
      documentType: PrintService.getTemplateNameType(
        DOC_TYPE.SALES_ORDER.toString().toLowerCase()
      ),
      event: TemplateMappingEvent.PRINT,
      parentDocumentType: null,
      parentDocumentCode: null
    };
    PrintService.getTemplatePrintInfo(payload).then(
      (data: any) => {
        const code = data?.code;
        if (Utility.isEmpty(code)) {
          showAlert('Error!', 'Error creating document link');
          return;
        }
        let link = `${process.env.REACT_APP_DOC_DESIGNER_URL}/?pid=${code}`;
        if (
          data.defaultTemplateMapping &&
          data.defaultTemplateMapping.templateId
        ) {
          link = `${link}&tid=${data.defaultTemplateMapping.templateId}`;
        }
        const sanitizedNumber = Utility.sanitizePhoneForWhatsapp(
          rowData.contactDto?.contactNumber
        );
        const messageToSend = WhatsAppHelper.getMessageForSalesDocument(
          'Sales Order',
          rowData,
          link,
          tenantInfo?.name
        );
        const urlToOpen =
          ApiConstants.URL.WHATSAPP.WHATSAPP_URL(sanitizedNumber) +
          `?text=${encodeURIComponent(messageToSend)}`;
        window.open(urlToOpen, '_blank');
      },
      (err: any) => {
        showAlert('Error!', 'Error fetching document link');
      }
    );
  };

  function getApprovalHistory(data: any, isDraft: boolean) {
    setEditableSalesOrder(data?.rowData);
    if (isDraft) {
      if (
        Utility.isEmpty(data?.rowData?.multiApprovalDetails?.approvalHistory)
      ) {
        showAlert('Approval/Rejection History', 'No History Found');
        return;
      }
      setApprovalHistoryData(data?.rowData?.multiApprovalDetails);
      setShowApprovalHistoryPopup(true);
    } else {
      AutomationService.getApprovalHistory(
        data?.rowData?.documentSequenceCode,
        DOC_TYPE.SALES_ORDER,
        draftsTableId
      )
        .then((res: any) => {
          if (res) {
            setApprovalHistoryData(res?.cells?.multiApprovalDetails);
            setShowApprovalHistoryPopup(true);
          } else {
            showAlert('Approval/Rejection History', 'No History Found');
          }
        })
        .catch((err) => {
          removeLoader();
          showAlert('Approval/Rejection History', 'No History Found');
        });
    }
  }
  async function onApproveDraft(
    data: any,
    status: APPROVAL_STATUS,
    remarks: string
  ) {
    if (isApprovalInprogress == false) {
      const rowData = data.rowData;
      if (rowData.isDraftDocument) {
        const draftIds = [rowData.id];
        setIsApprovalInprogress(true);
        setUpdating(true);
        try {
          await DraftService.updateApprovalStatus(
            draftsTableId,
            draftIds,
            DOC_TYPE.SALES_ORDER,
            status,
            remarks
          );
        } catch (e) {
        } finally {
          setIsApprovalInprogress(false);
          setUpdating(false);
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.SALES_ORDER
            })
          );
          loadSalesOrders({});
        }
      }
    }
  }

  const getButtonsForRow = (salesOrder: any) => {
    let buttons: any[] = [];

    if (
      Utility.isEmpty(salesOrder?.draftCode) &&
      !Utility.isEmpty(salesOrder?.documentSequenceCode)
    ) {
      if (
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.CONVERT,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        ) &&
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.CREATE)
      ) {
        buttons.push({
          title: 'Convert',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: ({ rowIndex, rowData }: any) => {
            loadSalesOrderDetailsForEdit(
              rowData,
              POPUP_CLICK_TYPE.OPEN_CONVERT_SO_OPTIONS
            );
          }
        });
      }
      if (
        salesOrder.linkedQuotationDocuments &&
        salesOrder.linkedQuotationDocuments?.length
      ) {
        const docType = salesOrder.linkedQuotationDocuments[0]?.documentType;
        let title = '';
        if (docType === DOC_TYPE.QUOTE) {
          title = `Linked ${getCapitalized(localizedText('quote'))}`;
        }
        buttons.push({
          title: title,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            if (docType === DOC_TYPE.QUOTE) {
              loadLinkedQuote(
                salesOrder.linkedQuotationDocuments?.[0]?.documentCode
              );
            }
          }
        });
        const linkedPO = salesOrder.linkedQuotationDocuments.find(
          (l: any) => l.documentType === DOC_TYPE.ORDER
        );
        if (linkedPO) {
          if (docType === DOC_TYPE.ORDER) {
            title = 'Linked Order';
          }

          buttons.push({
            title: title,
            className: 'p-v-0 text-blue underline grid-action-link-h-padding',
            onClick: (data: any) => {
              if (docType === DOC_TYPE.ORDER) {
                loadLinkedOrder(linkedPO.documentCode);
              }
            }
          });
        }
      }
    } else {
      if (
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT) &&
        SellConfig.showActionButton(
          SELL_CONFIG_BTN.EDIT,
          salesOrder,
          DOC_TYPE.SALES_ORDER
        )
      ) {
        buttons.push({
          title: 'Edit',
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: (data: any) => {
            onSalesOrderEdit(data, DOCUMENT_MODE.EDIT);
          }
        });
      }
    }

    return buttons;
  };

  const getRowData = (salesOrdersList: any) => {
    let rowData: any[] = [];

    salesOrdersList?.forEach((salesOrder: any, index: number) => {
      const contact = salesOrder.contact ? salesOrder.contact.name : '';
      const subTotalAmount = salesOrder.totalAmount - salesOrder.taxAmount;
      const isSelected = currentSelectedRows.find(
        (x) => x.id === salesOrder.id
      );
      const approvalStatus = Utility.isEmptyValue(salesOrder.approvalStatus)
        ? APPROVAL_STATUS.NOT_REQUIRED
        : salesOrder.approvalStatus;
      let remarks = '';
      if (salesOrder.multiApprovalDetails?.approvalHistory?.length > 0) {
        const allRemarks = salesOrder.multiApprovalDetails.approvalHistory
          .filter((history: any) => history?.remarks)
          .map((history: any) => `Level ${history.level}: ${history.remarks}`);
        remarks = allRemarks.join(', ');
      }
      const truncatedRemarks =
        remarks.length > 50 ? `${remarks.substring(0, 50)}...` : remarks;
      let row = {
        ...salesOrder,
        [COLUMN_CODE.SALES_ORDER.Number]:
          !salesOrder.isDraftDocument &&
          typeof salesOrder.documentSequenceCode !== 'undefined'
            ? salesOrder.documentSequenceCode
            : Utility.getDraftIconBlockForGrid(salesOrder.draftCode),
        [COLUMN_CODE.SALES_ORDER.Code]: salesOrder.salesOrderCode,
        [COLUMN_CODE.SALES_ORDER.CustomerOrderNo]:
          salesOrder.customerOrderNumber,
        [COLUMN_CODE.SALES_ORDER.SaleOrderDate]:
          DateFormatService.getDateFromStr(
            salesOrder.salesOrderDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
        [COLUMN_CODE.SALES_ORDER.SalesOrderDueDate]:
          DateFormatService.getDateFromStr(
            salesOrder.salesOrderDueDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
        [COLUMN_CODE.SALES_ORDER.ShipByDate]: salesOrder?.shipByDate
          ? DateFormatService.getDateFromStr(
              salesOrder.shipByDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )
          : '',
        [COLUMN_CODE.SALES_ORDER.TotalAmount]: getAmountBlock(
          salesOrder.currency,
          salesOrder?.totalAmount
        ),
        [COLUMN_CODE.SALES_ORDER.BalanceDue]:
          salesOrder?.draft || salesOrder?.isDraftDocument
            ? '-'
            : getAmountBlock(salesOrder.currency, salesOrder?.balanceDue || 0),
        [COLUMN_CODE.SALES_ORDER.AmountPaid]:
          salesOrder?.draft || salesOrder?.isDraftDocument
            ? '-'
            : getAmountBlock(salesOrder.currency, salesOrder.amountPaid || 0),
        [COLUMN_CODE.SALES_ORDER.Contact]: contact,
        [COLUMN_CODE.SALES_ORDER.Status]: [salesOrder.status],
        [COLUMN_CODE.SALES_ORDER.Recurring]: [
          salesOrder.recurring
            ? RECURRING_DOCUMENT_TYPE.RECURRING
            : RECURRING_DOCUMENT_TYPE.NON_RECURRING
        ],
        [COLUMN_CODE.SALES_ORDER.FulfillmentStatus]:
          salesOrder?.draft || salesOrder?.isDraftDocument
            ? '-'
            : [salesOrder.fulfillmentStatus],
        [COLUMN_CODE.SALES_ORDER.Id]: salesOrder.id ? salesOrder.id : index,
        [COLUMN_CODE.SALES_ORDER.Remarks]: remarks,
        [COLUMN_CODE.SALES_ORDER.ApprovalStatus]:
          APPROVAL_STATUS_LIST[approvalStatus] +
          getApprovalLevel(salesOrder, APPROVAL_STATUS_LIST[approvalStatus]),
        recurringActivated: salesOrder.recurringActivated,
        backOrder: salesOrder.backOrder,
        currencyCode: salesOrder.currency,
        linkedSalesInvoices: salesOrder['linkedSalesInvoices'],
        poNumber: salesOrder.poNumber,
        orderReference: salesOrder?.poNumber,
        rowType:
          typeof salesOrder.documentSequenceCode === 'undefined'
            ? 'draft'
            : DOCUMENT_TYPE.SALES_ORDER,
        allowRowEdit: true,
        productionStatus: salesOrder.productionStatus
          ? salesOrder.productionStatus
          : 'Pending',
        orderProgress: !Utility.isEmptyObject(salesOrder.orderProgress)
          ? salesOrder.orderProgress
          : [...STATIC_ORDER_PROGRESS],
        [COLUMN_CODE.SALES_ORDER.COGS]: salesOrder.cogsAmount
          ? getAmountBlock(
              salesOrder.currency,
              salesOrder.cogsAmount * salesOrder?.exchangeRate
            )
          : '',
        [COLUMN_CODE.SALES_ORDER.Profit]: salesOrder.cogsAmount
          ? getAmountBlock(
              salesOrder.currency,
              subTotalAmount - salesOrder.cogsAmount * salesOrder?.exchangeRate
            )
          : '',
        [COLUMN_CODE.SALES_ORDER.GrossMargin]: salesOrder.cogsAmount
          ? Utility.roundOffToTenantDecimalScale(
              ((subTotalAmount -
                salesOrder.cogsAmount * salesOrder?.exchangeRate) /
                subTotalAmount) *
                100
            ) + '%'
          : '',
        [COLUMN_CODE.SALES_ORDER.CreatedBy]: [salesOrder.createdBy],
        rowContextMenu: getContextMenuForRow(salesOrder),
        selected: isSelected ? true : false,
        rowButtons: getButtonsForRow(salesOrder)
      };
      if (salesOrder.customField && salesOrder.customField.length > 0) {
        salesOrder.customField.forEach((cf: any) => {
          const columnCode: string = `${COLUMN_TYPE.CUSTOM}_${cf.code}_${cf.id}`;
          const colType = columnConfig.find(
            (config: any) => config.columnCode === columnCode
          )?.type;
          const cfInAvaialbleCFs = availableCustomFields?.content?.find(
            (field: any) => field.id === cf.id
          );
          const isUserTypeCF = cfInAvaialbleCFs
            ? cfInAvaialbleCFs.fieldType === CUSTOM_FIELD_TYPE.USER
            : false;
          let cfValue = '';
          if (isUserTypeCF) {
            const tempCF = cfInAvaialbleCFs?.attributes?.find(
              (attr: any) => attr.code === cf.value
            );
            cfValue = tempCF ? tempCF.value : '';
          } else {
            cfValue = cf ? cf.value : '';
          }
          row = {
            ...row,
            [columnCode]: colType === INPUT_TYPE.SELECT ? [cfValue] : cfValue
          };
        });
      }
      rowData.push(row);
    });
    return rowData;
  };

  const loadSalesOrderDetailsForEdit = (
    salesOrderDetails: any,
    popupActionType: POPUP_CLICK_TYPE
  ) => {
    const salesOrderCode: string = salesOrderDetails.salesOrderCode;
    let salesOrderDetailsData: any =
      getUpdatedSalesOrderObject(salesOrderDetails);
    const displayFulfillmentConfirmation =
      !Utility.isAllNonTracked(salesOrderDetailsData?.items) &&
      salesOrderDetailsData.fulfillmentStatus ===
        FULFILLMENT_STATUS.UNFULFILLED &&
      salesOrderDetailsData.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP;
    const isDropShip = Utility.needDropshipPopup(salesOrderDetailsData);
    showLoader('Loading...');
    SalesOrderService.getSalesOrderByCode(salesOrderCode).then(
      (data: any) => {
        removeLoader();
        let salesOrderDetailsData: any = getUpdatedSalesOrderObject(data);
        if (
          salesOrderDetailsData.poNumber == null ||
          salesOrderDetailsData.poNumber !== undefined
        ) {
          salesOrderDetailsData.poNumber = salesOrderDetails.poNumber;
        }
        setEditableSalesOrder(salesOrderDetailsData);
        switch (popupActionType) {
          case POPUP_CLICK_TYPE.FULFILLMENT:
            if (isDropShip) {
              setShowDropshipConfirmationPopup(true);
            } else {
              setShowFulfillmentConfirmationPopup(true);
              setShowFulfillmentPopup(false);
            }
            break;
          case POPUP_CLICK_TYPE.COPY_SALES_ORDER:
            const isINDTaxSystem =
              getTenantTaxSystem() === TAX_SYSTEM.INDIA_GST;
            let salesOrderData: any = {
              ...data,
              id: null,
              documentSequenceCode: null,
              salesOrderCode: null,
              reservedStock: null,
              reservedQuantitiesData: null,
              isPartialSalesOrder: false,
              salesOrderItems: data.salesOrderItems.map((item: any) => {
                delete item?.qtyConvertedToWo;
                return {
                  ...item,
                  id: null,
                  documentItemCode: null,
                  isPartialSalesOrder: false,
                  customField: item.customField,
                  quantityFulfilled: 0,
                  fulfilledQuantity: 0,
                  invoicedQty: 0,
                  fulfilledQuantityInvoiced: 0,
                  qtyConvertedToDropShip: 0,
                  qtyFulfilledFromToDropShip: 0,
                  qtyFulfilledFromPps: 0,
                  qtyConvertedToPps: 0,
                  reservedQuantitiesData: null,
                  igstAmount: isINDTaxSystem ? item.igstAmount : 0,
                  igstRate: isINDTaxSystem ? item.igstRate : 0,
                  cgstAmount: isINDTaxSystem ? item.cgstAmount : 0,
                  cgstRate: isINDTaxSystem ? item.cgstRate : 0,
                  sgstAmount: isINDTaxSystem ? item.sgstAmount : 0,
                  sgstRate: isINDTaxSystem ? item.sgstRate : 0,
                  cessAmount: isINDTaxSystem ? item.cessAmount : 0,
                  cessPercentage: isINDTaxSystem ? item.cessPercentage : 0,
                  nonEditableColumns:
                    item.type === PRODUCT_TYPE.NON_TRACKED &&
                    item.product?.stockUom === UOM_NA_ID
                      ? ['productQuantity']
                      : []
                };
              }),
              linkedDocuments: null,
              linkedSalesInvoices: null,
              linkedSalesInvoiceDocuments: null,
              linkedQuotationDocuments: null,
              linkedWorkOrderDocuments: null,
              fulfillmentStatus: FULFILLMENT_STATUS.UNFULFILLED,
              fulfillmentType: FULFILLMENT_TYPE.NONE,
              status: DOCUMENT_STATUS.OPEN,
              approvalStatus: APPROVAL_STATUS.NOT_REQUIRED,
              multiApprovalDetails: null,
              duplicate: true,
              entityId: data.id,
              recurring: false,
              recurringActivated: false,
              backOrder: false
            };
            if (!Utility.isEmpty(salesOrderData)) {
              let payloadData: any = {
                type: LABELS.SALES_ORDER,
                title: LABELS.SALES_ORDER,
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: salesOrderData,
                isSaved: true,
                tableId: draftsTableId,
                columnConfig: draftsColumnConfig
              };
              salesOrderData.salesOrderItems =
                salesOrderData.salesOrderItems.filter(
                  (item: any) => item?.product?.active
                );

              dispatch(
                createBlankDraft({ payloadData, draftType: DraftTypes.COPY })
              );
            }
            break;
          case POPUP_CLICK_TYPE.OPEN_ATTACHMENT:
            setShowAttachmentPopup(true);
            break;
          case POPUP_CLICK_TYPE.SALES_RETURN:
            setShowSalesReturnPopup(true);
            break;
          case POPUP_CLICK_TYPE.GENERATE_LABEL:
            setGenerateLabelPopup(true);
            break;
          case POPUP_CLICK_TYPE.OPEN_CONVERT_SO_OPTIONS:
            setShowConversionPopup(true);
            break;
          default:
            break;
        }
      },
      (err) => {
        console.error('Error while fetching sales order details: ', err);
        removeLoader();
      }
    );
  };

  // Check if minimized doc exists and maximize it
  const minimizedDocExists = (documentCode: string, isDraft: boolean) => {
    const minimizedDocInfo = getMinimizedDocInfo(
      documentCode,
      isDraft,
      DOC_TYPE.SALES_ORDER
    );

    if (minimizedDocInfo.isMinimized) {
      let payloadData: any = {
        id: isDraft ? documentCode : minimizedDocInfo?.activeFormData?.id,
        isCenterAlign: true
      };
      dispatch(setDraftCenter(payloadData));
      dispatch(
        setPopulateFormData({
          id: documentCode,
          formdata: minimizedDocInfo.activeFormData
        })
      );
    }

    return minimizedDocInfo.isMinimized;
  };

  // Open document from document code in the URL
  const directOpenDocument = (documentCode: string) => {
    let documentMode = props.location.state?.documentMode || DOCUMENT_MODE.EDIT;

    if (documentCode.toLowerCase() === 'create') {
      // if (
      //   GranularPermissionsHelper.hasPermissionFor(
      //     PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE
      //   )
      // ) {
      if (!isOpeningForm) {
        addRecordClicked();
      }
      // } else {
      //   showAlert(
      //     'No Permission!',
      //     'You don't have permission to create sales order, please contact owner/admin of this organisation'
      //   );
      //   return;
      // }
      removeLoader();
    }
    if (documentCode.toLowerCase() !== 'create') {
      if (minimizedDocExists(documentCode, true)) {
        return;
      }
      const existingDraft = salesOrderDraftData?.find(
        (draft: any) => draft._id === documentCode
      );
      if (!Utility.isEmpty(existingDraft)) {
        removeLoader();
        const { type, payload, isMaximized, isCenterAlign, isSaved } =
          Utility.getKeysForDraftColumns(draftsColumnConfig);

        const formData = getUpdatedSalesOrderObject(
          Utility.parseDraftDocumentPayload(existingDraft.cells[payload[0].id])
        );
        let payloadData: any = {
          id: existingDraft._id,
          type: existingDraft.cells[type[0].id],
          title: existingDraft.cells[type[0].id],
          isMaximized: existingDraft.cells[isMaximized[0].id],
          isCenterAlign: existingDraft.cells[isCenterAlign[0].id],
          populateFormData: formData,
          isSaved: existingDraft.cells[isSaved[0].id],
          draftCode: existingDraft.cells.documentSequenceCode,
          draftsTableId,
          draftsColumnConfig
        };

        const isDraftReadOnly = Utility.isDraftReadOnly(
          payloadData.populateFormData
        );

        if (isDraftReadOnly) {
          payloadData.title = 'Draft';
          payloadData.hideSave = true;
          payloadData.isReadOnlyDraft = true;
        }

        dispatch(
          createBlankDraft({
            payloadData,
            draftType: isDraftReadOnly ? DraftTypes.READONLY : DraftTypes.DRAFT
          })
        );
      } else {
        if (minimizedDocExists(documentCode, false)) {
          return;
        }
        showLoader(OPENING_DOCUMENT_LABEL);
        SalesOrderService.getSalesOrderByCode(documentCode).then(
          (data: any) => {
            let docDetailsData: any = getUpdatedSalesOrderObject(data);
            const customFieldCheck = !(
              tenantInfo?.additionalSettings?.SO_EDIT_SETTING || false
            );
            var isReadOnly = getIsReadOnly(docDetailsData) && customFieldCheck;
            if (documentMode === DOCUMENT_MODE.VIEW) {
              isReadOnly = true;
            } else if (documentMode === DOCUMENT_MODE.EDIT) {
              isReadOnly =
                isReadOnly &&
                checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT);
            }
            if (!Utility.isEmpty(docDetailsData)) {
              let payloadData: any = {
                id: docDetailsData.id,
                type: LABELS.SALES_ORDER,
                title: LABELS.SALES_ORDER,
                isMaximized: true,
                isCenterAlign: true,
                populateFormData: docDetailsData,
                isSaved: true,
                draftsTableId,
                draftsColumnConfig
              };
              let type = isReadOnly ? DraftTypes.READONLY : DraftTypes.UPDATE;
              type = isDocumentLocked(docDetailsData)
                ? DraftTypes.LOCKED
                : type;
              dispatch(
                createBlankDraft({
                  payloadData,
                  draftType: type
                })
              );
            }
            removeLoader();
          },
          (err) => {
            console.error('Error while fetching document details: ', err);
            removeLoader();
          }
        );
      }
    }
  };

  const getIsReadOnly = (docClicked: any) => {
    let isReadOnly = false;

    if (
      getLinkedSalesInvoices(docClicked)?.length ||
      docClicked.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED
    ) {
      isReadOnly = true;
    }

    if (
      docClicked.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP ||
      docClicked.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
    ) {
      isReadOnly = true;
    }

    // if (docClicked.reservedStock) {
    //   isReadOnly = true;
    // }
    let res = Utility.validationForClosedDate(docClicked);
    if (res.isDisable) {
      isReadOnly = res.isDisable;
    }
    if (docClicked.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
      isReadOnly = true;
    }

    return isReadOnly;
  };

  const onSalesOrderEdit = (data: any, documentMode: DOCUMENT_MODE) => {
    if (!data.rowData.openingSalesOrder) {
      const rowData = data.rowData;
      if (rowData.isDraftDocument) {
        const draftId = rowData.id;
        RouteManager.navigateToPage(PAGE_ROUTES.SALES_ORDERS + `/${draftId}`);
      } else {
        const salesOrderInStore: any[] = salesOrdersData.content;
        const salesOrderClicked = salesOrderInStore.find(
          (x) => x.documentSequenceCode === data.rowData.documentSequenceCode
        );

        if (!salesOrderClicked?.salesOrderCode) {
          showAlert('', 'Requested sales order cannot be opened');
        } else if (props.listByContact) {
          directOpenDocument(salesOrderClicked.salesOrderCode);
        } else {
          history.push(
            PAGE_ROUTES.SALES_ORDERS + `/${salesOrderClicked.salesOrderCode}`,
            {
              documentMode: documentMode
            }
          );
        }
      }
    } else {
      showAlert('', 'Opening sales order cannot be opened');
    }
  };

  const columnDelete = (data: ColumnDeleteType) => {
    const [identifier, customFieldCode, customFieldId] =
      data.columnData.columnCode.split('_');
    if (
      !isNaN(Number(customFieldId)) &&
      data.columnData.id &&
      columnConfigTableId
    ) {
      CustomFieldService.deleteCustomField(
        Number(customFieldId),
        data,
        columnConfigTableId,
        columnConfig
      )
        .then((res) => {
          const data: any = res;
          const newColumnConfigs: IColumn[] = data.config;
          // dispatch(
          //   addSalesOrderColumnConfig({
          //     tableId: columnConfigTableId,
          //     config: newColumnConfigs
          //   })
          // );
        })
        .catch((err) => {
          dispatch(
            addSalesOrderColumnConfig({
              tableId: columnConfigTableId,
              config: columnConfig
            })
          );
          console.error(err, 'error');
        });
    }
  };

  const onColumnUpdated = async (data: any) => {
    const { columnData } = data;
    const columnCode = columnData.columnCode as string;
    let columnIndex = columnConfig.findIndex(
      (column: any) => column.columnCode === columnCode
    );
    if (typeof columnData.id !== 'undefined') {
      const isCustomField = columnCode.startsWith('custom_');
      if (isCustomField && columnData.name !== columnConfig[columnIndex].name) {
        try {
          const customFieldId = columnCode.split('_')[2];
          if (customFieldId) {
            const customFieldResponse =
              await CustomFieldService.getCustomFieldById(+customFieldId);
            if (customFieldResponse) {
              let customField = customFieldResponse;
              customField = {
                ...customField,
                label: columnData.name
              };

              const updatedCustomField =
                await CustomFieldService.updateCustomField(
                  +customFieldId,
                  customField,
                  columnConfigTableId
                );
              if (updatedCustomField) {
                dispatch(
                  addSalesOrderColumnConfig({
                    tableId: columnConfigTableId as string,
                    config: updatedCustomField.newColumnConfig
                  })
                );
              }
            }
          } else {
            console.error('Error updating custom field');
          }
        } catch (err) {
          console.error('Error fetching custom field: ', err);
        }
      } else {
        updateColumnInfoAndStore(columnIndex, columnData);
      }
    }
  };

  const updateColumnInfoAndStore = (columnIndex: number, columnData: any) => {
    updateColumnInfo(columnConfigTableId as string, columnData);
    const currentColumnsConfig = [...columnConfig];
    currentColumnsConfig[columnIndex] = columnData;
    dispatch(updateColumnConfig(currentColumnsConfig));
  };

  const onColumnShifted = (data: IColumn[]) => {
    const updatedConfigArray = data.filter((x) => typeof x !== 'undefined');
    const columnIdArray = updatedConfigArray.map(
      (config) => config.id
    ) as string[];
    updateColumnShift(columnConfigTableId as string, columnIdArray);
    dispatch(updateColumnConfig(updatedConfigArray));
  };

  const onAddCustomFieldClicked = () => {
    const module = MODULES_NAME.SALESORDER;
    showAddCustomField(
      {
        module: module,
        forDocument: true,
        columnConfig: columnConfig,
        tableId: columnConfigTableId
      },
      (newCustomField) => {
        loadSalesOrders({});
      }
    );
  };

  const sortSalesOrders = (data: any) => {
    let oldConfig: any = SalesOrderService.apiConfig;
    let config: SalesOrderAPIConfig = {
      ...SalesOrderService.apiConfig,
      Query:
        `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}` +
        (oldConfig.WithoutFilter ? `,${oldConfig.WithoutFilter}` : ''),
      QueryParam: `&sort=${data.columnData.key}&sortDir=${data.order}&${
        oldConfig.WithFilter ?? ''
      }`,
      Sort: data.columnData.key,
      SortDir: data.order
    };
    if (data.columnData.key === 'totalAmount') {
      config = {
        ...SalesOrderService.apiConfig,
        Query:
          `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}` +
          (oldConfig.WithoutFilter ? `,${oldConfig.WithoutFilter}` : ''),
        QueryParam: `&sort=totalAmountInBaseCurrency&sortDir=${data.order}&${
          oldConfig.WithFilter ?? ''
        }`,
        Sort: 'totalAmountInBaseCurrency',
        SortDir: data.order
      };
    }
    loadSalesOrders(config);
  };

  const dataHasReservedDocuments = (selectedDate: any[]) => {
    return selectedDate.some(
      (obj) => !obj.isDraftDocument && obj.reservedStock
    );
  };

  const handleBulkDelete = async (selectedData: any[]) => {
    setUpdating(true);
    const drafts = selectedData.filter((data: any) => data.isDraftDocument);
    const salesorders = selectedData.filter(
      (data: any) => !data.isDraftDocument
    );
    if (drafts.length) {
      const draftIds = drafts.map((draft) => draft.id);
      try {
        await dispatch(
          bulkDeleteDrafts({
            recordIds: draftIds,
            tableId: draftsTableId
          })
        );
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
      } catch (err) {
        console.error('Error deleting draft sales orders: ', err);
      }
    }
    if (salesorders.length) {
      const filteredSalesOrders: any = salesorders.filter(
        (salesorder) => !salesorder.ewayGenerated
      );
      try {
        const bulkDeleteResponse = await SalesOrderService.bulkDelete(
          filteredSalesOrders.map((salesorder: any) => salesorder.id)
        );
        if (bulkDeleteResponse.length) {
          if (bulkDeleteResponse.some((resp) => resp.httpStatus !== 'OK')) {
            const documentNumbers = bulkDeleteResponse
              .filter((resp) => resp.httpStatus !== 'OK')
              .map((resp) => `<li>${resp.documentSequenceCode}</li>`);
            showAlert(
              'Bulk Delete',
              `Following documents are already in use, So cannot be deleted:<ul>${documentNumbers.join(
                ''
              )}</ul>`
            );
          }
          loadSalesOrders({});
        }
      } catch (err) {
        showAlert('Error!', 'Error while bulk deleting sales orders');
        loadSalesOrders({});
      }
    }
    setUpdating(false);
  };

  const loadLocalSODetailsForEdit = (
    salesOrderDetails: any,
    popupActionType: POPUP_CLICK_TYPE = POPUP_CLICK_TYPE.OPEN_CONVERT_SO_OPTIONS
  ) => {
    const salesOrderDetailsData: any =
      getUpdatedSalesOrderObject(salesOrderDetails);
    setEditableSalesOrder(salesOrderDetailsData);

    switch (popupActionType) {
      case POPUP_CLICK_TYPE.OPEN_CONVERT_SO_OPTIONS:
        if (
          salesOrderDetailsData.fulfillmentStatus ===
          FULFILLMENT_STATUS.FULLY_FULFILLED
        ) {
          showLoader('Creating invoice...');
          convertToInvoice(salesOrderDetailsData);
        } else {
          setShowConversionPopup(true);
        }
        break;
    }
  };

  const convertToInvoice = (salesOrderToConvert: SalesOrder) => {
    let selectedSO: SalesOrder = {
      ...salesOrderToConvert,
      entityId: salesOrderToConvert.id ? salesOrderToConvert.id : undefined,
      documentType: DOC_TYPE.SALES_ORDER,
      recurring: false,
      recurringActivated: false,
      backOrder: false,
      documentCode: salesOrderToConvert.salesOrderCode,
      currency: salesOrderToConvert.currency,
      currencyCode: salesOrderToConvert.currency,
      fulfillmentStatus:
        salesOrderToConvert.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: salesOrderToConvert.fulfillmentType,
      fulfillmentDate: salesOrderToConvert.fulfillmentDate,
      status: !salesOrderToConvert.id
        ? DOCUMENT_STATUS.OPEN
        : salesOrderToConvert.status,
      items: salesOrderToConvert.salesOrderItems?.map((item) => {
        return {
          ...item,
          documentItemCode: item.salesOrderItemCode
        };
      }),
      linkedSalesInvoiceDocuments:
        salesOrderToConvert.linkedSalesInvoiceDocuments,
      linkedSalesInvoices: salesOrderToConvert.linkedSalesInvoices,
      processedInPPS: salesOrderToConvert.processedInPPS,
      reservedStock: salesOrderToConvert.reservedStock
    };

    let invoice = {
      ...InvoiceInitialState,
      ...selectedSO
    };
    let linkedDocuments = invoice.linkedDocuments;
    invoice = {
      ...invoice,
      documentType: DOC_TYPE.INVOICE,
      purchaseOrderRefNo: invoice.poNumber,
      orderReference: invoice?.poNumber,
      openingInvoice: invoice.openingInvoice || false,
      fulfillmentStatus:
        invoice.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
      fulfillmentType: invoice.fulfillmentType,
      documentDate: invoice.salesOrderDate
        ? invoice.salesOrderDate
        : invoice.documentDate,
      validTillDate: invoice.salesInvoiceDueDate
        ? invoice.salesInvoiceDueDate
        : invoice.validTillDate,
      fulfillmentDate: invoice.fulfillmentDate,
      status: !invoice.id ? DOCUMENT_STATUS.OPEN : invoice.status,
      backOrder: invoice.backOrder ? invoice.backOrder : false,
      paymentStatus: invoice.paymentStatus,
      taxInvoiceNo: invoice.taxInvoiceNo,
      paymentInformation: invoice.paymentInformation
        ? invoice.paymentInformation
        : null,
      whtRate:
        invoice.whtRate && invoice.whtRate !== null ? invoice.whtRate : 0,
      einvoiceInfoIndia: invoice.einvoiceInfoIndia,
      einvoiceInfoIndiaCancel: invoice.einvoiceInfoIndiaCancel,
      isCancelEinvoice: invoice.isCancelEinvoice,
      reservedStock: invoice.reservedStock ? invoice.reservedStock : false,
      salesInvoiceCode: invoice.documentCode,
      salesInvoiceDueDate: invoice.validTillDate,
      salesInvoiceDate: invoice.documentDate,
      shipByDate: invoice.fulfillmentDate,
      autoFulfill: false,
      attachments: invoice.entityId ? invoice.attachments : [],
      attachmentIds: invoice.entityId ? invoice.attachments?.map(Number) : [],
      contact: {
        ...invoice.contact,
        ...invoice.contactDto,
        address: Utility.getStringAddress(invoice.contactDto)
      },
      salesInvoiceItems: invoice.items?.map((item) => {
        return {
          ...item,
          id: undefined,
          documentItemCode: undefined,
          documentSequenceCode: null,
          linkedQuoteItem: item.id,
          unitPriceGstInclusive: invoice.unitPriceGstInclusive,
          exchangeRate: invoice.exchangeRate,
          taxDetails: item.taxDetails.map((tax: any) => {
            const taxItem = { ...tax };
            delete taxItem.id;
            return taxItem;
          }),
          nonEditableColumns:
            item.type === PRODUCT_TYPE.NON_TRACKED &&
            item.product?.stockUom === UOM_NA_ID
              ? ['productQuantity']
              : []
        };
      }),
      fulfillmentComplete: false
    };

    if (
      selectedSO.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP &&
      linkedDocuments &&
      linkedDocuments.length > 0
    ) {
      invoice.linkedDocuments = [
        ...linkedDocuments,
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ];
    } else {
      invoice.linkedDocuments = [
        {
          documentCode: selectedSO.salesOrderCode as string,
          documentType: selectedSO.documentType,
          documentSequenceCode: selectedSO.documentSequenceCode as string
        }
      ];
    }
    if (
      !Utility.isEmpty(selectedSO.linkedQuotationDocuments) &&
      !Utility.isEmpty(invoice.linkedDocuments) &&
      selectedSO?.linkedQuotationDocuments != undefined
    ) {
      invoice.linkedDocuments = [
        ...invoice.linkedDocuments,
        {
          documentCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentCode as string,
          documentType: selectedSO?.linkedQuotationDocuments?.[0]?.documentType,
          documentSequenceCode: selectedSO?.linkedQuotationDocuments?.[0]
            ?.documentSequenceCode as string
        }
      ];
    }

    const {
      contactDto,
      items,
      salesOrderCode,
      salesOrderItems,
      documentSequenceCode,
      sequenceFormat,
      ...invoiceToSave
    } = invoice;

    if (!Utility.isEmpty(invoiceToSave)) {
      let payloadData: any = {
        type: LABELS.INVOICES,
        title: LABELS.INVOICES,
        isMaximized: true,
        isCenterAlign: true,
        populateFormData: invoiceToSave,
        isSaved: true,
        tableId: draftsTableId,
        columnConfig: draftsColumnConfig
      };

      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
    }
  };

  const onSalesOrderDelete = async (data: any[]) => {
    if (dataHasReservedDocuments(data)) {
      showAlert('', "You can't delete document(s) having reserved quantity");
      return;
    }

    if (data?.length > 1) {
      handleBulkDelete(data);
      return;
    }
    const rowData = data[0];
    if (!Utility.isEmpty(rowData)) {
      setUpdating(true);

      if (rowData.isDraftDocument) {
        try {
          await dispatch(
            deleteDrafts({
              recordId: rowData.id,
              tableId: draftsTableId
            })
          );
          if (
            tenantInfo.additionalSettings?.RATE_ANALYSIS?.ENABLE_FOR_SALES_ORDER
          ) {
            RateAnalysisService.delteDraftRateAnalysis(rowData.id);
          }
          await dispatch(
            fetchDrafts({
              tableId: draftsTableId,
              isSaveColumnId: isSavedColumnId,
              draftTypeColId: draftTypeColId,
              draftTypeColValue: LABELS.SALES_ORDER
            })
          );
          setUpdating(false);
        } catch (err: any) {
          console.error('Error deleting draft or fetching list: ', err);
          setUpdating(false);
        }
      } else {
        const salesOrderId: number = rowData.id;
        const documentSequenceCode = rowData.documentSequenceCode;
        SalesOrderService.deleteSalesOrder(salesOrderId).then(
          (res) => {
            loadSalesOrders({});
          },
          (err: any) => {
            setUpdating(false);
            if (
              err?.data?.code === 406 &&
              err?.data?.httpStatus === 'NOT_ACCEPTABLE'
            ) {
              showAlert(
                '',
                `Sales Order <b>${
                  documentSequenceCode ? documentSequenceCode : ''
                }</b> is already in use, so cannot be deleted.`
              );
            } else {
              showAlert('Error!', 'Error deleting sales order');
              console.error('Error deleting sales order: ', err.data);
            }
          }
        );
      }
    }
  };

  const showExportOptionsList = () => {
    return (
      <DKListPicker
        data={EXPORT_FILE_TYPE}
        className="mr-l shadow-m absolute z-10"
        style={{ width: 81 }}
        onSelect={(index: any, value: any) => {
          if (index === 0) {
            exportSalesOrder(EXPORTED_FILES_TYPE_EXTENSION.PDF);
          } else if (index === 1) {
            exportSalesOrder(EXPORTED_FILES_TYPE_EXTENSION.XLS);
          } else {
            exportSalesOrder(EXPORTED_FILES_TYPE_EXTENSION.CSV);
          }
        }}
        onClose={() => {
          setShowExportOptions(false);
        }}
      />
    );
  };

  const exportSalesOrder = (fileFormat: EXPORTED_FILES_TYPE_EXTENSION) => {
    EximService.exportFiles(
      fileFormat,
      MODULES_NAME.SALES_ORDER_EXPORT,
      SalesOrderService.apiConfig
    )
      .then((res) => {
        removeLoader();
        setShowExportOptions(false);
      })
      .catch((err) => {
        removeLoader();
        console.error('Error exporting invoice: ', err);
        showAlert('Error', 'Unable to export invoice');
      });
  };

  const getDateRangeString = () => {
    if (filterDates) {
      return (
        DateFormatService.getFormattedDateString(
          filterDates.startDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        ) +
        ' to ' +
        DateFormatService.getFormattedDateString(
          filterDates.endDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD']
        )
      );
    } else {
      return '';
    }
  };

  const getDateRangePicker = () => {
    return (
      <div
        className={`position-absolute bg-white z-10 top-12 ${
          isDesktop ? 'right-56' : ''
        }`}
      >
        <ResponsiveDateRangePicker
          className="border shadow "
          isDateRange={true}
          selectedDate={new Date()}
          showPresetList={true}
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={'rgb(56, 79, 190)'}
          startDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedStartDate={DateFormatService.getDateFromStr(
            filterDates.startDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          selectedEndDate={DateFormatService.getDateFromStr(
            filterDates.endDate,
            BOOKS_DATE_FORMAT['YYYY-MM-DD']
          )}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                startDate: DateFormatService.getDateStrFromDate(
                  startDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                ),
                endDate: DateFormatService.getDateStrFromDate(
                  endDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              });

              SessionStorageService.saveConfigToStorage(
                MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
                DOC_TYPE.SALES_ORDER,
                {
                  [FILTER_DATE]: {
                    start: startDate,
                    end: endDate
                  }
                }
              );
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const importButtonClick = () => {
    RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.SALES_ORDER);
  };

  const addRecordClicked = async () => {
    if (props.listByContact && props.contact?.status === STATUS_TYPE.INACTIVE) {
      showAlert('Operation Not allowed', 'Selected Contact is Inactive');
      setIsOpeningForm(false);
      return;
    }

    setIsOpeningForm(true);
    if (contactIdInURL || props.listByContact) {
      const contactId = contactIdInURL || docCodeInURL;
      ContactService.getContactDetailsById(contactId).then(
        (contactInfo: any) => {
          let docDetailsData = SalesOrderInitialState;
          docDetailsData = {
            ...docDetailsData,
            contact: contactInfo,
            contactCode: contactInfo.code,
            contactDto: contactInfo,
            needToUpdateAddresses: true
          };
          if (!Utility.isEmpty(docDetailsData)) {
            let payloadData: any = {
              type: LABELS.SALES_ORDER,
              title: LABELS.SALES_ORDER,
              isMaximized: true,
              isCenterAlign: true,
              populateFormData: docDetailsData,
              isSaved: true,
              tableId: draftsTableId,
              columnConfig: draftsColumnConfig
            };

            dispatch(
              createBlankDraft({ payloadData, draftType: DraftTypes.NEW })
            );
          }
          setIsOpeningForm(false);
        },
        (err: any) => {
          setIsOpeningForm(false);
          console.error('Error loading contact details: ', err);
        }
      );
    } else {
      await dispatch(
        createBlankDraft({
          payloadData: {
            title: LABELS.SALES_ORDER,
            type: LABELS.SALES_ORDER,
            tableId: draftsTableId,
            columnConfig: draftsColumnConfig,
            isCenterAlign: true
          },
          draftType: DraftTypes.NEW
        })
      );
      setIsOpeningForm(false);
    }
  };

  const getHeaderAction = () => {
    let buttons: any[] = [];

    if (checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE)) {
      buttons.push({
        title: isDesktop ? `+ New Sales Order` : 'New',
        className: 'bg-button text-white ml-r',
        onClick: async () => {
          if (!isOpeningForm) {
            addRecordClicked();
          }
        }
      });
    }

    /* ********** SALES ORDERS COLUMNS DISPLAY TOGGLE ********** */
    buttons.push(COLUMN_VISIBILITY_BUTTON_CONFIG);

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EXPORT) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.EXPORT,
        { ...editableSalesOrder },
        DOC_TYPE.SALES_ORDER
      )
    ) {
      buttons.push({
        className: 'border-m bg-white ml-r position-relative',
        icon: DKIcons.ic_export,
        onClick: () => {
          setShowExportOptions(true);
        }
      });
    }

    if (
      checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.IMPORT) &&
      SellConfig.showActionButton(
        SELL_CONFIG_BTN.IMPORT,
        { ...editableSalesOrder },
        DOC_TYPE.SALES_ORDER
      ) &&
      Utility.isSupportedCountriesForImport(tenantInfo.country) &&
      !props.listByContact
    ) {
      buttons.push({
        icon: DKIcons.ic_import,
        className: 'border-m bg-white ml-r',
        onClick: () => {
          setShowImportOptions(true);
        }
      });
    }
    buttons.push({
      icon: DKIcons.ic_settings,
      className: 'border-m bg-white ml-r settings-btn display-only-web',
      onClick: () => {
        setShowSettingsPopup((prevValue) => !prevValue);
      }
    });

    buttons.push({
      title: isDesktop && !isTabletView() ? getDateRangeString() : '',
      className: 'border-m bg-white ml-r position-relative',
      icon: DKIcons.ic_calendar,
      onClick: () => {
        setShowHideCalendar(true);
      }
    });
    return buttons;
  };

  const showImportOption = () => {
    return (
      <DKListPicker
        data={IMPORT_OPTIONS}
        className="shadow-m absolute z-10"
        width={100}
        onSelect={(index: any, value: any) => {
          switch (value) {
            case 'Sales Orders':
              RouteManager.navigateToPage(IMPORT_CONSTANTS.ROUTE.SALES_ORDER);
              break;
            case 'Fulfillment':
              RouteManager.navigateToPage(
                IMPORT_CONSTANTS.ROUTE.FULFILLMENT_SO
              );
              break;

            default:
              break;
          }
        }}
        onClose={() => {
          setShowImportOptions(false);
        }}
      />
    );
  };

  const removeNonColumnFields = (queries: any[]) => {
    const nonColumnFields = ['product', 'contact'];
    let filteredQueries = queries?.filter(
      (data) => !nonColumnFields.includes(data?.key)
    );

    return filteredQueries;
  };

  const getQueryForNonColumnField = (queryString: string, queries: any[]) => {
    let nonColumnFieldQueries = '';
    nonColumnFieldQueries = queryString ?? '';
    queries.map((query: any) => {
      if (query.key === 'product') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries +
            `,salesOrderItems.productCode=${query?.value?.productId}`
          : `salesOrderItems.productCode=${query?.value?.productId}`;
      }
      if (query.key === 'contact') {
        nonColumnFieldQueries = !Utility.isEmpty(nonColumnFieldQueries)
          ? nonColumnFieldQueries + `,contactCode=${query?.value?.code}`
          : `contactCode=${query?.value?.code}`;
      }
    });

    return nonColumnFieldQueries;
  };

  const getDraftFilterQuery = (queries: any) => {
    let draftFilter: any[] = [];
    queries?.map((query: any) => {
      if (query?.key === 'product') {
        const row = {
          colId: `${payloadColumn}.salesOrderItems.productCode`,
          opr: 'is',
          value: query?.value?.productId?.toString() ?? ''
        };
        draftFilter.push(row);
      }
      if (query?.key === 'contact') {
        const row = {
          colId: `${payloadColumn}.contactCode`,
          opr: 'is',
          value: query?.value?.code?.toString() ?? ''
        };
        draftFilter.push(row);
      }
    });

    return draftFilter;
  };

  const onFilter = async (updatedList: any[], queries: any[]) => {
    if (queries.length === 0) {
      const config: SalesOrderAPIConfig = {
        ...SalesOrderService.apiConfig,
        Query: `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}`,
        QueryParam: `&sort=salesOrderDate&sortDir=DESC`,
        Page: 0
      };
      setFilterDataInStore(
        { ...config },
        salesOrderFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    } else {
      const fileredQueries = removeNonColumnFields(queries);

      let filteredQueryList = Utility.isEmpty(fileredQueries)
        ? []
        : Utility.getFilterParameters(
            fileredQueries,
            REMOTE_CONFIG_TABLES.SALES_ORDER
          );
      const contactFilter = filteredQueryList.find(
        (item) => item[FILTER_KEYS.CONTACT_CODE]
      );

      const productFilter = filteredQueryList.find(
        (item) => item['salesOrderItems.documentSequenceCode']
      );

      const approvalStatusFilter = filteredQueryList.find(
        (item) => item[FILTER_KEYS.APPROVAL_STATUS]
      );

      if (contactFilter) {
        const currentValue = contactFilter[FILTER_KEYS.CONTACT_CODE].value;

        try {
          const contacts = await ContactService.getContactDataForFilter(
            currentValue
          );

          const contactCodes = contacts.content
            .map((contact: any) => contact?.code)
            .join(',');
          contactFilter[FILTER_KEYS.CONTACT_CODE].value = contactCodes;
        } catch (error) {}
      }

      if (productFilter) {
        const currentValue =
          productFilter['salesOrderItems.documentSequenceCode'].value;

        try {
          const products = await ProductService.getProductBreif(currentValue);

          const productCode = products.map((product) => product?.pid).join(',');
          productFilter['salesOrderItems.documentSequenceCode'].value =
            productCode;
        } catch (error) {}
      }

      if (approvalStatusFilter) {
        const value = approvalStatusFilter?.approvalStatus?.value;
        if (value && 'APPROVED' !== value) {
          const approvalLevelSplit = value.split('_');
          const level = approvalLevelSplit?.[3];
          const payload: any = Utility.getKeyOfColumn(
            draftsColumnConfig,
            COLUMN_CODE.DRAFTS.Payload
          );
          const payloadKey = payload && payload.length ? payload[0].id : null;
          let draftSalesOrders = [];
          if (payloadKey) {
            draftSalesOrders = salesOrderDraftData?.map((data: any) => {
              if (data.cells[payloadKey]) {
                return {
                  ...Utility.parseDraftDocumentPayload(data.cells[payloadKey]),
                  id: data._id,
                  isDraftDocument: true,
                  createdBy: data?.createdBy,
                  draftCode: data.cells.documentSequenceCode
                };
              }
            });
            if (draftSalesOrders) {
              draftSalesOrders = draftSalesOrders?.filter((ele: any) => {
                if (!Utility.isEmpty(searchTerm)) {
                  let safePattern = Utility.escapeRegex(searchTerm);
                  return (
                    ele.approvalStatus !== APPROVAL_STATUS.APPROVED &&
                    ele.draftCode?.search(new RegExp(safePattern, 'i')) >= 0
                  );
                } else {
                  return ele.approvalStatus !== APPROVAL_STATUS.APPROVED;
                }
              });
            }
          }
          if (value.includes('PENDING') || value.includes('REJECTED')) {
            let status = value.includes('REJECTED')
              ? APPROVAL_STATUS['REJECTED']
              : APPROVAL_STATUS['PENDING_FOR_APPROVAL'];

            draftSalesOrders =
              draftSalesOrders?.filter(
                (ele: any) =>
                  ele?.multiApprovalDetails?.currentLevel === Number(level) &&
                  ele?.approvalStatus === status
              ) ?? [];

            const rowData = getRowData(draftSalesOrders ?? []);

            const passingData = {
              ...salesOrders,
              rowData: rowData,
              filter: filters,
              originalData: rowData
            };
            setSalesOrders(passingData);
            return;
          }
        }
      }

      const list = Utility.getFilteredQueryList(filteredQueryList);
      let withoutFilter, withFilter;
      if (
        list.newFilteredQueyListArr &&
        list.newFilteredQueyListArr.length > 0
      ) {
        withoutFilter = Utility.getQueryString(list.newFilteredQueyListArr);
      }
      withoutFilter = getQueryForNonColumnField(withoutFilter, queries);
      if (list.customField && list.customField.length > 0) {
        assignProductModule('CLASS', 'CLASS', list?.customField);
        assignProductModule('CLASS', 'LOCATION', list?.customField);
        withFilter = Utility.getQueryString(list.customField);
      }

      const draftFilter = getDraftFilterQuery(queries);
      if (!Utility.isEmpty(draftFilter)) {
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER,
            filter: draftFilter
          })
        );
        setWasDraftFilterApply(true);
      } else if (wasDraftFilterApply) {
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.SALES_ORDER
          })
        );
        setWasDraftFilterApply(false);
      }

      const config: SalesOrderAPIConfig = {
        ...SalesOrderService.apiConfig,
        Query:
          `salesOrderDate>=${filterDates.startDate},salesOrderDate<=${filterDates.endDate}` +
          (withoutFilter ? `,${withoutFilter}` : ''),
        QueryParam: `&sort=${SalesOrderService.apiConfig.Sort}&sortDir=${SalesOrderService.apiConfig.SortDir}&${withFilter}`,
        Page: 0,
        WithFilter: withFilter,
        WithoutFilter: withoutFilter
      };
      SalesOrderService.apiConfig = config;

      setFilterDataInStore(
        { ...config },
        salesOrderFilterData?.SearchTerm,
        deepClone(queries)
      );
      setFilters(queries);
    }
  };

  const assignProductModule = (key: string, label: string, list: any) => {
    if (tenantInfo.additionalSettings?.[key]?.assignClasses === 'ROW') {
      const cfInAvailableCFs = availableCustomFields?.content?.find(
        (field: any) => field.label === label
      );
      list?.forEach((field: any) => {
        field?.customfield?.forEach((data: any) => {
          if (data?.code === cfInAvailableCFs?.code) {
            if (!data?.module.includes('PRODUCT')) {
              data.module.push('PRODUCT');
            }
          }
        });
      });
    }
  };

  const showMainDocumentGridSettings = () => {
    return (
      <MainDocumentGridSettings
        showTotalInBaseCurrency={showTotalInBaseCurrency}
        onClose={() => setShowSettingsPopup(false)}
        onShowTotalInBaseCurrencyChange={(value: boolean) => {
          setShowTotalInBaseCurrency((prevValue) => {
            SessionStorageService.saveConfigToStorage(
              MODULE_NAME_FOR_STORAGE_UTILITY.SELL,
              DOC_TYPE.SALES_ORDER,
              {
                [SHOW_TOTAL_IN_BASE_CURRENCY]: value
              }
            );
            return value;
          });
        }}
      />
    );
  };

  const setSOForPrint = (rowData: any) => {
    const docsInStore: any[] = salesOrdersData.content;
    const soClicked = docsInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (soClicked.salesOrderCode) {
      getPrintPreview(TEMPLATE_CATEGORY.SALES_ORDER, {
        ...soClicked,
        contactCode: soClicked.contactCode,
        documentCode: soClicked.salesOrderCode,
        documentType: DOC_TYPE.SALES_ORDER
      });
    }
  };

  const getRateAnalysis = (rowData: any) => {
    if (!Utility.isEmpty(rowData)) {
      let detail = {
        documentCode:
          rowData.rowType == 'draft' || rowData.isDraftDocument
            ? rowData.draftCode
            : rowData.salesOrderCode,
        documentType: 'SALES_ORDER',
        draftId: rowData.id,
        isDraft:
          rowData.rowType == 'draft' || rowData.isDraftDocument ? true : false,
        draftCode: rowData.draftCode
      };
      setRateAnalysisData(detail);
      setShowRateAnalysisForm(true);
    }
  };

  const onEmailCallBack = (data: any, id: any) => {
    let emailIds = deepClone(emailTriggerIds);
    let soIds = emailIds[DOC_TYPE.SALES_ORDER] ?? [];
    soIds.push(id);
    emailIds[DOC_TYPE.SALES_ORDER] = soIds;
    dispatch(setEmailTriggerIds(emailIds));
  };

  const setSOForEmail = (rowData: any) => {
    const docsInStore: any[] = salesOrdersData.content;
    const soClicked = docsInStore.find(
      (x) => x.documentSequenceCode === rowData.documentSequenceCode
    );
    if (soClicked.salesOrderCode) {
      getEmailPopup(
        TEMPLATE_CATEGORY.SALES_ORDER,
        {
          ...soClicked,
          contactCode: soClicked.contactCode,
          documentCode: soClicked.salesOrderCode,
          documentType: DOC_TYPE.SALES_ORDER
        },
        salesOrderEmailTemplateConfig,
        (data: any) => onEmailCallBack(data, soClicked.salesOrderCode)
      );
    }
  };

  const loadSOForCopy = (soDetails: any) => {
    const docsInStore: any[] = salesOrdersData.content;
    const soClicked = docsInStore.find(
      (x) => x.documentSequenceCode === soDetails.documentSequenceCode
    );
    if (soClicked.salesOrderCode) {
      loadSalesOrderDetailsForEdit(
        soClicked,
        POPUP_CLICK_TYPE.COPY_SALES_ORDER
      );
    }
  };

  const openDesigner = (rowData: any) => {
    const docsInStore: any[] = salesOrdersData.content;
    const docId = rowData.id;
    const data = docsInStore.filter((doc) => doc.id === docId)[0];
    if (data) {
      let docCode = data.salesOrderCode;
      let docType = DOC_TYPE.SALES_ORDER;
      showLoader();
      PrintService.getDocumentPrintUUID(docCode, docType)
        .then(
          (response: any) => {
            removeLoader();
            const printExternalAppUrl = [
              ApiConstants.PRODUCT_URL_DOCUMENT_BUILDER,
              response.code
            ].join('/');
            window.open(printExternalAppUrl, '_blank');
          },
          (err: any) => {
            removeLoader();
            console.error('Failed to load the designer code', err);
          }
        )
        .catch((err: any) => {
          removeLoader();
          console.error('Failed to load the designer code', err);
        });
    }
  };
  const getFilteredColumns = (columns: any[]) => {
    const columnCode: any[] = [];
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.CONTACTS.VIEW)) {
      columnCode.push(COLUMN_CODE.SALES_ORDER.Contact);
    }
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW_PRICE)) {
      columnCode.push(COLUMN_CODE.SALES_ORDER.TotalAmount);
    }
    if (!checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW_PRICE)) {
      columnCode.push(COLUMN_CODE.SALES_ORDER.BalanceDue);
    }
    columnCode.push(COLUMN_CODE.SALES_ORDER.ProductCode);

    if (Utility.isNotEmpty(columnCode)) {
      columns = columns?.filter((col) => !columnCode.includes(col.columnCode));
    }
    return columns;
  };

  return (
    <div
      className="column parent-width flex-1 main-holder-padding padding-bottom-50"
      style={
        props.listByContact
          ? {
              paddingLeft: 4
            }
          : {}
      }
    >
      <div className="column parent-width mb-s position-relative flex-1">
        <div
          className="position-absolute z-index-3"
          style={{ right: 0, top: 45 }}
        >
          {showSettingsPopup && showMainDocumentGridSettings()}
        </div>
        <div className="position-absolute" style={{ right: 196, top: 45 }}>
          {showExportOptions && showExportOptionsList()}
        </div>
        <div className="position-absolute" style={{ right: 270, top: 45 }}>
          {showImportOptions && showImportOption()}
        </div>
        {showHideCalendar && getDateRangePicker()}
        <DataGridHolder
          tableName={TABLES.SALES_ORDERS}
          displayTableName={TABLE_DISPLAY_NAME[TABLES.SALES_ORDERS]}
          filterTableName={REMOTE_CONFIG_TABLES.SALES_ORDER}
          gridIcon="📋"
          needBoldTheme={isDesktop}
          needBorder={true}
          needShadow={false}
          extraWidth={props.listByContact ? CONTACT_LEFT_PANEL_WIDTH : 0}
          onRowOpenClick={
            checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT)
              ? (data: any) => {
                  onSalesOrderEdit(data, DOCUMENT_MODE.EDIT);
                }
              : null
          }
          allowColumnSort={true}
          onSort={(data: any) => sortSalesOrders(data)}
          allowRowEdit={checkUserPermission(
            PERMISSIONS_BY_MODULE.SALES_ORDER.EDIT
          )}
          allowColumnEdit={true}
          allowRowAdd={true}
          allowColumnAdd={checkUserPermission(
            PERMISSIONS_BY_MODULE.SALES_ORDER.CREATE
          )}
          allowColumnDelete={GranularPermissionsHelper.hasPermissionFor(
            PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.DELETE
          )}
          allowColumnShift={true}
          allowColumnVisibilityToggle={true}
          allowSearch={!props.listByContact}
          allowFilter={true}
          onRowDelete={onSalesOrderDelete}
          onRowEdit={(data: any) => {
            onSalesOrderEdit(data, DOCUMENT_MODE.EDIT);
          }}
          refresh={true}
          onBulkConsolidate={(data: any) => {
            data = data.filter((item: any) =>
              Utility.isEmpty(item.linkedWorkOrderDocuments)
            );
            data = data?.map((itemData: any) => {
              return {
                ...itemData,
                selected: false
              };
            });
            setSelectedSalesOrder(data);
            setShowAddWorkOrderPopup(true);
            setConsolidateRows([]);
            setSelectedRows([]);
            setCurrentSelectedRows([]);
            setSalesOrders({
              ...salesOrders,
              rowData: salesOrders?.rowData?.map((itemRow: any) => {
                return {
                  ...itemRow,
                  selected: false
                };
              })
            });
          }}
          onBulkConvertWO={(data: any) => {
            // sorting on the basis of Date to serve in FIFO order
            data.sort(function (a: any, b: any) {
              let aCreatedDate = new Date(a.createdDate).getTime();
              let bCreatedDate = new Date(b.createdDate).getTime();
              return aCreatedDate - bCreatedDate;
            });
            setShowConvertWorkOrderPopup(true);
            setSelectedSalesOrder(data);
            setSelectedRows([]);
            setCurrentSelectedRows([]);
            setSalesOrders({
              ...salesOrders,
              rowData: salesOrders?.rowData?.map((itemRow: any) => {
                return {
                  ...itemRow,
                  selected: false
                };
              })
            });
            // data = data.filter((item: any) =>
            //   Utility.isEmpty(item.linkedWorkOrderDocuments)
            // );
            // data = data?.map((itemData: any) => {
            //   return {
            //     ...itemData,
            //     selected: false
            //   };
            // });
            // setSelectedSalesOrder(data);
            // setShowAddWorkOrderPopup(true);
            // setConsolidateRows([]);
            // setSelectedRows([]);
            // setCurrentSelectedRows([]);
            // setSalesOrders({
            //   ...salesOrders,
            //   rowData: salesOrders?.rowData?.map((itemRow: any) => {
            //     return {
            //       ...itemRow,
            //       selected: false
            //     };
            //   })
            // });
          }}
          headerButtons={getHeaderAction()}
          allowDataExport={checkUserPermission(
            PERMISSIONS_BY_MODULE.SALES_ORDER.EXPORT
          )}
          gridData={salesOrders}
          onSearch={(term: string) => {
            setSearchTerm(term);
          }}
          onColumnAddClick={
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CUSTOM_FIELDS.CREATE
            )
              ? onAddCustomFieldClicked
              : () => {}
          }
          onFilter={onFilter}
          filter={Utility.checkFilterTypeDate(salesOrders.filter)}
          onRowAdd={() => {}}
          onPaginationClick={(requestedPageNumber: number) => {
            const config: SalesOrderAPIConfig = {
              ...SalesOrderService.apiConfig,
              Page: requestedPageNumber - 1 // Books backend pagination offset is 0, so subtract 1
            };
            setFilterDataInStore(
              { ...config },
              salesOrderFilterData?.SearchTerm,
              deepClone(salesOrderFilterData?.queries)
            );
          }}
          currentPage={currentPage}
          totalPageCount={totalPageCount}
          onColumnDelete={columnDelete}
          onColumnUpdate={onColumnUpdated}
          onColumnShift={onColumnShifted}
          onRowUpdate={() => {}}
          onRowSelect={(selectedRows) => {
            const selectedRowIds =
              selectedRows?.map((row: any) => row.salesOrderCode) || [];
            setSelectedRows(selectedRowIds);
            setBulkFulfillmentRecords(selectedRowIds);
            setCurrentSelectedRows(selectedRows);
            updateBulkPrintRecord(selectedRows);
            setConsolidateRows([...selectedRows]);
          }}
          consolidateRows={consolidateRows}
          onBulkPrint={onBulkPrintlick}
          bulkPrintRows={bulkPrintRecordData}
          bulkFulfillmentRows={bulkFulfillmentRows}
          onBulkFulfillment={bulkFulfillment}
          onFulfillment={fulfillment}
          allowBulkOperation={true}
          contextMenu={[]}
          updating={updating}
          needNoDataView={
            !salesOrdersData.content?.length || !salesOrderDraftData?.length
          }
          noDataTitle="No sales orders found"
          noDataText="Create your first sales order now"
          searchBarConfig={{ searchText: searchTerm }}
          onRowClick={(data: any) => {}}
        />

        {showConversionPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title="Convert to Invoice"
            btnList={convertPopupBtnConfig}
            width={isDesktop ? '600px' : '90%'}
            height={'auto'}
            disableClickOutside={true}
            overflowVisible={true}
          >
            <SalesOrderToInvoicePopup
              salesOrderDocument={{ ...editableSalesOrder }}
              closePopup={() => {
                setShowConversionPopup(false);
              }}
            />
          </PopupWrapper>
        )}
        {showDropshipConfirmationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={localizedText('Fulfill Your Invoice')}
            width={!isDesktop ? '80%' : '460px'}
            height={'auto'}
            overflowVisible={true}
            disableClickOutside={true}
            btnList={fulfillmentConfirmationPopupBtnConfig}
          >
            <DropshipConfirmation
              documentDetails={{ ...editableSalesOrder }}
              fulfillmentType={editableSalesOrder?.fulfillmentType}
              passingInteraction={(
                callback: CallBackPayloadType,
                data: any
              ) => {
                setEditableSalesOrder(data);
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowDropshipConfirmationPopup(false);
              }}
              openFulfillmentPopup={() => {
                setShowDropshipConfirmationPopup(false);
                setShowFulfillmentConfirmationPopup(true);
              }}
              documentType={DOC_TYPE.SALES_ORDER}
            />
          </PopupWrapper>
        )}
        {showFulfillmentConfirmationPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={localizedText('Fulfill Your Sales Order')}
            width={!isDesktop ? '80%' : '460px'}
            height={'auto'}
            overflowVisible={true}
            disableClickOutside={true}
            btnList={fulfillmentConfirmationPopupBtnConfig}
          >
            <FulfillmentConfirmation
              documentDetails={{ ...editableSalesOrder }}
              fulfillmentType={editableSalesOrder?.fulfillmentType}
              passingInteraction={(
                callback: CallBackPayloadType,
                data: any
              ) => {
                setEditableSalesOrder(data);
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowFulfillmentPopup(false);
              }}
              documentType={DOC_TYPE.SALES_ORDER}
            />
          </PopupWrapper>
        )}

        {showFulfillmentPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={localizedText('Direct Fulfillment of Sales Order')}
            height={'auto'}
            minHeight={'50%'}
            width={!isDesktop ? '90%' : '100%'}
            disableClickOutside={true}
            btnList={fulfillmentPopupBtnConfig}
          >
            <Fulfillment
              documentDetails={{ ...editableSalesOrder }}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              closePopup={() => {
                setShowFulfillmentPopup(false);
              }}
              documentType={DOC_TYPE.SALES_ORDER}
              isFulfillmentForDropship={isFulfillmentForDropship}
            />
          </PopupWrapper>
        )}

        {showFulfillmentRecordsPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={fulfillmentType}
            width={!isDesktop ? '90%' : '65%'}
            btnList={fulfillmentRecordsBtnConfig}
            disableClickOutside={true}
            showScrollBar={true}
          >
            <FulfillmentRecords
              documentDetails={{ ...editableSalesOrder }}
              fulfillmentDetails={fulfillmentDetails}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              isDeleted={(val: boolean) => {
                if (val) {
                  loadSalesOrders({});
                }
              }}
              documentType={DOC_TYPE.SALES_ORDER}
            />
          </PopupWrapper>
        )}
        {showBulkFulfillmentPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={t(`INVOICES.DIALOG.BULK_FULFILMENT_OF_INVOICES.TITLE`)}
            height={'auto'}
            minHeight={'60%'}
            disableClickOutside={true}
            width={!isDesktop ? '80%' : '90%'}
            maxWidth={'1100px'}
            btnList={bulkFulfillmentPopupBtnConfig}
          >
            <BulkFulillment
              documentDetails={bulkFulfillmentDetails}
              passingInteraction={(callback: CallBackPayloadType) => {
                parentChildInteraction(callback);
              }}
              documentType={DOC_TYPE.SALES_ORDER}
              refreshFulfillmentDetails={() => {
                FulfillmentService.getInvoiceBulkFulfillmentDetails(
                  selectedRows
                ).then((data: any) => {
                  setBulkFulfillmentDetails(data);
                  setEditableSalesOrder(data[0]);
                  setShowBulkFulfillmentPopup(true);
                });
              }}
            />
          </PopupWrapper>
        )}
        {showAttachmentPopup && !Utility.isEmpty(editableSalesOrder) && (
          <Attachments
            documentDetails={{ ...editableSalesOrder }}
            documentType={DOC_TYPE.SALES_ORDER}
            onClose={(docId: any, updatedAttachments: any[]) => {
              const docList: any[] = salesOrdersData['content'];
              const docData = docList.filter((doc) => doc.id === docId)[0];
              let refreshList = false;
              if (
                docData.attachmentsWithLink?.length !==
                updatedAttachments?.length
              ) {
                refreshList = true;
              }
              if (!refreshList && docData) {
                for (let attachment of updatedAttachments) {
                  const existingAttachment = docData.attachmentsWithLink?.find(
                    (att: any) => att.attachmentId === attachment.attachmentId
                  );
                  if (Utility.isEmpty(existingAttachment)) {
                    refreshList = true;
                    break;
                  }
                }
              }
              if (refreshList) {
                loadSalesOrders({});
              }
              setShowAttachmentPopup(false);
            }}
          />
        )}
        {showDropShipWarningPopup && (
          <PopupWrapper
            clickAction={catchClicks}
            type={POPUP_TYPE.POPUP}
            title={t(`INVOICES.DIALOG.DROPSHIP.TITLE`)}
            btnList={dropShipButtonConfig}
            width={!isDesktop ? '80%' : '600px'}
            height={'auto'}
          >
            <DropshipWarning
              document={{ ...editableSalesOrder }}
              passingInteraction={(callback: CallBackPayloadType, data) => {
                parentChildInteraction(callback);
                setEditableSalesOrder(data);
              }}
              documentType={DOC_TYPE.SALES_ORDER}
            />
          </PopupWrapper>
        )}
        {showAddWorkOrderPopup && (
          <AddNewWorkOrder
            salesOrder={selectedSalesOrder}
            workOrder={selectedWO ?? null}
            convertWOData={convertWOData}
            continueInEditMode={(res: any, selectedTabIndex = 0) => {
              setShowAddWorkOrderPopup(false);
              let selectedWorkOrder = Array.isArray(res)
                ? res?.[selectedTabIndex]
                : res;
              setSelectedSalesOrder(null);
              setSelectedWO(selectedWorkOrder); // because in edit mode, WO document is no longer a sales order created document.
              setShowAddWorkOrderPopup(true);
            }}
            onClose={() => {
              setSelectedSalesOrder(null);
              setShowAddWorkOrderPopup(false);
            }}
            onSave={() => {
              setSelectedSalesOrder(null);
              setShowAddWorkOrderPopup(false);
              loadSalesOrders(salesOrderFilterData.config);
            }}
            onGotoClick={() => {
              setShowAddWorkOrderPopup(false);
              RouteManager.navigateToPage(PAGE_ROUTES.MRP_WORK_ORDER);
            }}
          />
        )}
        {showConvertWorkOrderPopup && (
          <ConvertWorkOrder
            soData={selectedSalesOrder}
            onConvert={(data: any) => {
              setConvertWOData(data);
              setSelectedSalesOrder(data);
              setShowConvertWorkOrderPopup(false);
              setShowAddWorkOrderPopup(true);
            }}
            onCancel={() => {
              setShowConvertWorkOrderPopup(false);
            }}
          />
        )}
        {detailsPopupData?.showDetailsOpener && (
          <DetailsOpener
            data={detailsPopupData}
            onCancel={() => {
              setDetailsPopupData({
                showDetailsOpener: false
              });
            }}
            isReadOnly={true}
          />
        )}
      </div>

      {showSalesReturnPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Sales Return'}
          height={'auto'}
          minHeight={'50%'}
          width={'100%'}
          disableClickOutside={true}
          btnList={salesReturnPopupBtnConfig}
        >
          <SalesReturnPopup
            documentDetails={{ ...editableSalesOrder }}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowSalesReturnPopup(false);
            }}
            isClosed={(val: boolean, data: any) => {
              if (val) {
                createDocForReturn(data);
              }
            }}
            documentType={DOC_TYPE.SALES_ORDER}
          />
        </PopupWrapper>
      )}
      {showSalesReturnRecordsPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Sales Returns Records'}
          width={'60%'}
          btnList={fulfillmentRecordsBtnConfig}
          disableClickOutside={true}
        >
          <SalesReturnRecords
            documentDetails={{ ...editableSalesOrder }}
            salesReturnsDetails={salesReturnsDetails}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isDeleted={(val: boolean) => {
              if (val) {
                loadSalesOrders({});
              }
            }}
            isClosed={(val: boolean) => {
              if (val) {
                setShowSalesReturnRecordsPopup(false);
              }
            }}
            documentType={DOC_TYPE.SALES_ORDER}
          />
        </PopupWrapper>
      )}
      {showExpenseButton && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Direct Expense'}
          btnList={directExpenseBtnConfig}
          height={'98%'}
          allowFullScreen
        >
          <ExpenseDepositForm
            formType={MODULES_NAME.EXPENSE}
            populateData={expenseData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            allowFullScreen
          />
        </PopupWrapper>
      )}
      {showCNAndDNInfo.showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Create Credit Note'}
          disableClickOutside={true}
          readOnly={false}
          btnList={CNBtnConfig}
          height={'98%'}
          allowFullScreen
        >
          <CreateAndEditNotes
            documentMode={DOCUMENT_MODE.NEW}
            readOnly={false}
            populateFormData={showCNAndDNInfo.data}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            notesType={showCNAndDNInfo.documentType}
            showLinkedDocPopup={(data: any) => {}}
            allowFullScreen
          />
        </PopupWrapper>
      )}
      {showRateAnalysisForm && rateAnalysisData && (
        <RateAnalysisForm
          data={rateAnalysisData}
          onCancel={() => {
            setShowRateAnalysisForm(false);
          }}
          onSave={() => {
            setShowRateAnalysisForm(false);
          }}
        />
      )}
      {showLinkRecordsPopup && (
        <LinkedRecordsPopup
          data={salesOrderLinkRecordsData}
          isDocumentEmailFlow={true}
          documentType={DOC_TYPE.SALES_ORDER}
          onCancel={() => setShowLinkRecordsPopup(false)}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
          isDeleted={(val: boolean) => {
            if (val) {
              loadSalesOrders({});
            }
          }}
        />
      )}
      {generateLabelPopup && (
        <GenerateLabelPopup
          onCancel={() => setGenerateLabelPopup(false)}
          documentType={LABEL_DOCUMENT_TYPE.SALES_ORDER}
          documentData={{ ...editableSalesOrder }}
        />
      )}
      {showApprovalOrRejectPopup && (
        <ApprovalOrReject
          data={approvalOrRejectData}
          actionData={{
            isBulkAction: false,
            action: onApproveDraft
          }}
          setShowApprovalOrRejectPopup={setShowApprovalOrRejectPopup}
          title={`${approvalOrRejectData.title} SalesOrder`}
        ></ApprovalOrReject>
      )}

      {showApprovalHistoryPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Approval/Rejection History'}
          height={'80%'}
          width={!isDesktop ? '80%' : '60%'}
          btnList={fulfillmentRecordsBtnConfig}
          disableClickOutside={true}
        >
          <ApprovalHistory
            documentDetails={{ ...editableSalesOrder }}
            approvalHistoryDetails={approvalHistoryData}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            isClosed={(val: boolean) => {
              if (val) {
                setShowApprovalHistoryPopup(false);
              }
            }}
            documentType={DOC_TYPE.SALES_ORDER}
          />
        </PopupWrapper>
      )}

      {viewReservedQuantityPopup && (
        <ViewAdvancedTrackingReservedData
          selectedRowData={selectedRowForReservedData}
          documentType={DOC_TYPE.SALES_ORDER}
          onCancel={() => {
            setViewReservedQuantityPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default SalesOrders;
