import {
  DKIcon,
  DKIcons,
  DKLabel,
  showToast,
  DKInput,
  INPUT_TYPE,
  DKDataGrid
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import ic_delivery from '../../Assets/Icons/ic_delivery.png';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE,
  MODULES_NAME,
  PAGE_SIZE,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  TRACKING_TYPE,
  QTY_ROUNDOFF_PRECISION,
  BOOKS_ADDRESS_TYPES,
  INPUT_VIEW_DIRECTION,
  STATUS_TYPE,
  CUSTOM_FIELD_TYPE,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { Address } from '../../Models/Address';
import { FulfillmentRecordProps } from '../../Models/Fulfillment';
import { BtnType, PopupClickActionType } from '../../Models/Interfaces';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  selectCustomFields,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import AttachmentService from '../../Services/Attachment';
import DateFormatService from '../../Services/DateFormat';
import NewReportService, { NewReportConfig } from '../../Services/DebitReport';
import Utility from '../../Utility/Utility';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import PopupWrapper from '../PopupWrapper';
import FulfillmentTable from './FulFillmentTable';
import ContactService from '../../Services/Contact';
import {
  COLUMN_CONFIGS_FOR_FF_RECORD,
  COLUMN_CONFIGS_FOR_PPS_RECORD,
  COLUMN_CONFIGS_FOR_RG
} from '../../Components/Mrp/Constants/MRPColumnConfigs';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';
import { localizedText } from '../../Services/Localization/Localization';

const FulfillmentRecordDetailWithTable: React.FC<FulfillmentRecordProps> = (
  props
) => {
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    props.fulfillmentDetails?.[0]
  );
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);
  const [res, setRes] = useState<any>({});
  const { t, i18n } = useTranslation();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [isVisibleState, setIsVisibleState] = useState(false);
  const [isSerial, setIsSerial] = useState<any>(false);
  const [trackingType, setTrackingType] = useState<any>('');
  const [contactDetails, setContactDetails] = useState<any>(null);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [isPPS, setIsPPS] = useState<any>(false);
  let module: any = MODULES_NAME.INVOICE;
  if (props.documentType === 'SALES_ORDER') {
    module = MODULES_NAME.SALESORDER;
  } else if (props.documentType === 'QUOTATION') {
    module = MODULES_NAME.QUOTATION;
  }
  const popupBtnConfig: BtnType[] = [
    {
      title: t(`PRICE_LIST.BUTTON.CANCEL`),
      class: 'border-m mr-s bg-white',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    }
  ];

  const lineItemsTable = useRef(null);
  const uoms = useAppSelector(selectUOMs);
  const [itemDetails, setItemDetails] = useState<any>();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [popupWidth, setPopupWidth] = useState(30);
  const [columnConfig, setColumnConfig] = useState<any>(
    COLUMN_CONFIGS_FOR_FF_RECORD
  );
  const [ppsColumnConfig, setPpsColumnConfig] = useState<any>(
    COLUMN_CONFIGS_FOR_PPS_RECORD
  );
  const [ppsGridRowData, setPpsGridRowData] = useState<any>([]);
  const [gridRowData, setGridRowData] = useState<any>([]);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let popupWidthCopy = popupWidth;
      tenantInfo?.additionalSettings?.ROW_RACK_BIN?.forEach((element: any) => {
        if (element.enable) {
          popupWidthCopy = popupWidthCopy + 10;
        }
      });
      setPopupWidth(popupWidthCopy);
    }
  }, [tenantInfo]);

  useEffect(() => {
    let newCols = getUpdatedColumnConfigs([...columnConfig]);
    let productCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );

    productCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      newItem = { ...newItem, editable: false };
      const newItemInExistingColConfig = newCols.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newCols.push({ ...newItem });
      }
    });
    newCols = newCols.filter((col: any) => !col.hidden);
    setColumnConfig([...newCols]);
  }, [selectCustomFieldsData]);

  const getUpdatedColumnConfigs = (configs: any[]) => {
    configs = configs.map((config: any) => {
      switch (config.key) {
        case 'products':
          config.renderer = ({ rowData }: any) => {
            return (
              <div className="column parent-width">
                <div className="row parent-width">
                  <DKLabel text={rowData?.products || ''} className="fs-m" />
                </div>
                <div className="row">
                  <DKLabel
                    text={rowData?.documentSequenceCode || ''}
                    className="text-gray fs-s"
                  />
                </div>
              </div>
            );
          };
          break;
      }
      return config;
    });

    return configs;
  };

  // useEffect(() => {
  //   if (fulfillmentData?.fulfillmentItems?.length) {
  //     setGridRowData(getRowData(fulfillmentData.fulfillmentItems));
  //   }
  // }, [fulfillmentData]);

  useEffect(() => {
    setFulfillmentData(props?.fulfillmentDetails?.[0]);
    if (props?.fulfillmentDetails?.[0]?.category === 'PPS Fulfillment') {
      setPpsGridRowData(
        getPpsRowData(props?.fulfillmentDetails?.[0]?.ppsDocumentItems)
      );
    } else {
      setGridRowData(
        getRowData(props?.fulfillmentDetails?.[0]?.fulfillmentItems)
      );
    }
    setDocumentDetails(props.documentDetails);
  }, [props?.fulfillmentDetails, props?.documentDetails]);
  useEffect(() => {
    let itemsArray =
      documentDetails.salesInvoiceItems ||
      documentDetails.quotationItemDtoList ||
      documentDetails.salesOrderItems;
    setItemDescriptionArray(itemsArray);
    // if (tenantInfo.country === COUNTRY_CODES.IN) setIsVisibleState(true);
  }, [itemsDescriptionArray]);

  useEffect(() => {
    fetchAttachments();
  }, [props?.fulfillmentDetails?.[0]?.id]);

  const isTolerance =
    props.isToleranceApprovalFlow &&
    Utility.isToleranceSettingsEnabled() &&
    props.documentType === DOC_TYPE.SALES_ORDER;
  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_RECORDS,
        data: () => {
          closePopup();
        }
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    if (tenantInfo.country === COUNTRY_CODES.IL) {
      fetchContactInfo();
    }
  }, []);

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const fetchContactInfo = () => {
    ContactService.getContactDetailsById(
      props.documentDetails?.contactDto?.id
    ).then((res: any) => {
      if (res) {
        setContactDetails(res);
      }
    });
  };

  const getProductQty = (item: any, i: number) => {
    let quantity;
    if (item.documentUOMSchemaDefinition) {
      quantity = item.uomPendingQuantity + item.uomFulfilledQuantity;
    } else {
      quantity = item.pendingQuantity + item.fulfilledQuantity;
    }
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const getFulfilledQty = (item: any, i: number) => {
    let quantity;
    if (item.documentUOMSchemaDefinition) {
      quantity = item.uomFulfilledQuantity ? item.uomFulfilledQuantity : 0;
    } else {
      quantity = item.fulfilledQuantity ? item.fulfilledQuantity : 0;
    }
    return Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
  };

  const getSelectedUomValue = (id: number) => {
    let filteredUOM =
      uoms && uoms.length > 0 ? uoms.find((uom) => uom.id === id) : null;
    return filteredUOM?.name || '';
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setShowPopup(false);
        break;
    }
  };

  const getRowData = (fulfillmentItems: any) => {
    if (Utility.isEmpty(fulfillmentItems)) {
      return [];
    }

    let rowData: any = [];

    fulfillmentItems &&
      fulfillmentItems?.forEach((item: any, index: number) => {
        let rowObj: any = {
          products: item.productName,
          description: getProductDescription(item),
          uom: getSelectedUomValue(item.documentUom),
          required: getProductQty(item, index),
          committing: getFulfilledQty(item, index),
          documentSequenceCode: item?.documentSequenceCode || ''
          //receiving: quantityToFulfill[index],
          // advancedTracking: item.product.advancedTracking
        };
        if (!Utility.isEmpty(item.customField)) {
          item.customField.forEach((item1: any) => {
            let filteredCF: any = selectCustomFieldsData?.content?.find(
              (cf: any) => cf.code === item1.code
            );
            if (!Utility.isEmpty(filteredCF)) {
              let cfValue;
              if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                cfValue = DateFormatService.getDateFromStr(
                  item1.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.code === item1.value
                );
                if (tempCF) {
                  cfValue = tempCF ? tempCF : '';
                }
              } else if (
                filteredCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                cfValue = item1 ? item1 : '';
              } else {
                cfValue = item1.value ? item1.value : '';
              }
              rowObj[item1.id] = cfValue;
            }
          });
        }
        rowData.push(rowObj);
      });

    return rowData;
  };

  const getPpsRowData = (fulfillmentItems: any) => {
    if (Utility.isEmpty(fulfillmentItems)) {
      return [];
    }

    let rowData: any = [];

    fulfillmentItems &&
      fulfillmentItems?.forEach((item: any, index: number) => {
        let rowObj: any = {
          products: item.itemName,
          description: item?.itemDescription,
          required: item?.quantity,
          committing: item?.pickedQuantity,
          documentSequenceCode: item?.productCode || '',
          warehouseInventoryPPSData: item.warehouseInventoryPPSData,
          advancedTrackingType: item.advancedTrackingType
        };
        if (!Utility.isEmpty(item.customField)) {
          item.customField.forEach((item1: any) => {
            let filteredCF: any = selectCustomFieldsData?.content?.find(
              (cf: any) => cf.code === item1.code
            );
            if (!Utility.isEmpty(filteredCF)) {
              let cfValue;
              if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                cfValue = DateFormatService.getDateFromStr(
                  item1.value,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.code === item1.value
                );
                if (tempCF) {
                  cfValue = tempCF ? tempCF : '';
                }
              } else if (
                filteredCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                cfValue = item1 ? item1 : '';
              } else {
                cfValue = item1.value ? item1.value : '';
              }
              rowObj[item1.id] = cfValue;
            }
          });
        }
        rowData.push(rowObj);
      });

    return rowData;
  };

  const showCustomFieldView = () => {
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={module}
          customFieldsList={[...fulfillmentData?.customField] || []}
          columnConfig={[]}
          documentMode={DOCUMENT_MODE.VIEW}
          columnConfigTableId={''}
          onUpdate={(list: any) => {}}
          valueToTriggerLocalChange={props?.selectedTabIndex}
        />
      </div>
    );
  };
  const fetchAttachments = (id?: any) => {
    let moduleType = DOC_TYPE.FULFILLMENT;

    const entityId = id
      ? id
      : props.fulfillmentDetails?.[0]?.id ||
        props.fulfillmentDetails?.[0]?.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
    });
  };
  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments?.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };
  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(attachmentForListing);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
      });
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const getProductDescription = (item: any) => {
    let product: any = itemsDescriptionArray?.filter(
      (ele: any) => ele.productCode === item.productCode
    );
    return product?.[0]?.productDescription || '';
  };

  const advanceTrackingExists = () => {
    return (
      fulfillmentData?.fulfillmentItems?.filter(
        (item: any) => item.advancedTrackingType !== TRACKING_TYPE.NONE
      )?.length > 0
    );
  };

  const onPaginationClick = (pageNo: number = 0) => {
    const config: NewReportConfig = {
      ...NewReportService.apiConfig,
      Page: pageNo - 1,
      Limit: PAGE_SIZE
    };
    NewReportService.apiConfig = config;
    NewReportService.getFullfillment(itemDetails.fulfillment_item_code).then(
      (res: any) => {
        setRes(res);
      }
    );
  };

  const getContactAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = fulfillmentData?.shipFrom?.customFields;
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIP_TO) {
      addressCustomFields = fulfillmentData?.shipTo?.customFields;
    }
    return Utility.isNotEmpty(addressCustomFields) ? (
      <div
        className={`${addressCustomFields?.length ? 'mb-r mr-l' : ''}`}
        style={{
          marginLeft: addressCustomFields?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={[]}
          columnConfigTableId={''}
          documentMode={DOCUMENT_MODE.VIEW}
          onUpdate={async (updatedCFList) => {}}
          onLocationUpdate={(loc: any) => {}}
          contact={documentDetails?.contact}
          hideAddfieldButton={true}
          addressUpdate={true}
          updateAddressField={() => {}}
          valueToTriggerLocalChange={props?.selectedTabIndex}
        />
      </div>
    ) : null;
  };

  const getPPSCustomFields = () => {
    let customFields = fulfillmentData?.customField ?? [];
    let docType = MODULES_NAME.PICK_PACK_SHIP;
    if (props?.documentType === DOC_TYPE.SALES_ORDER) {
      docType = MODULES_NAME.SALESORDER;
    } else if (props?.documentType === DOC_TYPE.QUOTE) {
      docType = MODULES_NAME.QUOTATION;
    } else if (props?.documentType === DOC_TYPE.INVOICE) {
      docType = MODULES_NAME.INVOICE;
    }
    return Utility.isNotEmpty(customFields) ? (
      <div
        className={`${customFields?.length ? 'mb-r mr-l' : ''}`}
        style={{
          marginLeft: customFields?.length ? '' : -12
        }}
      >
        <CustomFieldsHolder
          moduleName={docType}
          customFieldsList={customFields}
          columnConfig={[]}
          columnConfigTableId={'Ship List Popup'}
          documentMode={DOCUMENT_MODE.VIEW}
          onUpdate={(list: any) => {}}
        />
      </div>
    ) : null;
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {
          <>
            <div className="row">
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Payer Id"
                  value={contactDetails?.taxPayerIdIsrael || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word',
                  marginLeft: '10px'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Registration Number"
                  value={contactDetails?.taxNumber || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        }
      </>
    );
  };

  const getButtonsForRow = (data: any, index: any) => {
    let buttons: any[] = [];
    const item = fulfillmentData?.fulfillmentItems
      ? fulfillmentData?.fulfillmentItems[index]
      : null;
    if (!isTolerance && item?.productType !== PRODUCT_TYPE.NON_TRACKED) {
      buttons.push({
        icon: ic_barcode_green,

        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowIndex, rowData }: any) => {
          if (fulfillmentData?.category === 'PPS Fulfillment') {
            setShowPopup(true);
            setIsPPS(true);
            setTrackingType(rowData.advancedTrackingType);
            setRes(rowData?.warehouseInventoryPPSData);

            if (rowData.advancedTrackingType === 'BATCH') {
              setIsSerial(false);
            }
            if (rowData.advancedTrackingType === 'SERIAL') {
              setIsSerial(true);
            }
            if (rowData.advancedTrackingType === 'NONE') {
              setShowPopup(true);
            }
            return;
          }
          setIsPPS(false);

          const item = fulfillmentData?.fulfillmentItems[rowIndex];
          setItemDetails(item);
          if (item.advancedTrackingType === 'BATCH') {
            setTrackingType('BATCH');
            setIsSerial(false);
          }
          if (item.advancedTrackingType === 'SERIAL') {
            setTrackingType('SERIAL');
            setIsSerial(true);
          }
          if (item.advancedTrackingType === 'NONE') {
            setTrackingType('NONE');
            setShowPopup(true);
          }
          if (item.advancedTrackingType !== TRACKING_TYPE.NONE) {
            const config: NewReportConfig = {
              ...NewReportService.apiConfig,
              Page: 0,
              Limit: PAGE_SIZE
            };
            NewReportService.apiConfig = config;
            NewReportService.getFullfillment(item.fulfillment_item_code).then(
              (res: any) => {
                setRes(res);
              }
            );
            setShowPopup(true);
          }
        }
      });
    }

    return buttons;
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={gridRowData?.map((item: any, index: any) => {
          return {
            ...item,
            rowButtons: getButtonsForRow(item, index)
          };
        })}
        columns={[
          ...columnConfig,
          {
            key: 'actions',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            actionButtonOptions: [],
            width: 180
          }
        ]}
        onRowUpdate={() => {}}
        onRowClick={({ columnData, rowIndex }: any) => {
          // if (props.fulfillmentItems) {
          // }
          // updateConfig(props.fulfillmentItems[rowIndex]);
        }}
      />
    );
  };

  const getPPSDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={ppsGridRowData?.map((item: any, index: any) => {
          return {
            ...item,
            rowButtons: getButtonsForRow(item, index)
          };
        })}
        columns={[
          ...ppsColumnConfig,
          {
            key: 'actions',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            actionButtonOptions: [],
            width: 180
          }
        ]}
      />
    );
  };

  const shipToAddress = new Address(fulfillmentData?.shipTo);
  const shipFromAddress = new Address(fulfillmentData?.shipFrom);
  return (
    <div className="flex align-self-start p-4 parent-width">
      <div className="flex flex-col parent-width">
        <div className="flex row-responsive justify-content-between w-full">
          {/* ship to */}
          {Utility.isNotEmpty(fulfillmentData) &&
            fulfillmentData?.category !== 'PPS Fulfillment' && (
              <div>
                <div
                  className="flex flex-col mr-r"
                  style={{ width: 300, maxWidth: 300 }}
                >
                  <div className="flex align-items-center label">
                    <DKIcon
                      src={ic_delivery}
                      className="ic-r ic-s"
                      style={{ opacity: 0.8 }}
                    />
                    <span className="fs-r pl-2 font-medium">Ship To</span>
                  </div>
                  <div
                    className="flex flex-col value"
                    style={{ minHeight: 100 }}
                  >
                    <span className="fs-r address1">
                      {shipToAddress.address1}
                    </span>
                    <span className="fs-r address2">
                      {shipToAddress.address2}
                    </span>
                    <span className="fs-r city">
                      {shipToAddress.city}
                      {`${shipToAddress.city !== '' ? ',' : ''}` +
                        shipToAddress.state}
                    </span>
                    <span className="fs-r country-postal">
                      {shipToAddress.country} {shipToAddress.postalCode}
                    </span>
                  </div>
                  {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_TO)}
                </div>
                {/* ship from */}
                <div
                  className="flex flex-col mr-r mobile-mt-m"
                  style={{ width: 300, maxWidth: 300 }}
                >
                  <div className="flex align-items-center label">
                    <span className="fs-r font-medium">Ship From</span>
                  </div>
                  <div
                    className="flex flex-col value pt-1"
                    style={{ minHeight: 100 }}
                  >
                    <span className="fs-r address1">
                      {shipFromAddress.address1}
                    </span>
                    <span className="fs-r address2">
                      {shipFromAddress.address2}
                    </span>
                    <span className="fs-r city">
                      {shipFromAddress.city}
                      {`${shipFromAddress.city !== '' ? ',' : ''}` +
                        shipFromAddress.state}
                    </span>
                    <span className="fs-r country-postal">
                      {shipFromAddress.country} {shipFromAddress.postalCode}
                    </span>
                  </div>
                  {getContactAddressCustomFields(BOOKS_ADDRESS_TYPES.SHIP_FROM)}
                </div>
              </div>
            )}
          {/* fulfill date */}
          <div className="flex flex-col w-48 mobile-mt-m">
            <span className="text-black fs-r ml-0 font-medium">
              {fulfillmentData?.category === 'PPS Fulfillment'
                ? 'PPS Date'
                : localizedText('Fulfill Date')}
            </span>
            <div className="position-relative pt-2">
              {Utility.isNotEmpty(fulfillmentData) &&
                (fulfillmentData?.category === 'PPS Fulfillment'
                  ? DateFormatService.getFormattedDateString(
                      fulfillmentData?.documentDate,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    )
                  : DateFormatService.getFormattedDateString(
                      fulfillmentData?.fulfillmentDate,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    ))}
            </div>
          </div>
        </div>

        {fulfillmentData && fulfillmentData.customerOrderNumber && (
          <div className="flex flex-col w-48 mobile-mt-m pt-1">
            <span className="text-black fs-r ml-0 font-medium">
              Customer Order No.
            </span>
            <div className="position-relative pt-1">
              {fulfillmentData && fulfillmentData.customerOrderNumber}
            </div>
          </div>
        )}
        {tenantInfo.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
        {fulfillmentData &&
          fulfillmentData?.category !== 'PPS Fulfillment' &&
          fulfillmentData?.customField &&
          showCustomFieldView()}
        {fulfillmentData &&
          fulfillmentData?.category === 'PPS Fulfillment' &&
          fulfillmentData?.customField &&
          getPPSCustomFields()}
        <div
          className="flex row-responsive justify-content-between invoice-table position-relative mt-xxl hide-scroll-bar column"
          style={{ overflowX: 'scroll' }}
        >
          {Utility.isNotEmpty(fulfillmentData) &&
            (fulfillmentData?.category === 'PPS Fulfillment'
              ? getPPSDataGrid()
              : getDataGrid())}
          {/* <table
            ref={lineItemsTable}
            className="table mb-5"
            style={{ width: '100%' }}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ width: 40, minWidth: 40, maxWidth: 40 }}
                >
                  #
                </th>
                <th className="text-left" style={{ width: 150, minWidth: 200 }}>
                  Products
                </th>
                <th className="text-left">Description</th>
                <th
                  className="text-right"
                  style={{ width: 60, minWidth: 60, maxWidth: 100 }}
                >
                  UOM
                </th>
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  Required
                </th>
                <th
                  className="text-right"
                  style={{ width: 100, minWidth: 100 }}
                >
                  Committed
                </th>
                {advanceTrackingExists() && !isTolerance && (
                  <th style={{ width: 60, minWidth: 60 }}> </th>
                )}
              </tr>
            </thead>
            <tbody>
              {fulfillmentData &&
                fulfillmentData.fulfillmentItems?.map((item: any, i: any) => {
                  return (
                    <tr className="dk-data-grid-row-bg">
                      <td className="text-align-center px-2 py-2 fs-r">
                        {i + 1}
                      </td>
                      <td className="px-2 py-2 fs-r">{item.productName}</td>
                      <td className="px-2 py-2 fs-r">
                        {getProductDescription(item)}
                      </td>
                      <td className="px-2 py-2 fs-r text-align-center">
                        {getSelectedUomValue(item.documentUom)}
                      </td>
                      <td className="text-align-right px-2 py-2 fs-r">
                        {getProductQty(item, i)}
                      </td>
                      <td className="text-align-right px-2 py-2 fs-r">
                        {getFulfilledQty(item, i)}
                      </td>
                      {item.advancedTrackingType !== TRACKING_TYPE.NONE &&
                        !isTolerance && (
                          <td
                            className="text-align-right px-2 fs-r"
                            style={{ width: 45 }}
                          >
                            <div className="row justify-content-center">
                              <DKIcon
                                src={ic_barcode_green}
                                onClick={() => {
                                  setItemDetails(item);
                                  if (item.advancedTrackingType === 'BATCH') {
                                    setIsSerial(false);
                                  }
                                  if (item.advancedTrackingType === 'SERIAL') {
                                    setIsSerial(true);
                                  }
                                  const config: NewReportConfig = {
                                    ...NewReportService.apiConfig,
                                    Page: 0,
                                    Limit: PAGE_SIZE
                                  };
                                  NewReportService.apiConfig = config;
                                  NewReportService.getFullfillment(
                                    item.fulfillment_item_code
                                  ).then((res: any) => {
                                    setRes(res);
                                  });
                                  setShowPopup(true);
                                }}
                                title="Advanced Tracking"
                                className="ic-r flex align-items-center cursor-hand"
                                style={{ width: '100%' }}
                              />
                            </div>
                          </td>
                        )}
                    </tr>
                  );
                })}
            </tbody>
          </table> */}
          <div className="row">
            {attachments?.length > 0 && getAttachments()}
          </div>
        </div>
      </div>
      {showPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={
            trackingType === 'NONE'
              ? 'None Tracked Fulfillment Records'
              : isSerial
              ? 'Serial-Fulfillment Records'
              : 'Batch-Fulfillment Records'
          }
          btnList={popupBtnConfig}
          width={isSerial ? `${popupWidth}%` : '60%'}
          height={!Utility.isEmptyObject(res) ? 'auto' : 350}
          disableClickOutside={true}
        >
          <FulfillmentTable
            populateFormData={res}
            isSerial={isSerial}
            module={'SELL'}
            itemData={itemDetails}
            trackingType={trackingType}
            width={'100%'}
            totalPageCount={res?.totalPages || 0}
            currentPage={res?.pageable?.pageNumber + 1 || 1}
            isPPS={isPPS}
            onPaginationClick={(pageNo: number) => onPaginationClick(pageNo)}
          />
        </PopupWrapper>
      )}
    </div>
  );
};

export default FulfillmentRecordDetailWithTable;
