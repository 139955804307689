import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  INPUT_TYPE,
  showLoader,
  removeLoader,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CUSTOM_FIELD_TYPE,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  MODULE_TYPE,
  PRODUCT_TYPE,
  ROW_RACK_BIN_CONSTANT,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { StockRequestInitialState } from '../../../Models/StockRequest';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  fetchCustomFields,
  selectCustomFields,
  selectProductCustomFields,
  selectUOMs
} from '../../../Redux/Slices/CommonDataSlice';
import {
  fetchStockTransferBrief,
  selectStockTransferColumnConfig,
  selectStockTransferColumnConfigTableId,
  selectStockTransferProduct,
  selectStockTransferProductBrief
} from '../../../Redux/Slices/StockTransferSlice';

import ic_no_data from '../../../Assets/Icons/ic_no_data.png';
import { CustomField } from '../../../Models/CustomField';
import { fetchStockRequest } from '../../../Redux/Slices/StockRequestSlice';
import {
  selectWarehouseProductByID,
  selectWarehouseProductCodes
} from '../../../Redux/Slices/WarehouseProductSlice';
import DateFormatService from '../../../Services/DateFormat';
import StockRequestService from '../../../Services/StockRequest';
import StockTransferService, {
  StockTransferAPIConfig
} from '../../../Services/StockTransfer';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import StockRequestDetailPopup from './StockRequestDetailPopup';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import NumberFormatService from '../../../Services/NumberFormat';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { getNewColumn } from '../../Accounting/JournalEntry/JEHelper';
import AddClass from '../../Settings/Classes/AddClass';
import { fetchCategoryDimensions } from '../../../Redux/Slices/LocationSlice';
import { DocumentConfigUtility } from '../../../Utility/DocumentConfigUtility';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import { WAREHOUSE_TYPE } from '../../../SharedComponents/WarehouseManagement/WarehouseManagementHelper';
import ApiConstants from '../../../Constants/ApiConstants';
import MRPProductsService from '../../../Services/MRP/MRPProducts';
import {
  getApprovalConfig,
  getIsApprovalFlowEnabled
} from '../StockAdjustment/StockAdjustmentHelper';
import { t } from 'i18next';
import DocProductSelectionPopup from '../../../SharedComponents/DocumentForm/DocProductSelectionPopup';
import {
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import CustomFieldService from '../../../Services/CustomField';
export default function AddStockTransferPopup(props: any) {
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState<any>(props.details);

  const [module, setModule] = useState<any>(props.module);
  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [productWarehouse, setProductWarehouse] = useState<any>([]);
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState<any>(
    []
  );
  const [defaultWarehouseData, setdefaultWarehouseData] = useState<any>({});
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [records, setRecords] = useState(props.records);
  const [warehouseProduct, setWarehouseProduct] = useState<any>({});

  const [stockRequestNotes, setStockRequestNotes] = useState<any>(
    props?.selectedRecord?.memo ? props?.selectedRecord?.memo : ''
  );
  const [showWarehouseManagement, setShowWarehouseManagement] = useState(false);
  const [showRequestDetailPopup, setShowRequestDetailPopup] = useState(false);
  const [trackingData, setTrackingData] = useState<any>({});
  const [normalDetail, setNormalDetail] = useState<any>({});
  const [isBatch, setIsBatch] = useState<any>(false);
  const [isNormal, setIsNormal] = useState<any>(false);
  const [showBatchTransferPopup, setShowBatchTransferPopup] = useState(false);
  const [showSerialTrackingPopup, setShowSerialTrackingPopup] = useState(false);
  const [StockRequestState, setStockRequestState] = useState<any>(
    StockRequestInitialState
  );
  const [srCFData, setSRCFData] = useState<any[]>([]);
  const productInventoryWarehouseById = useAppSelector(
    selectWarehouseProductByID
  );
  const fetchProductWarehouseCodes = useAppSelector(
    selectWarehouseProductCodes
  );
  const [defaultAllocationWarehouse, setDefaultAllocationWarehouse] =
    useState<any>('');
  const tenantInfo = useAppSelector(activeTenantInfo);
  const productList = useAppSelector(selectStockTransferProduct);
  const uomData = useAppSelector(selectUOMs);
  const productBriefData = useAppSelector(selectStockTransferProductBrief);
  const [productData, setProductData] = useState<any[]>([]);
  const [productBriefList, setProductBriefList] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const columnConfig = useAppSelector(selectStockTransferColumnConfig);
  const columnConfigTableId: any = useAppSelector(
    selectStockTransferColumnConfigTableId
  );
  const [state, setState] = useState<any>(false);
  const warehousesData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [allProductsInfo, setAllProductsInfo] = useState<any>([]);
  const [productCustomFields, setProductCustomFields] = useState<any[]>([]);
  const productCFfromStore = useAppSelector(selectProductCustomFields);

  const isProductGroupingEnabled =
    tenantInfo?.additionalSettings?.PRODUCT_GROUP_ENABLED;
  const [showProductSelectionPopup, setShowProductSelectionPopup] =
    useState(false);

  const getProductApi = (search: string) => {
    const config: StockTransferAPIConfig = {
      ...StockTransferService.apiConfig,
      SearchTerm: search
    };
    StockTransferService.apiConfig = config;
    return StockTransferService.getProductStockRequestEndpoint();
  };
  const getProductData = (data: any) => {
    return data
      ? data.filter(
          (ele: any) =>
            ele.type !== PRODUCT_TYPE.NON_TRACKED && !ele.hasVariants
        )
      : [];
  };
  const getProductRenderer = (index: any, obj: any) => {
    return (
      <div className="flex flex-row w-full justify-content-between">
        <DKLabel
          style={{ fontSize: '13px' }}
          className="text-base border-radius-s"
          text={obj.name}
        />
        <DKLabel
          style={{ fontSize: '13px' }}
          className="text-base border-radius-s"
          text={obj.name}
        />
      </div>
    );
  };

  const actionButtonOptions = [
    {
      icon: DKIcons.ic_delete,
      className: 'p-v-0',
      onClick: async (data: any) => {
        const rowData = [data.rowData];
      }
    }
  ];
  const getActiveWarehouses = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      if (!Utility.isEmpty(props.targetWarehouse)) {
        return (
          productWarehouse.filter(
            (pw: any) => pw.id !== props.targetWarehouse.id
          ) || []
        );
      } else {
        return productWarehouse;
      }
    } else {
      return [];
    }
  };

  const localColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: props?.readOnly ? false : true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.productName;
      },
      width: 200,
      dropdownConfig: {
        title: 'Select Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 250 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: getProductApi,
          dataParser: getProductData,
          debounceTime: 300
        },
        data: productData,
        // renderer: productRenderer,
        // renderer: getProductRenderer,
        renderer: DocumentConfigUtility.productRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      id: 'productCode',
      key: 'documentSequenceCode',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      id: 'productAvailableQuantity',
      key: 'productAvailableQuantity',
      name: 'Available Quantity',
      type: INPUT_TYPE.TEXT,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      name: 'Target Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: props?.readOnly ? false : !readOnlyTarget,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'targetWarehouse',
      id: 'targetWarehouse',
      datasource: [],
      key: 'targetWarehouse',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.targetWarehouse?.name;
      },

      width: 200,
      dropdownConfig: {
        className: '',
        style: {},
        allowSearch: false,
        searchableKey: 'name',
        canEdit: false,
        canDelete: false,
        searchApiConfig: {
          getUrl: (searchValue: string) => {
            const query: string = `?limit=100&page=0&search=${searchValue}&allRRBDetails=true&query=active=true`;
            const finalEndPoint =
              ApiConstants.URL.BASE +
              ApiConstants.URL.ACCOUNTS.WAREHOUSES +
              query;
            return finalEndPoint;
          },
          dataParser: (response: any) => {
            let filtered = response?.content?.filter(
              (item: any) =>
                item.active && item.warehouseType !== WAREHOUSE_TYPE.REJECTED
            );
            return filtered;
          },
          debounceTime: 300
        },
        data: [],
        renderer: (index: any, obj: any) => {
          return <DKLabel text={`${obj.name}`} />;
        },
        onSelect: (index: any, data: any) => {}
      }
    },

    {
      id: 'row',
      columnCode: 'row',
      key: 'row',
      name: 'Select Row',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      formatter: (value: any) => {
        return value?.rowData?.row?.name ?? '';
      },
      uiVisible: true,
      dropdownConfig: {
        title: 'Select Row',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'rack',
      columnCode: 'rack',
      key: 'rack',
      name: 'Select Rack',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: (value: any) => {
        return value?.rowData?.rack?.name ?? '';
      },
      dropdownConfig: {
        title: 'Select Rack',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'bin',
      columnCode: 'bin',
      key: 'bin',
      name: 'Select Bin',
      type: INPUT_TYPE.DROPDOWN,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      formatter: (value: any) => {
        return value?.rowData?.bin?.name ?? '';
      },
      dropdownConfig: {
        title: 'Select Bin',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },

    {
      name: 'Requested Qty',
      index: 0,
      options: null,
      required: false,
      editable: props?.readOnly ? false : true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableQuantity',
      id: 'availableQuantity',
      datasource: [],
      key: 'availableQuantity',
      lineItem: false,
      type: INPUT_TYPE.NUMBER,
      textAlign: 'right',
      width: 200,
      formatter: (value: any) => {
        return value?.rowData?.availableQuantity;
      }
    }
  ];

  const editLocalColumnConfig = [
    {
      name: 'Product Name',
      index: 0,
      options: null,
      required: false,
      editable: true,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'product',
      id: 'product',
      datasource: [],
      key: 'product',
      lineItem: false,
      type: INPUT_TYPE.DROPDOWN,
      formatter: (value: any) => {
        return value?.rowData?.productName;
      },
      width: 250,
      dropdownConfig: {
        title: 'Select Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 250 },
        className: 'shadow-m width-auto',
        searchApiConfig: {
          getUrl: getProductApi,
          dataParser: getProductData,
          debounceTime: 300
        },
        data: productData,
        renderer: getProductRenderer,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      name: 'Available Qty',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'availableQuantity',
      id: 'availableQuantity',
      datasource: [],
      key: 'availableQuantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 450
    },
    {
      name: 'Quantity',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'quantity',
      id: 'quantity',
      datasource: [],
      key: 'quantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 450
    }
    // {
    //   key: 'actions',
    //   name: 'Actions',
    //   type: INPUT_TYPE.BUTTON,
    //   actionButtonOptions,
    //   width: 180
    // }
  ];

  const row1 = [
    {
      code: 1,
      date: '12/11/2022',
      notes: 'working',
      request_quantity: 20,
      warehouseName: 'Primary Warehouseeeeee'
    }
  ];

  async function loadAllProductsInfo() {
    let ids: any = [];
    if (!Utility.isEmpty(props?.selectedRecord?.stockRequestItems)) {
      props?.selectedRecord?.stockRequestItems.forEach((ele: any) => {
        ids.push({
          productCode: ele.productVariantCode,
          quantity: ele.availableQuantity,
          documentUOMSchemaDefinition: ele?.documentUOMSchemaDefinition,
          uomQuantity: Utility.getUomQuantityWithoutRoundOff(
            ele.availableQuantity,
            ele?.documentUOMSchemaDefinition
          )
        });
      });
    } else {
      if (Utility.isNotEmpty(props?.rowItemsForNewDoc)) {
        props?.rowItemsForNewDoc?.forEach((ele: any) => {
          ids.push({
            productCode: ele.productVariantCode,
            quantity: ele.availableQuantity,
            documentUOMSchemaDefinition: ele?.documentUOMSchemaDefinition,
            uomQuantity: Utility.getUomQuantityWithoutRoundOff(
              ele.availableQuantity,
              ele?.documentUOMSchemaDefinition
            )
          });
        });
      }
    }
    if (Utility.isNotEmpty(ids)) {
      const allProductsInfo = await MRPProductsService.getProductShortInfo(ids);
      setAllProductsInfo(allProductsInfo);
    }
  }

  useEffect(() => {
    loadAllProductsInfo();
    getSRAPICFData();

    updateConfig(null, '', null, null, null);
    let productWarehouseData;
    if (module === MODULE_TYPE.SELL) {
      productWarehouseData =
        productInventoryWarehouseById.filter(
          (warehouse: any) => warehouse.productAvailableQuantity[details.pid]
        ) || [];
    }
    if (!Utility.isEmpty(props.targetWarehouse)) {
      if (!Utility.isEmpty(productInventoryWarehouseById)) {
        if (productInventoryWarehouseById) {
          let warehouseIdWithInventory: any[] = [];
          productInventoryWarehouseById.forEach((piw: any) => {
            if (
              piw.productAvailableQuantity &&
              piw.productAvailableQuantity[details.pid] &&
              piw.productAvailableQuantity[details.pid] > 0
            ) {
              //get list of warehouse where product is available
              warehouseIdWithInventory.push(piw.id);
            }
          });
          if (warehouseIdWithInventory.length > 0) {
            //remove targetwarehouse from list
            let x = warehouseIdWithInventory.filter(
              (w: any) => w.id !== props.targetWarehouse.id
            );
            if (x && x.length > 0) {
              //set target warehouse if there are inventory in other warehouse
              let tmp = productInventoryWarehouseById.filter(
                (w: any) => w.id === props.targetWarehouse.id
              );
              if (tmp && tmp.length > 0) {
                let tmpWarehouse = [...warehouseData];
                if (!Utility.isEmpty(tmpWarehouse)) {
                  let x = { ...tmpWarehouse[0] };
                  x.targetWarehouse = tmp[0];
                  tmpWarehouse[0] = x;
                  setWarehouseData(tmpWarehouse);
                }

                setDestinationWarehouse(tmp[0]);
                setReadOnlyTarget(true);
              }
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (
      !Utility.isEmpty(productCFfromStore) &&
      productCFfromStore?.content?.length
    ) {
      let prodCFs = [...productCFfromStore?.content];
      prodCFs.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setProductCustomFields(prodCFs);
    }
  }, [productCFfromStore]);

  useEffect(() => {
    let warehouseTempData =
      props.hasMultilpleWarehouseData &&
      props.hasMultilpleWarehouseData.length > 0
        ? props.hasMultilpleWarehouseData
        : [
            {
              warehouseCode: {},
              availableQty: 0,
              quantity: details?.quantityFulfilled,
              targetWarehouse: props.targetWarehouse
            }
          ];

    // if (defaultProductWarehouse) {
    //   setSourceWarehouse(defaultProductWarehouse);
    // }
    setWarehouseData(warehouseTempData);
  }, [defaultProductWarehouse, defaultProductWarehouse]);

  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [targetWarehouse, settargetWarehouse] = useState<any>({});
  //const productInventoryWarehouse = useAppSelector(selectWarehouseProducts);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [columns, setColumns] = useState<any>(localColumnConfig);

  useEffect(() => {
    if (!Utility.isEmpty(tenantInfo)) {
      let newCols = getUpdatedColumnConfigs([...columns]);
      let accCustomFields = selectCustomFieldsData?.content?.filter(
        (item: any) => {
          return (
            item.modules?.includes('STOCKREQUEST') &&
            item.status === STATUS_TYPE.ACTIVE
          );
        }
      );
      accCustomFields = accCustomFields?.filter(
        (ele: any) => ele.label === LOCATION_CLASS_ENUM.CLASS
      );

      accCustomFields?.forEach((accCF: any) => {
        let newItem: any = getNewColumn(accCF);

        const newItemInExistingColConfig = columns.find(
          (config: any) => config.code === accCF.code
        );
        if (Utility.isEmpty(newItemInExistingColConfig)) {
          newItem['dropdownConfig']['button'] = {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => setShowAddClassPopup(true)
          };

          newCols.push({ ...newItem, hidden: hideClassColumn() });
        }
      });
      let productCustomFields = selectCustomFieldsData?.content?.filter(
        (item: any) => {
          return (
            item.modules?.includes('PRODUCT') &&
            item.status === STATUS_TYPE.ACTIVE
          );
        }
      );

      productCustomFields?.forEach((accCF: any) => {
        let newItem: any = getNewColumn(accCF);
        const newItemInExistingColConfig = newCols.find(
          (config: any) => config.code === accCF.code
        );
        if (Utility.isEmpty(newItemInExistingColConfig)) {
          newCols.push({ ...newItem });
        }
      });
      newCols = newCols.filter((col: any) => !col.hidden);
      setColumns([...newCols]);
    }
  }, [tenantInfo, selectCustomFieldsData]);

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getUpdatedColumnConfigs = (columns: any) => {
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    const enabledRRBNames = rrbEnabled
      ?.filter((rrb: any) => rrb.enable)
      ?.map((itemRRB: any) => {
        return itemRRB?.name;
      });

    const updatedColumns: any = [];
    columns?.forEach((col: any) => {
      if (
        col && col.key && typeof(col.key) === 'string' && (
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN)
      ) {
        //check and push ROW RACK BIN here
        if (enabledRRBNames?.includes(col.key.toUpperCase())) {
          const foundRRB = rrbEnabled?.find(
            (rrb: any) => rrb.name === col.key.toUpperCase()
          );
          col = {
            ...col,
            name: `Select ${foundRRB?.label ?? ''}`
          };
          updatedColumns.push(col);
        }
      } else {
        updatedColumns.push(col);
      }
    });

    return updatedColumns;
  };

  const getButtonsForRow = (data: any) => {
    let buttons: any[] = [];
    // TODO: handle delete of new line
    buttons.push({
      icon: DKIcons.ic_delete,
      className: 'p-v-0 text-blue underline grid-action-link-h-padding',
      onClick: ({ rowIndex, rowData }: any) => {
        let tmp = { ...StockRequestState };
        tmp.stockRequestItems.splice(rowIndex, 1);
        setStockRequestState(tmp);
      }
    });

    return buttons;
  };

  const updateEditData = () => {
    //debugger;
    let tmpRead = props.selectedRecord;
    let tmp = { ...StockRequestState };

    tmp.stockRequestDate = DateFormatService.getDateStrFromDate(
      new Date(props.selectedRecord?.stockRequestDate),
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    let allData: any[] = [];

    setStockRequestNotes(props.selectedRecord?.notes);
  };

  const getSRAPICFData = async () => {
    try {
      const srData = await CustomFieldService.getCustomFields({
        status: 'ACTIVE',
        limit: '1000',
        module: 'STOCKREQUEST'
      });
      let sortedCF = srData?.content?.length ? srData?.content : [];
      sortedCF.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setSRCFData(sortedCF);
      return sortedCF;
    } catch (err: any) {
      console.log('Error while fetching SR CFs: ', err);
    }
  };

  useEffect(() => {
    const handleItemsInNewDoc = async () => {
      const newItems: any[] = [];
      const rowItems = deepClone(props.rowItemsForNewDoc);
      rowItems?.forEach((item: any, index: number) => {
        const matchingProduct = allProductsInfo.find(
          (product: any) =>
            product.documentSequenceCode === item?.documentSequenceCode
        );

        const productAvailableQuantity = matchingProduct
          ? item?.documentUOMSchemaDefinition
            ? matchingProduct?.availableUomQuantity ??
              matchingProduct.availableQuantity
            : matchingProduct.availableQuantity
          : 0;

        let wh = item?.targetWarehouse;
        let uniqueRows: any, uniqueRacks: any, uniqueBins: any;
        if (!Utility.isEmpty(wh)) {
          //rows
          uniqueRows = wh?.warehouseRowInfos ?? [];
          //racks
          uniqueRacks = wh?.warehouseRackInfos;
          //bins
          uniqueBins = wh?.warehouseBinInfos;
          let itemRow = item?.row;
          let itemRack = item?.rack;
          let itemBin = item?.bin;
          updateConfig(wh, '', item?.row, item?.rack, item?.bin);

          if (
            Utility.isBinAllocationMandatory() ||
            Utility.isWarehouseTaggingEnabled()
          ) {
            let whInfo = warehousesData?.content?.find(
              (ele: any) => ele.code === item.targetWarehouse.code
            );
            if (Utility.isNotEmpty(whInfo)) {
              wh['warehouseRowInfos'] = whInfo['warehouseRowInfos'];
              wh['warehouseRackInfos'] = whInfo['warehouseRackInfos'];
              wh['warehouseBinInfos'] = whInfo['warehouseBinInfos'];
            }

            uniqueRows =
              Utility.isBinAllocationMandatory() ||
              (Utility.isWarehouseTaggingEnabled() &&
                item.taggedWarehouseData?.[0]?.rowCode)
                ? wh?.warehouseRowInfos?.filter(
                    (ele: any) =>
                      ele.code === item.taggedWarehouseData?.[0]?.rowCode
                  ) ?? []
                : wh?.warehouseRowInfos ?? [];
            uniqueRacks =
              Utility.isBinAllocationMandatory() ||
              (Utility.isWarehouseTaggingEnabled() &&
                item.taggedWarehouseData?.[0]?.rackCode)
                ? wh?.warehouseRackInfos?.filter(
                    (ele: any) =>
                      ele.code === item.taggedWarehouseData?.[0]?.rackCode
                  ) ?? []
                : wh?.warehouseRackInfos ?? [];
            uniqueBins =
              Utility.isBinAllocationMandatory() ||
              (Utility.isWarehouseTaggingEnabled() &&
                item.taggedWarehouseData?.[0]?.binCode)
                ? wh?.warehouseBinInfos?.filter(
                    (ele: any) =>
                      ele.code === item.taggedWarehouseData?.[0]?.binCode
                  ) ?? []
                : wh?.warehouseBinInfos ?? [];
            itemRow =
              wh?.warehouseRowInfos?.find(
                (ele: any) =>
                  ele.code === item.taggedWarehouseData?.[0]?.rowCode
              ) ?? [];
            itemRack =
              wh?.warehouseRackInfos?.find(
                (ele: any) =>
                  ele.code === item.taggedWarehouseData?.[0]?.rackCode
              ) ?? [];
            itemBin =
              wh?.warehouseBinInfos?.find(
                (ele: any) =>
                  ele.code === item.taggedWarehouseData?.[0]?.binCode
              ) ?? [];
            wh['warehouseRowInfos'] = uniqueRows;
            wh['warehouseRackInfos'] = uniqueRacks;
            wh['warehouseBinInfos'] = uniqueBins;
            item['row'] = itemRow;
            item['rack'] = itemRack;
            item['bin'] = itemBin;
            updateConfig(wh, '', itemRow, itemRack, itemBin);
          }

          item.invalidFields = getInvalidFields(
            itemRow,
            uniqueRows,
            itemRack,
            uniqueRacks,
            itemBin,
            uniqueBins
          );
        } else {
          updateConfig(null, '', '', '', '');
        }
        newItems.push({
          ...item,
          productAvailableQuantity,
          productAvailableQuantityInBaseUOM: matchingProduct?.availableQuantity
        });
      });
      return newItems;
    };

    setDefaultActiveWarehouse();
    if (Utility.isEmpty(props.selectedRecord)) {
      dispatch(fetchStockTransferBrief());
      // updateEditData();

      let tmpState = { ...StockRequestInitialState };
      if (Utility.isEmptyObject(props.rowItemsForNewDoc)) {
        if (isProductGroupingEnabled) {
          tmpState.stockRequestItems = [];
        } else {
          tmpState.stockRequestItems = [
            {
              productName: '',
              lineNumber: 0,
              productCode: '',
              documentSequenceCode: '',
              productAvailableQuantity: 0,
              productAvailableQuantityInBaseUOM: 0,
              productVariantCode: '',
              documentUom: 0,
              documentUOMSchemaDefinition: {},
              productQuantity: 0,
              dstWarehouseCode: '',
              stockRequestWarehouseInventoryData: '',
              type: null,
              product: {},
              rowButtons: []
            }
          ];
        }
        setStockRequestState(tmpState);
        setStockRequestNotes(props.selectedRecord?.memo);
      } else {
        handleItemsInNewDoc().then((newItems: any[]) => {
          tmpState = {
            ...tmpState,
            stockRequestItems: newItems,
            linkedDocuments: props.woDetails
              ? [
                  {
                    documentType: DOC_TYPE.WORK_ORDER,
                    documentCode: props.woDetails?.documentCode,
                    documentSequenceCode: props.woDetails?.documentSequenceCode
                  }
                ]
              : []
          };
          setStockRequestState(tmpState);
          setStockRequestNotes(
            props.woDetails
              ? `WO no.: ${
                  props.woDetails ? props.woDetails?.documentSequenceCode : ''
                }`
              : ''
          );
        });
      }
    } else {
      let tmpState = props.selectedRecord;
      if (Utility.isNotEmpty(props.selectedRecord)) {
        setState(true);
      }
      let allData: any[] = [];

      props.selectedRecord.stockRequestItems.forEach((item: any) => {
        const productDocumentSequenceCode = item.documentSequenceCode;
        const matchingProduct = allProductsInfo.find(
          (product: any) =>
            product.documentSequenceCode === productDocumentSequenceCode
        );

        const productAvailableQuantity = matchingProduct
          ? item?.documentUOMSchemaDefinition
            ? matchingProduct?.availableUomQuantity ??
              matchingProduct.availableQuantity
            : matchingProduct.availableQuantity
          : 0;

        allData.push({
          ...item,
          productAvailableQuantity,
          productAvailableQuantityInBaseUOM: matchingProduct?.availableQuantity
        });
      });
      props.selectedRecord.stockRequestItems?.map((item1: any, index1: any) => {
        item1?.customField?.map((item: any, index: any) => {
          let filteredCF: any = selectCustomFieldsData?.content?.find(
            (cf: any) => cf.code === item.code
          );
          if (!Utility.isEmpty(filteredCF)) {
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }
            allData[index1][item.id] = cfValue;
          }
        });
      });
      tmpState.stockRequestItems = allData;
      setStockRequestNotes(props.selectedRecord?.memo);
      setStockRequestState(tmpState);
      if (Utility.isNotEmpty(allProductsInfo)) {
        updateConfig(null, '', '', '', '');
      }
    }
  }, [allProductsInfo]);

  useEffect(() => {
    let tmpStockRequestState = { ...StockRequestState };
    let tmpStockRequestInitialState = tmpStockRequestState.stockRequestItems;

    tmpStockRequestInitialState.forEach((item: any) => {
      if (Utility.isEmpty(item.stockTransferWarehouseInventoryData)) {
        if (item.rowButtons && item.rowButtons.length > 0) {
          item.rowButtons[0].disabled = false;
        }
      } else {
        if (item.rowButtons && item.rowButtons.length > 0) {
          if (Utility.isEmpty(props.selectedRecord)) {
            item.rowButtons[0].disabled = true;
          } else {
            item.rowButtons[0].icon = ic_no_data;
          }
        }
      }
    });
  }, [StockRequestState]);

  useEffect(() => {
    if (productList && productList.length > 0) {
      setProductData(productList);
    }
  }, [productList]);

  useEffect(() => {
    if (
      !Utility.isEmpty(props.selectedRecord) &&
      productBriefData &&
      productBriefData.length > 0
    ) {
      updateEditData();
    }
  }, [productBriefData]);

  const updateConfig = (
    selectedWarehouse: any = null,
    productCode: any,
    selectedRow: any = null,
    selectedRack: any = null,
    selectedBin: any = null
  ) => {
    let config = [...columns];
    let rrbDtoForProduct: any,
      uniqueRows: any,
      uniqueRacks: any,
      uniqueBins: any;

    if (Utility.isEmpty(selectedWarehouse)) {
      uniqueRows = [];
      uniqueRacks = [];
      uniqueBins = [];
    } else {
      //rows
      uniqueRows = selectedWarehouse?.warehouseRowInfos ?? [];
      //racks
      let racks = !Utility.isEmpty(selectedWarehouse?.warehouseRackInfos)
        ? selectedWarehouse?.warehouseRackInfos?.filter((rackChip: any) => {
            if (Utility.isNotEmpty(selectedRow)) {
              return rackChip.rowCode === selectedRow?.code;
            } else {
              return rackChip;
            }
          })
        : [];
      uniqueRacks = racks;
      //bins
      let bins = !Utility.isEmpty(selectedWarehouse?.warehouseBinInfos)
        ? selectedWarehouse?.warehouseBinInfos?.filter((binChip: any) => {
            if (Utility.isNotEmpty(selectedRack)) {
              return binChip.rackCode === selectedRack?.code;
            } else if (Utility.isNotEmpty(selectedRow)) {
              return binChip.rowCode === selectedRow?.code;
            } else {
              return binChip;
              // return false;
            }
          })
        : [];
      uniqueBins = bins ?? [];
      console.log('UNIQUE: ', uniqueRows, uniqueRacks, uniqueBins);
    }

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.product?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig.getUrl = getProductApi;
          conf.dropdownConfig.searchApiConfig.dataParser = getProductData;
          //   conf.dropdownConfig.renderer = (index: any, obj: any) => {
          //     return obj?.name ?? '';
          //   };
          return;

        case 'availableQuantity':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(
              obj?.rowData?.availableQuantity ?? 0
            );
          };
          break;

        case 'productAvailableQuantity':
          conf.formatter = (obj: any) => {
            let conversion =
              obj.rowData?.documentUOMSchemaDefinition?.name ?? '';
            const productAvailableQuantity =
              obj?.rowData?.productAvailableQuantity ?? 0;
            return `${productAvailableQuantity} ${conversion}`;
          };
          break;

        case 'targetWarehouse':
          let activeWarehouses =
            warehousesData &&
            warehousesData.content &&
            warehousesData.content.filter(
              (item: any) => item.active && item.warehouseType !== 'REJECTED'
            );
          conf.formatter = (obj: any) => {
            return obj?.rowData?.targetWarehouse?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = null;
          conf.dropdownConfig.data = activeWarehouses;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          if (
            (Utility.isBinAllocationMandatory() ||
              Utility.isWarehouseTaggingEnabled()) &&
            !Utility.isEmpty(props.rowItemsForNewDoc)
          ) {
            conf.editable = false;
          }
          return;

        case 'row':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.row?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = null;
          conf.dropdownConfig.data = uniqueRows;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          if (
            Utility.isBinAllocationMandatory() &&
            !Utility.isEmpty(props.rowItemsForNewDoc)
          ) {
            conf.editable = false;
          }
          return;

        case 'rack':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.rack?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = null;
          conf.dropdownConfig.data = uniqueRacks;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          if (
            Utility.isBinAllocationMandatory() &&
            !Utility.isEmpty(props.rowItemsForNewDoc)
          ) {
            conf.editable = false;
          }
          return;

        case 'bin':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.bin?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = null;
          conf.dropdownConfig.data = uniqueBins;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          if (
            Utility.isBinAllocationMandatory() &&
            !Utility.isEmpty(props.rowItemsForNewDoc)
          ) {
            conf.editable = false;
          }
          return;

        default:
          break;
      }
    });

    setColumns([...config]);
  };

  const validateData = () => {
    if (Utility.isEmpty(StockRequestState.stockRequestDate)) {
      showAlert('Error', 'Please select transfer date');
      return false;
    }

    let cf: any = [];
    if (StockRequestState.customField.length > 0) {
      StockRequestState.customField.map((field: any) => {
        if (field.mandatory && field.value === '') {
          cf.push('');
        } else {
          cf.push(field.value);
        }
      });
    }

    let productArray: any = [];
    let whArray: any = [];
    let productQuantityArray: any = [];
    let invalidFieldsFlag: boolean = false;

    if (StockRequestState.stockRequestItems.length === 0) {
      showAlert('Error', 'Please select at least one product');
      return false;
    } else {
      StockRequestState.stockRequestItems.map((item: any) => {
        if (item.hasOwnProperty('productName')) {
          productArray.push(item.productName);
        } else if (!item.hasOwnProperty('productName')) {
          productArray.push(0);
        }

        if (
          item.targetWarehouse === undefined ||
          !item.hasOwnProperty('targetWarehouse')
        ) {
          whArray.push('');
        } else if (item.hasOwnProperty('targetWarehouse')) {
          whArray.push(item.targetWarehouse);
        }

        if (!Utility.isEmpty(item?.invalidFields)) {
          invalidFieldsFlag = true;
        }

        if (
          item.availableQuantity === undefined ||
          item.availableQuantity <= 0
        ) {
          productQuantityArray.push('');
        } else if (item.availableQuantity > 0) {
          productQuantityArray.push(item.availableQuantity);
        }
      });
    }
    const objWithInvalidfield = StockRequestState.stockRequestItems?.find(
      (item: any) => {
        return !Utility.isEmpty(item?.invalidFields);
      }
    );

    let validateProduct: boolean = productArray.includes('') ? true : false;
    let validateWh: boolean = whArray.includes('') ? true : false;
    let validateProductQuantity: boolean = productQuantityArray.includes('')
      ? true
      : false;
    let validateRow: boolean = productArray.includes(0) ? true : false;
    let validateCf: Boolean = cf.includes('') ? true : false;

    // if (validateCf) {
    //   showAlert('Error', 'Custom Field must not be blank');
    //   return false;
    // } else
    if (validateRow) {
      showAlert('Error', 'Please select Product in all rows');
      return false;
    } else if (validateProduct) {
      showAlert('Error', 'Please select at least one product');
      return false;
    } else if (objWithInvalidfield) {
      return false;
    } else if (validateProductQuantity && validateWh) {
      showAlert(
        'Error',
        'Requested Quantity and Target Warehouse must not be Empty'
      );
      return false;
    } else if (validateWh) {
      showAlert('Error', 'Please select Target Warehouse for your stock');
      return false;
    } else if (validateProductQuantity) {
      showAlert(
        'Error',
        'Requested Quantity of Product must be greater than 0'
      );
      return false;
    } else if (invalidFieldsFlag) {
      return false;
    }
    return true;
  };

  // const getTransferQuantity = (d: any) => {
  //   let x = d.map((data: any) => Number(data.quantity));
  //   let total = x.reduce((x: any, a: any) => (x = a), 0);
  //   return isNaN(total) ? 0 : total;
  // };
  // const getBatchTransferQuantity = (d: any) => {
  //   let x = d.map((data: any) => Number(data.quantity));
  //   let total = x.reduce((x: any, a: any) => x + a, 0);
  //   return isNaN(total) ? 0 : total;
  // };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = columns;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'STOCKREQUEST',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const saveStockRequest = () => {
    if (Utility.isEmpty(props.selectedRecord)) {
      if (validateData()) {
        setApiCallInProgress(true);
        let item: any[] = [];
        StockRequestState.stockRequestItems.forEach((data: any, index: any) => {
          let d = {
            productName: data.productName,
            lineNumber: index + 1,
            //productVariantCode: data.productVariantCode,
            documentUom:
              data.documentUOMSchemaDefinition.id ??
              data.documentUOMSchemaDefinition?.sinkUOM,
            documentUOMSchemaDefinition: data.documentUOMSchemaDefinition,
            productQuantity: data.availableQuantity,
            uomQuantity: data.availableQuantity,
            stockRequestWarehouseInventoryData:
              data.stockRequestWarehouseInventoryData,
            productCode: data.productVariantCode,
            productAvailableQuantity: data?.availableQuantity,
            documentSequenceCode: data.documentSequenceCode,
            availableQuantity: data?.availableQuantity,
            dstWarehouseCode: data.targetWarehouse.code,
            dstRowCode: data?.row?.code,
            dstRackCode: data?.rack?.code,
            dstBinCode: data?.bin?.code,
            customField: getLineItemCFs(data)
          };

          item.push(d);
        });

        let data = {
          memo: stockRequestNotes,
          stockRequestDate: StockRequestState.stockRequestDate,
          stockRequestItems: item,
          customField: StockRequestState?.customField?.length
            ? StockRequestState?.customField
            : null,
          linkedDocuments: StockRequestState?.linkedDocuments,
          approvalConfig: getApprovalConfig(DOC_TYPE.STOCK_REQUEST),
          isApprovalFlowEnabled: getIsApprovalFlowEnabled(
            DOC_TYPE.STOCK_REQUEST
          )
        };

        StockRequestService.createStockRequest(data)
          .then((res: any) => {
            dispatch(fetchStockRequest());
            showAlert('Success', 'Stock request created successfully');
            setApiCallInProgress(false);
            props.onSave(res);
          })
          .catch((error: any) => {
            setApiCallInProgress(false);
            showAlert('Error', 'Unable to save stock Request');
          });
      }
    } else {
      setApiCallInProgress(true);
      let item: any[] = [];
      StockRequestState.stockRequestItems.forEach((data: any, index: any) => {
        let d = {
          productName: data.productName,
          lineNumber: index + 1,

          productCode: data.productVariantCode,
          //productVariantCode: data.productVariantCode,
          documentSequenceCode: data.documentSequenceCode,
          availableQuantity: data?.availableQuantity,
          documentUom:
            data.documentUOMSchemaDefinition.id ??
            data.documentUOMSchemaDefinition?.sinkUOM,
          documentUOMSchemaDefinition: data.documentUOMSchemaDefinition,
          productQuantity: data.availableQuantity,
          stockRequestWarehouseInventoryData:
            data.stockRequestWarehouseInventoryData,
          dstWarehouseCode: data.targetWarehouse.code,
          dstRowCode: data?.row?.code,
          dstRackCode: data?.rack?.code,
          dstBinCode: data?.bin?.code,
          customField: getLineItemCFs(data)
        };

        item.push(d);
      });
      let data = {
        memo: stockRequestNotes,
        stockRequestDate: StockRequestState.stockRequestDate,
        stockRequestItems: item,
        customField: StockRequestState?.customField?.length
          ? StockRequestState?.customField
          : null,
        linkedDocuments: StockRequestState?.linkedDocuments
      };

      StockRequestService.updateStockRequest(data, props.selectedRecord.id)
        .then((res: any) => {
          dispatch(fetchStockRequest());
          setApiCallInProgress(false);
          props.onSave(res);
        })
        .catch((error: any) => {
          setApiCallInProgress(false);
          showAlert('Error', 'Unable to update stock request');
        });
    }
  };

  const setDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehousesData &&
      warehousesData.content &&
      warehousesData.content.filter((item: any) => item.active);
    if (activeWarehouses && activeWarehouses.length > 0) {
      let selectedWarehouse = activeWarehouses.filter(
        (warehouse: any) => warehouse.primary === true
      );
      if (selectedWarehouse[0] !== undefined && selectedWarehouse[0] !== null) {
        setDefaultAllocationWarehouse(selectedWarehouse[0].code);
        setSelectedWarehouse(selectedWarehouse[0]);
      }
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          {props?.readOnly ? (
            <>
              <DKLabel className="fw-m fs-l" text={'Stock Request Details'} />
              <div
                className={`bg-chip-orange text-orange p-v-s p-h-r border-radius-l fw-m text-wrap-none m-s`}
              >
                {'Read-Only'}
              </div>
            </>
          ) : (
            <DKLabel
              text={
                Utility.isEmpty(props.selectedRecord)
                  ? 'Add Stock Request'
                  : 'Edit Stock Request'
              }
              className="fw-m fs-l"
            />
          )}
        </div>

        <div className="row width-auto">
          {props?.readOnly ? (
            <>
              <DKButton
                title={'Close'}
                className="bg-white border-m mr-r"
                onClick={props.onCancel}
              />
            </>
          ) : (
            <>
              <DKButton
                title={'Cancel'}
                className="bg-white border-m mr-r"
                onClick={props.onCancel}
              />
              <div
                className={`row border-radius-m ${
                  apiCallInProgress
                    ? ' bg-gray1 border-m pr-2'
                    : ' bg-button text-white'
                }`}
              >
                {<DKButton title={'Save'} onClick={saveStockRequest} />}
                {apiCallInProgress && <DKSpinner iconClassName="ic-s" />}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  //const updateContactEmail = (record: any, email: any) => {};
  const currentDate = new Date();

  const onRequestDateChange = (data: any) => {
    let tmpStockRequest = deepClone(StockRequestState);
    tmpStockRequest.stockRequestDate = DateFormatService.getDateStrFromDate(
      data,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    if (!Utility.isEmpty(tmpStockRequest.stockRequestDate)) {
      const formatSrDate = tmpStockRequest.stockRequestDate
        .split('-')
        .reverse()
        .join('-');

      const srDate: Date = new Date(formatSrDate);
      const fDate: Date = new Date(tenantInfo.financialStartDate);

      if (srDate < fDate) {
        showAlert(
          'Invalid Date',
          'Stock Request Date can not be before the Financial Start Date'
        );
        tmpStockRequest.stockRequestDate = DateFormatService.getDateStrFromDate(
          currentDate,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        );
        setStockRequestState(tmpStockRequest);
        return;
      } else {
        setStockRequestState(tmpStockRequest);
      }
    }
  };

  const rowDelete = (data: any) => {};

  const onRowAdd = (data: any) => {};

  const getInvalidFields = (
    row: any,
    rowData: any,
    rack: any,
    rackData: any,
    bin: any,
    binData: any
  ) => {
    let invalidFields = [];

    if (!Utility.isEmpty(rowData) && Utility.isEmpty(row)) {
      invalidFields.push('row');
    }
    if (!Utility.isEmpty(rackData) && Utility.isEmpty(rack)) {
      invalidFields.push('rack');
    }
    if (!Utility.isEmpty(binData) && Utility.isEmpty(bin)) {
      invalidFields.push('bin');
    }

    return invalidFields;
  };

  const rowEdit = async (data: any) => {
    console.log('data', data);
    let uniqueRows: any, uniqueRacks: any, uniqueBins: any;
    let tempStockRequestState = { ...StockRequestState };

    if (
      !Utility.isEmpty(data.rowData.targetWarehouse) &&
      (data?.columnKey === 'row' ||
        data?.columnKey === 'rack' ||
        data?.columnKey === 'bin')
    ) {
      try {
        let wh = data.rowData.targetWarehouse;
        if (!Utility.isEmpty(wh)) {
          //rows
          uniqueRows = wh?.warehouseRowInfos ?? [];
          //racks
          uniqueRacks = wh?.warehouseRackInfos;
          //bins
          uniqueBins = wh?.warehouseBinInfos;
          updateConfig(
            wh,
            '',
            data?.rowData?.row,
            data?.rowData?.rack,
            data?.rowData?.bin
          );
          if (data?.columnKey === 'row') {
            tempStockRequestState.stockRequestItems[data.rowIndex].row =
              data?.rowData?.row;
            tempStockRequestState.stockRequestItems[data.rowIndex].rack = null;
            tempStockRequestState.stockRequestItems[data.rowIndex].bin = null;
          }

          if (data?.columnKey === 'rack') {
            tempStockRequestState.stockRequestItems[data.rowIndex].rack =
              data?.rowData?.rack;
            tempStockRequestState.stockRequestItems[data.rowIndex].bin = null;
          }

          if (data?.columnKey === 'bin') {
            tempStockRequestState.stockRequestItems[data.rowIndex].bin =
              data?.rowData?.bin;
          }

          tempStockRequestState.stockRequestItems[data.rowIndex].invalidFields =
            getInvalidFields(
              data?.rowData?.row,
              uniqueRows,
              data?.rowData?.rack,
              uniqueRacks,
              data?.rowData?.bin,
              uniqueBins
            );
        } else {
          updateConfig(null, '', '', '', '');
        }
      } catch (err: any) {
        console.log('Error fetching ware products info: ', err);
      }

      setStockRequestState(tempStockRequestState);
      return;
    }

    if (data) {
      const quantityToUpdate =
        data.rowData.product.advancedTracking === TRACKING_TYPE.SERIAL
          ? isNaN(Math.round(data.rowData.availableQuantity))
            ? 0
            : Math.round(data.rowData.availableQuantity)
          : data.rowData.availableQuantity;

      switch (data.columnKey) {
        case 'targetWarehouse':
          let selectedWarehouse = data.rowData.targetWarehouse;
          if (selectedWarehouse) {
            tempStockRequestState.stockRequestItems[data.rowIndex].row = null;
            tempStockRequestState.stockRequestItems[data.rowIndex].rack = null;
            tempStockRequestState.stockRequestItems[data.rowIndex].bin = null;
            tempStockRequestState.stockRequestItems[
              data.rowIndex
            ].targetWarehouse = { ...data.rowData?.targetWarehouse };
            try {
              let wh = selectedWarehouse;
              if (!Utility.isEmpty(wh)) {
                //rows
                uniqueRows = wh?.warehouseRowInfos ?? [];
                //racks
                uniqueRacks = wh?.warehouseRackInfos;
                //bins
                uniqueBins = wh?.warehouseBinInfos;
                updateConfig(
                  wh,
                  '',
                  data?.rowData?.row,
                  data?.rowData?.rack,
                  data?.rowData?.bin
                );
                tempStockRequestState.stockRequestItems[
                  data.rowIndex
                ].invalidFields = getInvalidFields(
                  data?.rowData?.row,
                  uniqueRows,
                  data?.rowData?.rack,
                  uniqueRacks,
                  data?.rowData?.bin,
                  uniqueBins
                );
              } else {
                updateConfig(null, '', '', '', '');
              }
              setStockRequestState(tempStockRequestState);
            } catch (err) {
              console.error('Error fetching product details: ', err);
            }
          }
          break;
        case 'availableQuantity':
          tempStockRequestState.stockRequestItems[
            data.rowIndex
          ].availableQuantity = quantityToUpdate;

          // if (data.rowData.productQuantity) {
          //   setRequestQuantity(data.rowData.availableQuantity);
          // }
          setStockRequestState(tempStockRequestState);
          break;
        case 'product':
          let tmpStockRequestedItemState =
            tempStockRequestState.stockRequestItems;
          let selectedRows = tmpStockRequestedItemState[data.rowIndex];

          let productDefaultWarehouse = warehousesData?.content?.find(
            (warehouse: any) =>
              warehouse.code === data?.rowData?.product?.warehouseCode
          );
          if (productDefaultWarehouse) {
            tempStockRequestState.stockRequestItems[data.rowIndex].row = data
              ?.rowData?.product?.rowCode
              ? productDefaultWarehouse?.warehouseRowInfos?.find(
                  (row: any) => row.code === data?.rowData?.product?.rowCode
                )
              : null;

            tempStockRequestState.stockRequestItems[data.rowIndex].rack = data
              ?.rowData?.product?.rackCode
              ? productDefaultWarehouse?.warehouseRackInfos?.find(
                  (rack: any) => rack.code === data?.rowData?.product?.rackCode
                )
              : null;
            tempStockRequestState.stockRequestItems[data.rowIndex].bin = data
              ?.rowData?.product?.binCode
              ? productDefaultWarehouse?.warehouseBinInfos?.find(
                  (bin: any) => bin.code === data?.rowData?.product?.binCode
                )
              : null;

            tempStockRequestState.stockRequestItems[
              data.rowIndex
            ].targetWarehouse = { ...data.rowData?.targetWarehouse };
            try {
              let wh = productDefaultWarehouse;
              if (!Utility.isEmpty(wh)) {
                //rows
                uniqueRows = wh?.warehouseRowInfos ?? [];
                //racks
                uniqueRacks = wh?.warehouseRackInfos;
                //bins
                uniqueBins = wh?.warehouseBinInfos;
                updateConfig(
                  wh,
                  '',
                  data?.rowData?.row,
                  data?.rowData?.rack,
                  data?.rowData?.bin
                );
                tempStockRequestState.stockRequestItems[
                  data.rowIndex
                ].invalidFields = getInvalidFields(
                  data?.rowData?.row,
                  uniqueRows,
                  data?.rowData?.rack,
                  uniqueRacks,
                  data?.rowData?.bin,
                  uniqueBins
                );
              } else {
                updateConfig(null, '', '', '', '');
              }
            } catch (err) {
              console.error('Error fetching product details: ', err);
            }
          }
          StockRequestService.fetchProductDocSeqCode([
            data.rowData.product.documentSequenceCode
          ])
            .then((res: any) => {
              let uom = uomData.filter((u: any) => u.id === res[0].stockUom);
              if (uom && uom.length > 0) {
                let stockItem: any = {
                  productName: data.rowData.product.name,
                  //name: data.rowData.product.targetWarehouse,
                  lineNumber: data.rowData.product.lineNumber,
                  productVariantCode: data.rowData.product.pid,
                  documentUom: res.stockUom,
                  documentUOMSchemaDefinition: uom[0],
                  //productQuantity: data.rowData.availableQuantity,
                  availableQuantity: quantityToUpdate,
                  stockRequestWarehouseInventoryData: null,
                  //availableQuantity: data.rowData.productQuantity,
                  dstWarehouseCode: data.rowData.dstWarehouseCode,
                  //availableQuantity: getButtonsForRow(data.rowData),
                  type: data.rowData.product.type,
                  product: data.rowData.product,
                  targetWarehouse: productDefaultWarehouse,
                  row: data?.rowData?.product?.rowCode
                    ? productDefaultWarehouse?.warehouseRowInfos?.find(
                        (row: any) =>
                          row.code === data?.rowData?.product?.rowCode
                      )
                    : null,
                  rack: data?.rowData?.product?.rackCode
                    ? productDefaultWarehouse?.warehouseRackInfos?.find(
                        (rack: any) =>
                          rack.code === data?.rowData?.product?.rackCode
                      )
                    : null,
                  bin: data?.rowData?.product?.binCode
                    ? productDefaultWarehouse?.warehouseBinInfos?.find(
                        (bin: any) =>
                          bin.code === data?.rowData?.product?.binCode
                      )
                    : null,
                  productCode: data.rowData.product.productCode,
                  documentSequenceCode:
                    data.rowData.product.documentSequenceCode,
                  productAvailableQuantity:
                    data.rowData.product.availableQuantity,
                  productAvailableQuantityInBaseUOM:
                    data.rowData.product.productAvailableQuantityInBaseUOM
                };
                stockItem = addProductCustomFieldsToLineItem(
                  { ...stockItem },
                  res[0]
                );
                if (Utility.isEmpty(selectedRows)) {
                  tempStockRequestState.stockRequestItems.push(stockItem);
                } else {
                  tempStockRequestState.stockRequestItems[data.rowIndex] =
                    stockItem;
                }
                setStockRequestState(tempStockRequestState);
              }
            })
            .catch((error: any) => {});
          break;
        default:
          // Set class or other CF values
          let tempState = { ...StockRequestState };
          tempState.stockRequestItems[data.rowIndex][data.columnKey] =
            data.rowData?.[data.columnKey];

          if (data?.columnData?.isCustomField) {
            //update column values for custom fields directly here
            const configForColumnKey = columns?.find(
              (config: any) => config?.key === data?.columnKey
            );
            if (configForColumnKey.isCustomField) {
              const cfInfo: any = selectCustomFieldsData?.content?.find(
                (cf: any) => cf.id === data?.columnKey
              );
              if (cfInfo) {
                if (
                  cfInfo.fieldType.toLowerCase() ===
                  INPUT_TYPE.DATE.toLowerCase()
                ) {
                  tempState.stockRequestItems[data.rowIndex][data?.columnKey] =
                    new Date(data.rowData[data?.columnKey]);
                } else if (
                  cfInfo.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.USER.toLowerCase()
                ) {
                  // Use data.rowData[columnKey].value for User or Dropdown type
                  tempState.stockRequestItems[data.rowIndex][data?.columnKey] =
                    data.rowData[data?.columnKey];
                } else if (
                  cfInfo.fieldType.toLowerCase() ===
                  CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
                ) {
                  // Use data.rowData[data?.columnKey].value for User or Dropdown type
                  tempState.stockRequestItems[data.rowIndex][data?.columnKey] =
                    data.rowData[data?.columnKey].value === 'None'
                      ? null
                      : data.rowData[data?.columnKey];
                  // cfUpdatedTimeMap.current = {
                  //   ...cfUpdatedTimeMap.current,
                  //   [cfInfo.id]: new Date().getTime()
                  // };
                  const availableSRCFs: any[] = srCFData;
                  const { rowData } = updateRowDataWithParentCFValues(
                    data.rowData[data?.columnKey].value === 'None'
                      ? null
                      : data.rowData[data?.columnKey],
                    { ...tempState.stockRequestItems[data.rowIndex] },
                    cfInfo,
                    availableSRCFs
                  );
                  tempState.stockRequestItems[data.rowIndex] = rowData;
                } else {
                  tempState.stockRequestItems[data.rowIndex][data?.columnKey] =
                    data.rowData[data?.columnKey];
                }
              }
            }
          }

          setStockRequestState(tempState);
          break;
      }
    }
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product: any) => {
    let cfs: any[] = [];
    if (
      !Utility.isEmpty(productCustomFields) &&
      !Utility.isEmpty(product.customField)
    ) {
      // Set default values of CFs when new line is added
      product.customField.forEach((productCF: any) => {
        const filteredCF = productCustomFields?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          width={785}
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={false}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={true}
          columns={[
            ...columns,
            {
              key: 'actions',
              name: 'Actions',
              type: INPUT_TYPE.BUTTON,
              actionButtonOptions: [],
              width: 180
            }
          ]}
          rows={StockRequestState.stockRequestItems?.map((item: any) => {
            return {
              ...item,
              rowButtons:
                props?.readOnly || !Utility.isEmpty(props.selectedRecord)
                  ? []
                  : getButtonsForRow(item)
            };
          })}
          onRowUpdate={rowEdit}
          onRowClick={async (data: any) => {
            if (Utility.isEmpty(data.rowData.targetWarehouse)) {
              updateConfig(null, '', '', '', '');
            } else {
              updateConfig(
                data.rowData.targetWarehouse,
                '',
                data?.rowData?.row,
                data?.rowData?.rack,
                data?.rowData?.bin
              );
            }
            const availableCFs: any[] = srCFData;
            updateColumnConfigOnRowClick(
              data?.columnData,
              data.rowData,
              columnConfig,
              availableCFs
            );
          }}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowBulkOperation={false}
        />
      </>
    );
  };

  const addNewItem = () => {
    let tmpStockRequestState = { ...StockRequestState };
    let tmpObj: any = {};

    tmpObj['rowButtons'] = getButtonsForRow({});
    tmpStockRequestState.stockRequestItems.push(tmpObj);

    setStockRequestState(tmpStockRequestState);
  };

  const customFieldUpdated = (cfList: CustomField[]) => {
    let newState = { ...StockRequestState };
    newState.customField = cfList;
    setStockRequestState({ ...newState });
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
        dispatch(fetchCustomFields({ status: 'ACTIVE', limit: '1000' }));
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const onRowSelectionFromPopup = (selectedProducts: any[]) => {
    populateProductGroupData(selectedProducts);
    setShowProductSelectionPopup(false);
  };

  const populateProductGroupData = (selectedProducts: any) => {
    let tmpStockRequestState = { ...StockRequestState };
    let rows = tmpStockRequestState?.stockRequestItems;

    if (selectedProducts && selectedProducts !== null) {
      if (selectedProducts.length > 0) {
        const selectedProductsSeqCodes = selectedProducts.map(
          (x: any) => x.documentSequenceCode
        );
        showLoader();
        StockRequestService.fetchProductDocSeqCode(selectedProductsSeqCodes)
          .then(async (res: any) => {
            selectedProducts.forEach(async (element: any) => {
              let resStockData: any = res.find(
                (x: any) =>
                  x.documentSequenceCode === element.documentSequenceCode
              );
              if (resStockData && uomData && uomData.length > 0) {
                const quantityToUpdate =
                  resStockData.advancedTracking === TRACKING_TYPE.SERIAL
                    ? isNaN(Math.round(element?.inventory?.availableQuantity))
                      ? 0
                      : Math.round(element?.inventory?.availableQuantity)
                    : element?.inventory?.availableQuantity;
                let UOM = uomData.find(
                  (u: any) => u.id === resStockData.stockUom
                );

                if (Utility.isNotEmpty(UOM)) {
                  let stockItem: any = {};
                  stockItem.productVariantCode = resStockData.pid;
                  stockItem.lineNumber = rows.length;
                  stockItem.productName = resStockData.name;
                  stockItem.documentUom = resStockData.stockUom;
                  stockItem.documentUOMSchemaDefinition = UOM;
                  stockItem.availableQuantity = 0;
                  stockItem.stockRequestWarehouseInventoryData = null;
                  stockItem.type = resStockData.type;
                  stockItem.product = element;
                  stockItem.productCode = element.productCode;
                  stockItem.documentSequenceCode =
                    resStockData.documentSequenceCode;
                  stockItem.productAvailableQuantity =
                    element?.inventory?.availableQuantity;
                  stockItem.productAvailableQuantityInBaseUOM =
                    resStockData.productAvailableQuantityInBaseUOM;

                  let productDefaultWarehouse = warehousesData?.content?.find(
                    (warehouse: any) =>
                      warehouse.code === element.inventory?.warehouseCode
                  );
                  if (productDefaultWarehouse) {
                    stockItem.row =
                      productDefaultWarehouse?.warehouseRowInfos?.find(
                        (row: any) => row.code === element?.inventory?.rowCode
                      ) ?? null;
                    stockItem.rack =
                      productDefaultWarehouse?.warehouseRowInfos?.find(
                        (row: any) => row.code === element?.inventory?.rackCode
                      ) ?? null;
                    stockItem.bin =
                      productDefaultWarehouse?.warehouseRowInfos?.find(
                        (row: any) => row.code === element?.inventory?.binCode
                      ) ?? null;

                    try {
                      let uniqueRows: any, uniqueRacks: any, uniqueBins: any;
                      let wh = productDefaultWarehouse;
                      if (!Utility.isEmpty(wh)) {
                        //rows
                        uniqueRows = wh?.warehouseRowInfos ?? [];
                        //racks
                        uniqueRacks = wh?.warehouseRackInfos;
                        //bins
                        uniqueBins = wh?.warehouseBinInfos;
                        updateConfig(
                          wh,
                          '',
                          stockItem.row,
                          stockItem.rack,
                          stockItem.bin
                        );

                        stockItem.invalidFields = getInvalidFields(
                          stockItem.row,
                          uniqueRows,
                          stockItem.rack,
                          uniqueRacks,
                          stockItem.bin,
                          uniqueBins
                        );
                      } else {
                        updateConfig(null, '', '', '', '');
                      }
                    } catch (err) {
                      console.error('Error fetching product details: ', err);
                    }

                    stockItem.targetWarehouse = productDefaultWarehouse;

                    stockItem = addProductCustomFieldsToLineItem(
                      { ...stockItem },
                      resStockData
                    );
                  }
                  rows.push(stockItem);
                  // stockItem.productData = productData
                  // newRows.push(stockItem)
                }
              }
            });
          })
          .catch((error: any) => {})
          .finally(() => {
            removeLoader();
            tmpStockRequestState.stockRequestItems = rows;
            setStockRequestState(tmpStockRequestState);
          });
      }
    }
  };

  const getProductSelectionPopup = () => {
    return (
      <DocProductSelectionPopup
        module={DOC_TYPE.STOCK_REQUEST}
        onSave={onRowSelectionFromPopup}
        onCancel={() => setShowProductSelectionPopup(false)}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 850,
            width: '90%',
            maxHeight: '95%',
            padding: 0,
            paddingBottom: 30
          }}
        >
          {getHeader()}
          <div
            className="column p-h-xl parent-width parent-height"
            style={{
              overflowY: 'scroll',
              overflowX: 'hidden',
              scrollbarWidth: 'none'
            }}
          >
            <div
              id="popup-container"
              className="column parent-width parent-height pt-xl"
              style={{
                pointerEvents: 'auto'
              }}
            >
              <div className="row parent-width">
                <div className="w-1/2 p-h-s">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.TEXT}
                    title={'Request Document'}
                    value={
                      !Utility.isEmpty(props.selectedRecord)
                        ? props?.selectedRecord?.documentSequenceCode
                        : 'STKRQ-0000001'
                    }
                    onChange={(value: number) => {}}
                    canValidate={false}
                    validator={(value: string) => {}}
                    errorMessage={''}
                    readOnly={true}
                    required={false}
                  />
                </div>
                <div className="w-1/2 p-h-s">
                  <DKInput
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    type={INPUT_TYPE.DATE}
                    title={'Date'}
                    className=""
                    value={
                      StockRequestState && StockRequestState.stockRequestDate
                        ? DateFormatService.getDateFromStr(
                            StockRequestState.stockRequestDate,
                            BOOKS_DATE_FORMAT['DD-MM-YYYY']
                          )
                        : StockRequestState.stockRequestDate
                    }
                    onChange={(value: number) => {
                      onRequestDateChange(value);
                    }}
                    canValidate={false}
                    validator={(value: string) => {}}
                    errorMessage={''}
                    // readOnly={
                    //   !Utility.isEmpty(props.selectedRecord) ? true : false
                    // }
                    required={true}
                    readOnly={props?.readOnly ? true : false}
                    dateFormat={convertBooksDateFormatToUILibraryFormat(
                      tenantInfo.dateFormat
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="column parent-width mt-m p-h-s">
                  <CustomFieldsHolder
                    moduleName={MODULES_NAME.STOCK_REQUEST}
                    customFieldsList={
                      props.selectedRecord?.customField
                        ? props.selectedRecord.customField
                        : []
                    }
                    columnConfig={columnConfig}
                    columnConfigTableId={columnConfigTableId}
                    documentMode={
                      props?.readOnly ? DOCUMENT_MODE.VIEW : DOCUMENT_MODE.NEW
                    }
                    onUpdate={(list) => {
                      customFieldUpdated(list);
                    }}
                  />
                </div>
              </div>
              <div className="m-v-r parent-width p-h-s">
                <div className="m-v-r parent-width">{getLineItemGrid()}</div>
                {!props.readOnly && (
                  <div className="m-v-r parent-width">
                    <DKButton
                      title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
                      onClick={() => {
                        if (isProductGroupingEnabled) {
                          setShowProductSelectionPopup(true);
                        } else {
                          addNewItem();
                        }
                      }}
                      className={`${'text-blue'} fw-m p-0`}
                      style={{
                        marginTop: -10,
                        zIndex: 1,
                        paddingLeft: 0,
                        width: 70
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="w-1/2 p-h-s">
                  <textarea
                    className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400 overflow-auto"
                    style={{
                      width: 500,
                      height: 130,
                      backgroundColor: 'rgb(250, 250, 250)',
                      border: '1px dashed rgb(200, 200, 200)'
                    }}
                    placeholder={'Notes'}
                    value={stockRequestNotes}
                    disabled={props.readOnly}
                    onChange={(e: any) => {
                      setStockRequestNotes(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>

            <div>
              {showRequestDetailPopup && (
                <StockRequestDetailPopup
                  detail={trackingData}
                  normalDetail={normalDetail}
                  isBatch={isBatch}
                  isNormal={isNormal}
                  onClose={() => {
                    setShowRequestDetailPopup(false);
                  }}
                ></StockRequestDetailPopup>
              )}
            </div>
            {showAddClassPopup && getAddClassForm()}
            {showProductSelectionPopup && getProductSelectionPopup()}
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
}
